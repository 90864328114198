import React, { useState, useEffect } from "react";

// react-bootstrap components
import { Button, Form, Container, Row, Col, Spinner } from "react-bootstrap";
import SuscripcionStyles from "./styles/SuscripcionStyles";
import useFetch from "hooks/useFetch";
import useSweetAlertConfirm from "hooks/useSweetAlertConfirm";
import Styles from "themes/Styles";
import ReactDatetime from "react-datetime";
import moment from "moment";
import { useSnackbar } from "notistack";
import InputMask from "react-input-mask";
import { CountryDropdown } from "react-country-region-selector";
import { documentTypes, invitedByTypes } from "../../consts/APPCONSTANTS";
import { rangoEdades } from "./SuscripcionConstants";

function Suscripcion() {
  const [alert, setAlert, closeAlert] = useSweetAlertConfirm();
  const classes = SuscripcionStyles();
  const themeClasses = Styles();
  const { enqueueSnackbar } = useSnackbar();

  const [loading, setLoading] = useState(false);
  const { fetchConTokenWithFile } = useFetch();

  const [anUserIsSelected, setAnUserIsSelected] = useState(false);

  const [country, setCountry] = useState("Dominican Republic");
  const [cedula_passport, setCedula_passport] = useState("");
  const [documentType, setDocumentType] = useState(1);
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [sex, setSex] = useState(2);
  const [birthday, setBirthday] = useState(null);
  const [email, setEmail] = useState("");
  const [celphone, setCelphone] = useState("");
  const [direccion, setDireccion] = useState("");
  const [comentario, setComentario] = useState("");

  const [file, setFile] = useState();
  const [costo, setCosto] = useState(0);
  const [activity, setActivity] = useState("");
  const [confirmado, setConfirmado] = useState(true);
  const [rangoEdad, setRangoEdad] = useState(0);
  const [enviado, setEnviado] = useState(false);

  const clearForm = () => {
    setAnUserIsSelected(false);
    setCountry("Dominican Republic");
    setDocumentType(1);
    setCedula_passport("");
    setFirstname("");
    setSex(1);
    setEmail("");
    setCelphone("");
    setDireccion("");
    setComentario("");
    setBirthday(null);
    setLastname("");
    setConfirmado(true);
    setRangoEdad(0);
  };

  const addNewGuest = async (e) => {
    e.preventDefault();
    if (!validateForm()) {
      return;
    }
    setLoading(true);
    await fetchConTokenWithFile(
      "person",
      {
        id_tipo: 2, //2 BECAUSE 2 IS GUEST
        id_face: "",
        cedula_passport,
        firstname,
        lastname,
        birthday: birthday
          ? moment.parseZone(birthday).format("YYYY-MM-DD")
          : null,
        email,
        celphone:
          country == "Dominican Republic"
            ? 1 + celphone.replace("+", "")
            : celphone.replace("+", ""),
        direccion,
        comentario,
        tipo_documento: documentType,
        pais: country,
        sexo: sex,
        image: file?.files[0],
        costo: 500,
        activity: 66,
        confirmado: false,
        rango_edad: rangoEdad,
      },
      "POST"
    );

    setEnviado(true);
    clearForm();
    setLoading(false);
  };

  const validateForm = () => {
    if (cedula_passport?.length == 11 && !Number(cedula_passport)) {
      enqueueSnackbar("Introduzca una Cédula valida", { variant: "error" });
      return false;
    }

    if (celphone == "___-___-____") {
      enqueueSnackbar("Introduzca un Whatsapp valido", { variant: "success" });
      return false;
    }

    if (celphone?.length < 10 || celphone.search("_") > 0) {
      enqueueSnackbar("Introduzca un Whatsapp valido", { variant: "success" });
      return false;
    }
    if (
      cedula_passport?.length > 9 &&
      cedula_passport?.length < 11 &&
      cedula_passport?.length > 0
    ) {
      enqueueSnackbar("La Cédula o pasaporte debe tener 9 o 11 Dígitos", {
        variant: "error",
      });
      return false;
    }
    return true;
  };

  const handleDocumentTypeChange = (value) => {
    setDocumentType(value);
    setCountry("Dominican Republic");
  };

  const handleRangoChange = (value) => {
    setRangoEdad(value);
  };

  const handleSubmit = (e) => {
    addNewGuest(e);
  };

  const selectRangoByDate = (e) => {
    setBirthday(e);
    const anios = moment().diff(e, "years");

    if (anios <= 19) {
      setRangoEdad(1);
    } else if (anios <= 29) {
      setRangoEdad(2);
    } else if (anios <= 39) {
      setRangoEdad(3);
    } else if (anios <= 49) {
      setRangoEdad(4);
    } else if (anios <= 59) {
      setRangoEdad(5);
    } else if (anios <= 69) {
      setRangoEdad(6);
    } else {
      setRangoEdad(7);
    }
  };

  const handleCelPhone = (e) => {
    if (e.target.value == "___-___-____") {
      enqueueSnackbar("Introduzca un Whatsapp valido", { variant: "default" });
      return false;
    }

    setCelphone(e.target.value);
  };

  return (
    <Container fluid className={classes.container}>
      {alert}
      <Row>
        <Form
          onSubmit={handleSubmit}
          className={classes.form}
          style={{ height: enviado ? "430px" : "auto" }}
        >
          <Row>
            <Col className="d-flex justify-content-center">
              <img
                style={{ width: "50%", marginBottom: 20 }}
                src="./bellasublogo.png"
              />
            </Col>
          </Row>

          <p style={{ textAlign: "center" }}>
            <br />{" "}
            <span style={{ fontWeight: "800" }}>Hola!</span>{" "}
            <br /> Gracias por el interés de inscribirte en nuestra conferencia, lamentablemente se han agotado los cupos disponibles. <br />
            <br />
          </p>
        </Form>
      </Row>
    </Container>
  );

  return (
    <Container fluid className={classes.container}>
      {alert}
      <Row>
        <Form
          onSubmit={handleSubmit}
          className={classes.form}
          style={{ height: enviado ? "430px" : "auto" }}
        >
          <Row>
            <Col className="d-flex justify-content-center">
              <img
                style={{ width: "50%", marginBottom: 20 }}
                src="./bellasublogo.png"
              />
            </Col>
          </Row>
          {!enviado ? (
            <>
              <Row>
                <Col className="" md="4">
                  <Form.Group>
                    <label>Tipo de documento</label>
                    <select
                      style={{ borderRadius: "42px" }}
                      value={documentType}
                      onChange={(e) => handleDocumentTypeChange(e.target.value)}
                      disabled={anUserIsSelected ? true : false}
                      className="form-select"
                      aria-label="Default select example"
                    >
                      {documentTypes.map((type, index) => (
                        <option key={index} value={type.value}>
                          {type.label}
                        </option>
                      ))}
                    </select>
                  </Form.Group>
                </Col>
                <Col className="" md="8">
                  <Form.Group>
                    <label># Documento</label>
                    <Form.Control
                      style={{ borderRadius: "42px" }}
                      id="documentoId"
                      placeholder="Cedula o pasaporte"
                      type="text"
                      inputMode="numeric"
                      minLength={9}
                      maxLength={11}
                      value={cedula_passport}
                      onChange={(e) => setCedula_passport(e.target.value)}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col className="" md="6">
                  <label>Pais</label>
                  <CountryDropdown
                    style={{ borderRadius: "42px" }}
                    className="form-select"
                    value={country}
                    onChange={(country) => setCountry(country)}
                    disabled={documentType == 1 ? true : false}
                  />
                </Col>
                <Col className="" md="6">
                  <Form.Group>
                    <label>Sexo</label>
                    <Row className="mb-3">
                      <Col md="6">
                        <Form.Check
                          className={classes.check}
                          inline
                          checked={sex == 1 ? true : false}
                          onChange={(e) => {
                            setSex(1);
                          }}
                          label="M"
                          type="radio"
                        />
                      </Col>
                      <Col md="6">
                        <Form.Check
                          className={classes.check}
                          inline
                          checked={sex == 2 ? true : false}
                          onChange={(e) => {
                            setSex(2);
                          }}
                          label="F"
                          type="radio"
                        />
                      </Col>
                    </Row>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col className="" md="6">
                  <Form.Group>
                    <label>Nombre *</label>
                    <Form.Control
                      style={{ borderRadius: "42px" }}
                      placeholder="Nombre"
                      type="text"
                      value={firstname}
                      required
                      onChange={(e) => setFirstname(e.target.value)}
                    ></Form.Control>
                  </Form.Group>
                </Col>
                <Col className="" md="6">
                  <Form.Group>
                    <label>Apellido *</label>
                    <Form.Control
                      style={{ borderRadius: "42px" }}
                      value={lastname}
                      required
                      onChange={(e) => setLastname(e.target.value)}
                      placeholder="Apellido"
                      type="text"
                    ></Form.Control>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col className="" md="6">
                  <Form.Group>
                    <label>Fecha de nacimiento</label>
                    <ReactDatetime
                      timeFormat={false}
                      inputProps={{
                        className: "form-control",
                        placeholder: "Click para seleccionar",
                        style: { borderRadius: 20 },
                      }}
                      value={
                        birthday
                          ? moment.parseZone(birthday).format("DD/MM/YYYY")
                          : null
                      }
                      dateFormat="DD/MM/YYYY"
                      onChange={(e) => selectRangoByDate(e)}
                    />
                  </Form.Group>
                </Col>
                <Col className="" md="6">
                  <Form.Group>
                    <label>Rango de edad</label>
                    <select
                      style={{ borderRadius: "42px" }}
                      value={rangoEdad}
                      onChange={(e) => handleRangoChange(e.target.value)}
                      className="form-select"
                      aria-label="Default select example"
                    >
                      <option selected disabled value={0}>
                        Seleccionar
                      </option>
                      {rangoEdades.map((type, index) => (
                        <option key={index} value={type.value}>
                          {type.label}
                        </option>
                      ))}
                    </select>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col md="12">
                  <Form.Group>
                    <label>Dirección</label>
                    <Form.Control
                      value={direccion}
                      onChange={(e) => setDireccion(e.target.value)}
                      placeholder="Dirección"
                      style={{ borderRadius: "42px" }}
                      type="text"
                    ></Form.Control>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col className="" md="6">
                  <label>Whatsapp *</label>
                  <Form.Group>
                    <InputMask
                      required
                      mask={
                        country == "Dominican Republic" ? "999-999-9999" : ""
                      }
                      className="form-control"
                      placeholder={
                        country == "Dominican Republic" ? "829-000-0000" : ""
                      }
                      value={celphone}
                      onChange={handleCelPhone}
                      style={{ borderRadius: "42px" }}
                    />
                  </Form.Group>
                </Col>
                <Col className="" md="6">
                  <Form.Group>
                    <label htmlFor="email">Email *</label>
                    <Form.Control
                      style={{ borderRadius: "42px" }}
                      placeholder="Email"
                      required
                      type="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    ></Form.Control>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <p style={{ textAlign: "center", fontWeight: "800" }}>
                  Entrada: RD$500.00{" "}
                </p>
              </Row>

              <div className="d-flex justify-content-center">
                {loading ? (
                  <a variant="dark" type="button">
                    <Spinner
                      as="span"
                      animation="grow"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                      className="mr-1"
                    />
                    Guardando...
                  </a>
                ) : (
                  <Button
                    className={classes.btn}
                    type="submit"
                    variant="primary"
                    style={{ borderRadius: "42px" }}
                  >
                    Reservar
                  </Button>
                )}
              </div>
              <div className="clearfix"></div>
            </>
          ) : (
            <p style={{ textAlign: "center" }}>
              <br />{" "}
              <span style={{ fontWeight: "800" }}>Muchas felicidades!!!</span>{" "}
              <br /> Hemos reservado tu asiento, por favor, no te olvides de
              confirmar tu asistencia realizando el pago en los diferentes
              cultos del ministerio ELIM o en la libreria MIEL. <br />
              <br />
              <Button
                className={classes.btn}
                variant="primary"
                style={{ borderRadius: "42px" }}
                onClick={() => setEnviado(false)}
              >
                Reservar otro
              </Button>
            </p>
          )}
        </Form>
      </Row>
    </Container>
  );
}

export default Suscripcion;
