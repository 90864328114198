import React, { useState, useEffect } from "react";

// react-bootstrap components
import { Button, Form, Container, Row, Col, Spinner } from "react-bootstrap";
import QuestionsStyles from "./styles/QuestionsStyles";
import useFetch from "hooks/useFetch";
import useSweetAlertConfirm from "hooks/useSweetAlertConfirm";
import { useSnackbar } from "notistack";
import { fetchConToken } from "helpers/fetch";

function Questions() {
  const [alert] = useSweetAlertConfirm();
  const classes = QuestionsStyles();
  const { enqueueSnackbar } = useSnackbar();

  const [loading, setLoading] = useState(false);

  const [anUserIsSelected, setAnUserIsSelected] = useState(false);

  const [topic, setTopic] = useState(17);
  const [firstname, setFirstname] = useState("");
  const [question, setQuestion] = useState("");

  const [enviado, setEnviado] = useState(false);

  const clearForm = () => {
    setAnUserIsSelected(false);
    setTopic(17);
    setFirstname("");
    setQuestion("");
  };

  const addNewGuest = async (e) => {
    e.preventDefault();
    if (!validateForm()) {
      return;
    }
    setLoading(true);
    await fetchConToken(
      "fqa/ws",
      {
        contacto: "portalweb",
        nombre: firstname,
        pregunta: question,
        tema: topic,
      },
      "POST"
    );

    setEnviado(true);
    clearForm();
    setLoading(false);
  };

  const validateForm = () => {
    if (firstname?.length < 1) {
      enqueueSnackbar("Introduzca un nombre", { variant: "error" });
      return false;
    }

    if (question?.length < 6) {
      enqueueSnackbar("Ingrese una pregunta valida", { variant: "success" });
      return false;
    }

    return true;
  };

  const handleDocumentTypeChange = (value) => {
    setTopic(value);
  };

  const handleSubmit = (e) => {
    addNewGuest(e);
  };

  return (
    <Container fluid className={classes.container}>
      {alert}
      <Row>
        <Form
          onSubmit={handleSubmit}
          className={classes.form}
          style={{ height: enviado ? "430px" : "560px" }}
        >
          <Row>
            <Col className="d-flex justify-content-center">
              <img
                style={{ width: "50%", marginBottom: 20 }}
                src="./bellasublogo.png"
              />
            </Col>
          </Row>
          {!enviado ? (
            <>
              <Row>
                <Col className="" md="12">
                  <Form.Group>
                    <label>Seleccionar tema de interes *</label>
                    <select
                      style={{ borderRadius: "42px" }}
                      value={topic}
                      onChange={(e) => handleDocumentTypeChange(e.target.value)}
                      disabled={anUserIsSelected ? true : false}
                      className="form-select"
                      aria-label="Default select example"
                    >
                      {[
                        { value: 17, label: "Soltería" },
                        { value: 18, label: "Salud mental" },
                        { value: 19, label: "Salud emocional" },
                        { value: 20, label: "Matrimonio" },
                        { value: 21, label: "Vida cristiana" },
                      ].map((type, index) => (
                        <option key={index} value={type.value}>
                          {type.label}
                        </option>
                      ))}
                    </select>
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                <Col className="" md="12">
                  <Form.Group>
                    <label>Nombre *</label>
                    <Form.Control
                      style={{ borderRadius: "42px" }}
                      placeholder="Nombre"
                      type="text"
                      value={firstname}
                      required
                      onChange={(e) => setFirstname(e.target.value)}
                    ></Form.Control>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col md="12">
                  <Form.Group>
                    <label>Pregunta *</label>
                    <Form.Control
                      value={question}
                      onChange={(e) => setQuestion(e.target.value)}
                      placeholder="Pregunta"
                      style={{ borderRadius: "42px" }}
                      type="text"
                    ></Form.Control>
                  </Form.Group>
                </Col>
              </Row>

              <div className="d-flex justify-content-center">
                {loading ? (
                  <a variant="dark" type="button">
                    <Spinner
                      as="span"
                      animation="grow"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                      className="mr-1"
                    />
                    Guardando...
                  </a>
                ) : (
                  <Button
                    className={classes.btn}
                    type="submit"
                    variant="primary"
                    style={{ borderRadius: "42px" }}
                  >
                    Enviar pregunta
                  </Button>
                )}
              </div>
              <div className="clearfix"></div>
            </>
          ) : (
            <p style={{ textAlign: "center" }}>
              <br />{" "}
              <span style={{ fontWeight: "800" }}>
                Gracias por escribirnos!
              </span>{" "}
              <br /> Hemos recibido tu pregunta <br />
              <br />
              <Button
                className={classes.btn}
                variant="primary"
                style={{ borderRadius: "42px" }}
                onClick={() => setEnviado(false)}
              >
                Enviar otra
              </Button>
            </p>
          )}
        </Form>
      </Row>
    </Container>
  );
}

export default Questions;
