import { makeStyles } from '@material-ui/styles';
import COLORS from 'themes/Colors';

export const LoginStyles = makeStyles({
  root: {
    display:'flex',
    flexDirection:'row',
  },
  backgroundDiv:{
    display:'flex',
    width:'100%',
    backgroundImage:"url(" + require("assets/img/login-background.jpg").default + ")",
    backgroundSize:'cover'
  },
  image:{
    display:'flex',
    width:'100%',
  },
  loginContainer: {
    padding:'1%',
    paddingTop:'7%', 
    width:'30%',
    minHeight:'100vh',
    alignItems:'center',
  },
  loginMobileContainer: {
    padding:'5%',
    width:'100%',
    alignItems:'center',
    justifyContent:'center'
  },
  sidebarHeader:{
    flexDirection:'column',
    display: "flex",
     justifyContent: "center",
     alignItems:'center'
  },
  headerImageDivider:{
    width:'80%',
    height:'0.15rem',
    backgroundColor:COLORS.primary
  },
  button: {
    alignSelf:'center',
    backgroundColor:COLORS.primary,
    borderColor:COLORS.primary,
    borderRadius:'5px',
    width:'100%',
    '&:hover':{
      backgroundColor:COLORS.tertiary,
      borderColor:COLORS.tertiary,
    },
    '&:active':{
      backgroundColor:COLORS.tertiary,
      borderColor:COLORS.tertiary,
    }
  },
  buttonSecondary: {
    backgroundColor:COLORS.secondary,
    borderColor:COLORS.secondary,
    borderRadius:'5px',
    width:'100%',

    '&:hover':{
      backgroundColor:COLORS.tertiary,
      borderColor:COLORS.tertiary,
    },
    '&:active':{
      backgroundColor:COLORS.tertiary,
      borderColor:COLORS.tertiary,
    }
  },
  bottomLoginContainer: {
    paddingTop:'3%',
    alignItems:'center',
    textAlign:'center'
  },
  versionText:{
    paddingTop:'5%',
    textAlign:'center',
    fontSize:'12px'
},
Title: {
  textAlign: 'center',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  display: '-webkit-box',
  WebkitLineClamp: 2,
  WebkitBoxOrient: 'vertical'
},
})
