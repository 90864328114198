
export const documentTypes = [
  { value: 1, label: "Cedula" },
  { value: 2, label: "Pasaporte" },
];

export const shirtSizes = [
  { value: 1, label: "XS" },
  { value: 2, label: "S" },
  { value: 3, label: "M" },
  { value: 4, label: "L" },
  { value: 5, label: "XL" },
  { value: 6, label: "XXL" },
];

export const groups = [
  { value: 1, label: "A" },
  { value: 2, label: "B" },
  { value: 3, label: "C" },
];

export const categories = [
  { value: 1, label: "Adulto" },
];


export const outTees = [
  { value: 1, label: "ROJO " },
  { value: 2, label: "AZUL" },
  { value: 3, label: "DORADO" },
  { value: 4, label: "NEGRO" },
];


export const roles = [
  { value: 1, label: "Admin" },
  { value: 2, label: "Encargado" }
];

export const estado_evento = [
  { value: 1, label: "Abierto" },
  { value: 2, label: "Cerrado" },
];

export const tipo_pagos = [
  { value: 1, label: "Normal" },
  { value: 2, label: "Donación" },
];

export const invitedByTypes = [
  { value: 1, label: "Damas", searchOnPlayers: true },
  { value: 2, label: "Patrocinador", searchOnPlayers: false },
  { value: 3, label: "Gerente de sucursal", searchOnPlayers: false },
  { value: 4, label: "Invitados especiales", searchOnPlayers: false },
  { value: 5, label: "Boletas adicionales - Jugadores", searchOnPlayers: true },
  { value: 6, label: "Boletas adicionales - Patrocinadores", searchOnPlayers: false },
  { value: 7, label: "Boletas adicionales - Gerente de sucursales", searchOnPlayers: false },
  { value: 8, label: "Invitado Especial - BMCargo", searchOnPlayers: false },

];

export const gameWinnerPrize = [
  { value: 1, label: "Drive más Largo, hoyo 5" },
  { value: 2, label: "Acercamiento a Bandera, hoyo 4" },
  { value: 3, label: "Acercamiento a Bandera, hoyo 7" },
  { value: 4, label: "Acercamiento a Bandera, hoyo 13" },
  { value: 5, label: "Drive más Largo Damas, hoyo 5" },
  { value: 6, label: "Acercamientos a Bandera, hoyo 16" },
  //Agregar otros premios
];

export const ActivitiesPermissions = [
  { value: 1, label: 'Jugador' },
  { value: 2, label: 'Invitado' },
  { value: 3, label: 'Staff' },
  { value: 4, label: 'Admin torneo' },
  { value: 5, label: 'Admin técnico' },
  { value: 6, label: 'Invitado - Dama' },
  { value: 7, label: 'Invitado - Patronicador' },
  { value: 8, label: 'Invitado - Gerente de sucursal' },
  { value: 9, label: 'Invitado especial' },
  { value: 10, label: 'Invitado Boletas adicionales- Jugador' },
  { value: 11, label: 'Invitado Boletas adicionales- Patrocinador' },
  { value: 12, label: 'Invitado Boletas adicionales- Gerente de sucursales' },
];