import { combineReducers } from "redux";
import authReducer from "./authRedecer";
import userReducer from "./userReducer";
import personReducer from "./personReducer";
import rolReducer from "./rolReducer";
import activityReducer from "./activityReducer";
import foursomeReducer from "./foursomeReducer";
export const rootReducer = combineReducers({
  authReducer,
  userReducer,
  personReducer,
  rolReducer,
  activityReducer,
  foursomeReducer,
});
