import { useState } from "react";
import { useSnackbar } from "notistack";
import { useDispatch, useSelector } from "react-redux";
import { updateToken, logout } from "actions/authActions";
const baseUrl = process.env.REACT_APP_API_URL;

function processResponse(response) {
  const statusCode = response.status;
  const data = response.json();
  return Promise.all([statusCode, data]).then(res => ({
    statusCode: res[0],
    data: res[1]
  }));
}

const useFetch = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [error, setError] = useState([]);
  const dispatch = useDispatch();
  const { token, logged_user } = useSelector((state) => state.authReducer);

  const fetchSinToken = async (endpoint, body, method = "GET") => {
    const url = `${baseUrl}/${endpoint}`;
    setLoading(true);
    try {
      if (method === "GET") {
        const resp = await fetch(url);
        const data = await resp.json();
        setData(data);
        setLoading(false);
      } else {
        const resp = await fetch(url, {
          method,
          headers: {
            "Content-type": "application/json",
          },
          body: JSON.stringify(body),
        });
        const data = await resp.json();
        setData(data);
        setLoading(false);
        setError(false);
      }
    } catch (error) {
      setError(error);
      setLoading(false);
    }
  };


  const fetchConToken = async (endpoint, body, method = "GET") => {
    const url = `${baseUrl}/${endpoint}`;
    setLoading(true);
    try {
      if (method === "GET") {
        const resp = await fetch(url, {
          method,
          headers: {
            "x-token": token,
          },
        });
        const data = await resp.json();
        setData(data);
        setLoading(false);
        setError(false);
      } else {
        const resp = await fetch(url, {
          method,
          headers: {
           // "Content-type": "application/json",
            "x-token": token,
          },
          body: JSON.stringify(body),
        });
        const data = await resp.json();
        setData(data);
        setLoading(false);
        setError(false);
      }
    } catch (error) { }
  };

  const fetchConTokenPromesa = async (endpoint, body, method = "GET", ErrorList = [], disableSuccesAlert,isFormData) => {
    const url = `${baseUrl}/${endpoint}`;
    let req;
    
    try {
      if (method == "GET") {
        await fetch(url, {
          method,
          headers: {
            Accept: 'application/json',
            "Content-type": isFormData ?  "multipart/form-data" : "application/json",
            "x-token": token,
          },
        }).then(processResponse).then(async res => {
          const { statusCode, data } = res;
          if (statusCode == 200) {
            req = data;
            return;
          }

          let handledError = ErrorList.find(error => data?.error?.includes(error.key));
          let handledErrorMSG = ErrorList.find(error => data?.msg?.includes(error.key));
          let handledErrorMessage = ErrorList.find(error => data?.message?.includes(error.key));
          if (handledError) {
            enqueueSnackbar(handledError.error, { variant: "error" });
            return;
          }
          if (handledErrorMSG) {
            enqueueSnackbar(handledErrorMSG.error, { variant: "error" });
            return;
          }
          if (handledErrorMessage) {
            enqueueSnackbar(handledErrorMessage.error, { variant: "error" });
            return;
          }

        })
      } else {

        await fetch(url, {
          method,
          headers: {
            //  Accept: 'application/json',
              "Content-type": isFormData ?  "multipart/form-data" : "application/json",
              "x-token": token,
          },
          body: isFormData ? body : JSON.stringify(body)
        }).then(processResponse).then(async res => {
          const { statusCode, data } = res;
          if (statusCode == 200) {
            req = data;
            if (!disableSuccesAlert) {
              enqueueSnackbar("Solicitud exitosa", { variant: "success" })
            }
            return;
          }

          if (data.ok == false && data.error.search('Duplicate entry') > -1) {
            enqueueSnackbar("Duplicado", { variant: "error" })
            return;
          }

          if (statusCode == 409) {
            enqueueSnackbar("No se pudo completar la accion", { variant: "error" })
            return;
          }

          if (!disableSuccesAlert) {
            let handledError = ErrorList.find(error => data?.error?.includes(error.key));
            let handledErrorMSG = ErrorList.find(error => data?.msg?.includes(error.key));
            let handledErrorMessage = ErrorList.find(error => data?.message?.includes(error.key));
            if (handledError) {
              enqueueSnackbar(handledError.error, { variant: "error" });
              return;
            }
            if (handledErrorMSG) {
              enqueueSnackbar(handledErrorMSG.error, { variant: "error" });
              return;
            }
            if (handledErrorMessage) {
              enqueueSnackbar(handledErrorMessage.error, { variant: "error" });
              return;
            }
          }
        })
      }
    } catch (error) {
      console.log(error)
    }
    return req;
  };

  const POSTNotificationRequest = async (URL, body, ErrorList = [], disableSuccessAlert) => {
    let req;
    try {
      req = await fetch(URL, {
        method: 'POST',
        headers: {
          "Content-Type": "application/json;charset=utf-8",
          "Authorization": 'Basic NTQyYzc5MWUtZGVmNy00YTRlLTk1ZDctOGFjYjdmYzE3N2Ey',
        },
        body: JSON.stringify(body)
      }).then(processResponse).then(async res => {
        const { statusCode, data } = res;
        if (statusCode == 200) {
          req = data;
          if (!disableSuccessAlert) {
            enqueueSnackbar("Solicitud exitosa", { variant: "success" })
          }
          return;
        }
        if (statusCode == 500) {
          enqueueSnackbar("Ha ocurrido un error interno del servidor", { variant: "error" })
          return;
        }
      })
    } catch (error) {

    }
    return req;
  };

 const RefreshToken = async () => {
    let tokenRefreshed = false;
    await fetch(`${baseUrl}/auth/renew`, {
      method: "GET",
      headers: {
        Accept: 'application/json',
        "Content-type": "application/json",
        "x-token": token,
      },
    }).then(processResponse).then(async res => {
      const { statusCode, data } = res;
      if (statusCode == 200) {
        dispatch(updateToken({
          login: true,
          logged_user: logged_user,
          token: data.token
        }));
        tokenRefreshed = true;
      } else {
        tokenRefreshed = false;
      }
    })
    return tokenRefreshed;
  };

  const fetchConTokenWithFile = async (endpoint, body) => {
    const url = `${baseUrl}/${endpoint}`;
    setLoading(true);
    try {
        const formdata = new FormData();



        for (const key in body) {
          if (Object.hasOwnProperty.call(body, key)) {
            const element = body[key];
            key != 'entradas' && key != 'speakers' && key != 'encargados' && key != 'images' &&
            formdata.append(key, element);
          }
        }

        for (let i = 0; i < body.entradas?.length; i++) {
          formdata.append('entradas[]', body.entradas[i].id ? body.entradas[i].id:body.entradas[i].value);
        }
        for (let i = 0; i < body.speakers?.length; i++) {
          formdata.append('speakers[]', body.speakers[i].id ? body.speakers[i].id:body.speakers[i].value);
        }
        for (let i = 0; i < body.encargados?.length; i++) {
          formdata.append('encargados[]', body.encargados[i].id ? body.encargados[i].id:body.encargados[i].value);
        }

        for (let i = 0; i < body.images?.length; i++) {
          formdata.append('images', body.images[i]);
        }
        
        const resp = await fetch(url, {
          method:'POST',
          body: formdata,
          headers: {
            "x-token": token,
          },
        });





        const data = await resp.json();
        
        setData(data);
        setLoading(false);
        setError(false);

        if (resp.status == 200) {
          enqueueSnackbar("Solicitud exitosa", { color:'#ad2c5d' })
        return;
      }

      if (resp.status == 500) {
        enqueueSnackbar("Ha ocurrido un error", { variant: "error" })
        return;
      }
      
    } catch (error) {
      setError(error);
      setLoading(false);
      console.log(error)
    }
  };

  return {
    fetchSinToken,
    fetchConToken,
    fetchConTokenPromesa,
    POSTNotificationRequest,
    RefreshToken,
    fetchConTokenWithFile,
    loading,
    data,
    error,
  };
};

export default useFetch;
