import { makeStyles } from "@material-ui/styles";
import COLORS from "themes/Colors";

export default makeStyles({
    root: {
        display: 'flex',
        flexDirection: 'column'
    },
    headerButton: {
     backgroundColor: COLORS.primary,
     borderColor:COLORS.primary,
     '&:hover':{
        backgroundColor: COLORS.secondary,
        borderColor:COLORS.secondary,
     }
    },
    addButton: {
        backgroundColor: COLORS.primary,
        borderColor: COLORS.primary,
        '&:hover': {
            backgroundColor: COLORS.primary,
            borderColor: COLORS.primary,
        },
        borderRadius:'6px',
        width:'60%',
        height:'2.5rem',
    },
    modifyButton: {
        backgroundColor: COLORS.secondary,
        borderColor: COLORS.secondary,
        '&:hover': {
            backgroundColor: COLORS.secondary,
            borderColor: COLORS.secondary,
        },
        borderRadius:'6px',
        width:'40%',
        height:'2.5rem',
    },
    deleteButton: {
        backgroundColor: COLORS.red,
        borderColor: COLORS.red,
        '&:hover': {
            backgroundColor: COLORS.red,
            borderColor: COLORS.red,
        },
        borderRadius:'6px',
        width:'60%',
        height:'2.5rem',
    },
    divider:{
        height:'1.5rem'
    }
})