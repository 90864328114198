import { types } from "../types/types";

const initialState = {
  checking: true,
  editingUser: {},
  userUpdated: false,
  savingUser: false,
  errorSavingUser: false,
  UserSaved: false,
  userDeleted: false,
  userDeleting: false,
  errorDeletingUser: false,
  users: [],
  pagination: { page: 1, total_page: 1, total_user: 0, limit: 40 },
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.userAddNew:
      return {
        ...state,
        users: [action.payload, ...state.users],
      };
    case types.savingUser:
      return {
        ...state,
        savingUser: true,
      };
    case types.userSaved:
      return {
        ...state,
        UserSaved: true,
        errorSavingUser: false,
        savingUser: false,
      };
    case types.errorSavingUser:
      return {
        ...state,
        UserSaved: false,
        errorSavingUser: action.payload,
        savingUser: false,
      };
    case types.resetUserSaved:
      return {
        ...state,
        UserSaved: false,
      };

    case types.userUpdated:
      return {
        ...state,
        editingUser: {},
        userUpdated: true,
        users: state.users.map((e) =>
          e.id_usuario === action.payload.id_usuario ? action.payload : e
        ),
      };
    case types.userDeleting:
      return {
        ...state,
        userDeleting: true,
      };
    case types.userDeleted:
      return {
        ...state,
        userDeleting: false,
        userDeleted: true,
        users: state.users.filter(
          (e) => e.cedula_passport !== action.payload.id
        ),
      };
    case types.resetDeletedUser:
      return {
        ...state,
        UserSaved: false,
      };
    case types.userLoaded:
      return {
        ...state,
        users: action.payload.users,
        pagination: action.payload.pagination,
        checking: false,
      };
    case types.resetUserLoaded:
      return {
        ...state,
        userUpdated: false,
      };
    case types.editingUser:
      return {
        ...state,
        editingUser: action.payload,
      };
    case types.closeEditingUser:
      return {
        ...state,
        editingUser: {},
      };
    case types.userLogout:
      return initialState;

    default:
      return state;
  }
};

export default userReducer;
