export const types = {
  userLogout: "[user] Logout user clean",
  userStartAddNew: "[user] Start add new",
  userAddNew: "[user] Add new",
  userUpdated: "[user] user updated",
  userLoaded: "[user] users loaded",
  resetUserLoaded: "[user] Delete updated user notification",
  editingUser: "[user] show modal with user data",
  savingUser: "[user] wait for server response (add)",
  errorSavingUser: "[user] error response of server",
  userSaved: "[user] User saved",
  resetUserSaved: "[user] Delete saved user notification",
  closeEditingUser: "[user] close  modal with user data",

  userStartDelete: "[user] delete user",
  userDeleting: "[user] wait for server response",
  userDeleted: "[user] user deleted",
  resetDeletedUser: "Delete deleted user notification",
  errorDeletingUser: "[user] error response of server (delete)",

  getPersonList: "[Person] get person list",
  cleanPersonList: "[Person] clean person list",
  addPerson: "[Person] add person to list",
  deletePerson: "[Person] delete person of list",
  updatePerson: "[Person] update person",
  showPersonForm: "[Person] show or hide person form",
  selectPerson: "[Person] select one person for update or delete",
  unSelectPerson: "[Person] Unselect person",

  getActivityList: "[Activity] get activity list",
  cleanActivityList: "[Activity] clean activity list",
  addActivity: "[Activity] add activity to list",
  deleteActivity: "[Activity] delete activity of list",
  updateActivity: "[Activity] update activity",
  showActivityForm: "[Activity] show or hide activity form",
  selectActivity: "[Activity] select one activity for update or delete",
  unSelectActivity: "[Activity] Unselect activity",

  getFoursomeList: "[Foursome] get foursome list",
  cleanFoursomeList: "[Foursome] clean foursome list",
  addFoursome: "[Foursome] add foursome to list",
  deleteFoursome: "[Foursome] delete foursome of list",
  updateFoursome: "[Foursome] update foursome",
  showFoursomeForm: "[Foursome] show or hide foursome form",
  selectFoursome: "[Foursome] select one foursome for update or delete",
  unSelectFoursome: "[Foursome] Unselect foursome",

  getRoles: "[Roles] get rol list",

  authCkeckingFinish: "[auth] checking finish login state",
  authStartLogin: "[auth] Start login",
  authLogin: "[auth] login",
  authStartRegister: "[auth] Start Register",
  authStartTokenRenew: "[auth] Start token renew",
  authLogout: "[auth] Logout",
  authLoginError: "[auth] wrong login",
};
