import moment from "moment";
export const formatDateToLocalDateString = (dateToFormat) => {
    let date = new Date(dateToFormat);
   // console.log(date,new Date(dateToFormat).getUTCDate() )
    let formatedCurrentDate = ("0" + date.getUTCDate()).slice(-2) + "-" + ("0" + (date.getUTCMonth() + 1)).slice(-2) + "-" + date.getUTCFullYear();
    let hours = date.getHours();
    var minutes = date.getMinutes();
    let AmOrPm = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12;
    hours = hours ? hours : 12;
    minutes = minutes < 10 ? '0'+minutes : minutes;

    return `${formatedCurrentDate} ${hours}:${minutes} ${AmOrPm.toUpperCase()}`
  }