import React from "react";
import { useDispatch } from "react-redux";

// react-bootstrap components
import { Button, Dropdown, Navbar, Nav, Container } from "react-bootstrap";
import { logout } from "actions/authActions";
import AdminNavbarStyles from "./styles/AdminNavbarStyles";
import { BrowserView, MobileView } from "react-device-detect";

function AdminNavbar() {
  const dispatch = useDispatch();
  const classes = AdminNavbarStyles();
  const LogOut = () => {
    dispatch(logout());
  };

  return (
    <>
      <BrowserView>
        <Navbar expand="lg">
          <Container fluid>
            <div className="navbar-wrapper">
              <div className="navbar-minimize">
                <Button
                  className="btn-fill btn-round btn-icon d-none d-lg-block bg-dark border-dark"
                  variant="dark"
                  onClick={() => document.body.classList.toggle("sidebar-mini")}
                >
                  <i className="fas fa-ellipsis-v visible-on-sidebar-regular"></i>
                  <i className="fas fa-bars visible-on-sidebar-mini"></i>
                </Button>

                <Button
                  className="btn-fill btn-round btn-icon d-block d-lg-none bg-dark border-dark"
                  variant="dark"
                  onClick={() =>
                    document.documentElement.classList.toggle("nav-open")
                  }
                >
                  <i className="fas fa-ellipsis-v visible-on-sidebar-regular"></i>
                  <i className="fas fa-bars visible-on-sidebar-mini"></i>
                </Button>
              </div>
            </div>

            <div className={classes.headerTitle}>
              <p className={classes.headerTitleStyle}>Administrador de Eventos</p>
            </div>

            <Dropdown as={Nav.Item}>
              <Dropdown.Toggle
                as={Nav.Link}
                id="dropdown-41471887333"
                variant="default"
              >
                <i className="nc-icon nc-bullet-list-67"></i>
              </Dropdown.Toggle>
              <Dropdown.Menu
                alignRight
                aria-labelledby="navbarDropdownMenuLink"
              >
                <Dropdown.Item className="text-danger" onClick={() => LogOut()}>
                  <i className="nc-icon nc-button-power"></i>
                  Log out
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>

          </Container>
        </Navbar>
      </BrowserView>
      <MobileView>
        <Navbar expand="lg">
          <Container fluid>
            <Dropdown as={Nav.Item}>
              <Dropdown.Toggle
                as={Nav.Link}
                id="dropdown-41471887333"
                variant="default"
              >
                <i className="nc-icon nc-bullet-list-67"></i>
              </Dropdown.Toggle>
              <Dropdown.Menu
                aria-labelledby="navbarDropdownMenuLink"
              >
                <Dropdown.Item className="text-danger" onClick={() => LogOut()}>
                  <i className="nc-icon nc-button-power"></i>
                  Log out
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>

            <div className="navbar-wrapper">
              <div className="navbar-minimize">
                <Button
                  className="btn-fill btn-round btn-icon d-none d-lg-block bg-dark border-dark"
                  variant="dark"
                  onClick={() => document.body.classList.toggle("sidebar-mini")}
                >
                  <i className="fas fa-ellipsis-v visible-on-sidebar-regular"></i>
                  <i className="fas fa-bars visible-on-sidebar-mini"></i>
                </Button>

                <Button
                  className="btn-fill btn-round btn-icon d-block d-lg-none bg-dark border-dark"
                  variant="dark"
                  onClick={() =>
                    document.documentElement.classList.toggle("nav-open")
                  }
                >
                  <i className="fas fa-ellipsis-v visible-on-sidebar-regular"></i>
                  <i className="fas fa-bars visible-on-sidebar-mini"></i>
                </Button>
              </div>
            </div>
          </Container>
        </Navbar>
      </MobileView>
    </>
  );
}

export default AdminNavbar;
