import React, { useEffect } from "react";
import useForm from "hooks/useForm";
import { useDispatch, useSelector } from "react-redux";
import { startLogin } from "../../actions/authActions";
import { Redirect, useHistory } from "react-router";
import useFetch from "hooks/useFetch";

import { BrowserView, MobileView, } from "react-device-detect";

// react-bootstrap components
import { Button, Card, Form, Container, Col, Image, Row } from "react-bootstrap";
import { LoginStyles } from "./styles/LoginStyles";
import AppConfig from "AppConfig";

const APP_VERSION = AppConfig.AppVersion;

function Login() {
  const dispatch = useDispatch();
  const { login } = useSelector((state) => state.authReducer);
  const history = useHistory();
  const { fetchConTokenPromesa } = useFetch();

  useEffect(() => {
    if (login) {
      return <Redirect to="/admin/dashboard" />;
    }
  }, [login]);

  const [form, setForm] = useForm({
    documentId: "", //"40226930788",
    password: "" //clave01",
  });

  const { documentId, password } = form;

  const handleLogin = async () => {
    const ErrorList = [
      {
        key: 'You do not have',
        error: 'Usted no tiene permisos para acceder'
      },
      {
        key: 'Document id or password',
        error: 'Usuario o contraseña incorrectos'
      }
    ]
    let response = await fetchConTokenPromesa(
      "auth",
      {
        documentId: documentId,
        password: password
      },
      "POST",
      ErrorList,
    );
    if (response?.ok) {
      dispatch(startLogin(response))
    }
  }

  const handleOnSubmit = async (e) => {
    e.preventDefault();
    // dispatch(startLogin(documentId, password));
    handleLogin();
  };

  const classes = LoginStyles();
  return (
    <React.Fragment>
      <BrowserView className={classes.root}>
        <div className={classes.loginContainer}>
          <div
            className={classes.sidebarHeader}
          >
            <Image
              src={require("assets/img/reservame.png").default}
              width={"40%"}
              style={{ marginRight: "20px" }}
            />
            <div style={{ height: '0.5rem' }} />
            <h5 className={classes.Title}>Administrador de Eventos</h5>
            <div className={classes.headerImageDivider} />
          </div>
          <div style={{ height: '2rem' }} />
          <Form onSubmit={handleOnSubmit}>
            <Form.Group>
              <label>Usuario</label>
              <Form.Control
                placeholder="Cédula o pasaporte"
                type="text"
                name="documentId"
                autoComplete="off"
                onChange={setForm}
                value={documentId}
                required
              ></Form.Control>
            </Form.Group>
            <Form.Group>
              <label>Password</label>
              <Form.Control
                placeholder="Password"
                type="password"
                name="password"
                minLength="4"
                onChange={setForm}
                value={password}
              // required
              ></Form.Control>
            </Form.Group>
            <div style={{ height: '0.5rem' }} />
            <p
              className="text-danger text-center hide d-none"
              id="errorMsg"
            >
              Error en los datos ingresados
            </p>
            <Button
              className={classes.button}
              type="submit"
            >
              Iniciar sesion
            </Button>
          </Form>
          <p className={classes.versionText}>Version:{APP_VERSION}</p>
        </div>
        <img width={'70%'} src={require("assets/img/fondo.jpg").default} />
      </BrowserView>
      <MobileView className={classes.loginMobileContainer}>
        <div
          style={{ display: "flex", flexDirection: 'column', alignItems: 'center', justifyContent: "center" }}
        >
          <Image
            src={require("assets/img/reservame.png").default}
            width={"40%"}
          />
          <div style={{ height: '0.5rem' }} />
          <h5 className={classes.Title}>Administrador de Eventos</h5>
          <div className={classes.headerImageDivider} />
        </div>

        <Form onSubmit={handleOnSubmit}>
          <Form.Group>
            <label>Usuario</label>
            <Form.Control
              placeholder="Cédula o pasaporte"
              type="text"
              name="documentId"
              autoComplete="off"
              onChange={setForm}
              value={documentId}
              required
            ></Form.Control>
          </Form.Group>
          <Form.Group>
            <label>Password</label>
            <Form.Control
              placeholder="Password"
              type="password"
              name="password"
              minLength="4"
              onChange={setForm}
              value={password}
            // required
            ></Form.Control>
          </Form.Group>
          <p
            className="text-danger text-center hide d-none"
            id="errorMsg"
          >
            Error en los datos ingresados
          </p>
          <Button
            className={classes.button}
            type="submit"
            variant="primary"
          >
            Iniciar sesion
          </Button>

        </Form>
        <p className={classes.versionText}>Version:{APP_VERSION}</p>
      </MobileView>
    </React.Fragment>

  );
}

export default Login;
