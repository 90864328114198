import React, { useState } from 'react';
import { Button, Spinner, Form, Row, Col } from "react-bootstrap";
import { makeStyles } from '@material-ui/styles';
import COLORS from 'themes/Colors';

function InputSearcher({ className, value, placeholder, onChange }) {
    const classes = Styles();
    return (
            <Form.Control
                placeholder={placeholder}
                value={value} onChange={onChange} className={className || classes.inputSearcher} />

    )
}

const Styles = makeStyles({
    inputSearcher: {
        width: '60%',
        backgroundColor: COLORS.fieldGray,
    }
})

export default InputSearcher;