import React, { useEffect } from "react";
import {
  //BrowserRouter as Router,
  HashRouter as Router,
  Switch,
  Redirect,
  Route,
} from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { PublicRoute } from "./PublicRoute";
import { PrivateRoute } from "./PrivateRoute";

//Layouts
import AuthLayout from "../layouts/Auth.js";
import AdminLayout from "../layouts/Admin.js";
import Results from "../layouts/Results";
import RestorePassword from "views/auth/RestorePassword";
import Login from "views/auth/Login";
import Suscripcion from "views/suscripcion/Suscripcion";
import Questions from "views/questions/Questions";
export const AppRouter = () => {
  const dispatch = useDispatch();
  const { login } = useSelector((state) => state.authReducer);

  return (
    <Router hashType='noslash'>
      <Switch>
      <PublicRoute
          path="/suscripcion"
          component={Suscripcion}
          isAuthenticated={login}
        />
        <PublicRoute
          path="/questions"
          component={Questions}
          isAuthenticated={login}
        />

        <PublicRoute
          path="/auth/login"
          component={AuthLayout}
          isAuthenticated={login}
        />

        <PublicRoute
          path="/password-reset/:id/:token"
          component={RestorePassword}
          isAuthenticated={login}
        />
        <PrivateRoute
          path="/admin"
          component={AdminLayout}
          isAuthenticated={login}
        />
        <Redirect from='*' to='/auth/login' />
      </Switch>
    </Router>
  );
};
