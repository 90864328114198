import React, { useState, useEffect } from "react";

// react-bootstrap components
import {
  OverlayTrigger,
  Button,
  Card,
  Form,
  Tooltip,
  Table,
  Container,
  Row,
  Col,
  Modal,
  Spinner,
  Image
} from "react-bootstrap";
import { useDispatch } from "react-redux";
import LocationStyles from "./styles/LocationStyles";
import useFetch from "hooks/useFetch";
import Pagination from "components/Pagination/Pagination";
import useSweetAlertConfirm from "hooks/useSweetAlertConfirm";
import Styles from "themes/Styles";
import ReactDatetime from "react-datetime";
import { isMobile } from "react-device-detect";
import InputSearcher from "components/InputSearcher/InputSearcher";
import ExportToExcel from "utilities/ExportToExcel";
import { useSnackbar } from "notistack";
import { ErrorList } from "./LocationConstants";
import AppConfig from "AppConfig";
function Location() {

  const [alert, setAlert, closeAlert] = useSweetAlertConfirm();
  const classes = LocationStyles();
  const themeClasses = Styles();
  const { enqueueSnackbar } = useSnackbar();

  const dispatch = useDispatch();

  const [loading, setLoading] = useState(true);
  const { fetchConTokenPromesa } = useFetch();

  const [formModalVisible, setFormModalVisible] = useState(false);

  const [filterText, setFilterText] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [listData, setListData] = useState([]);
  const [paginationData, setPaginationData] = useState({});

  const [itemId, setItemId] = useState();
  const [order, setOrder] = useState();
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [detail, setDetail] = useState("");
  const [latitud, setLatitud] = useState("");
  const [longitud, setLongitud] = useState("");

  const [exportData, setExportData] = useState([]);

  const handleSelectItem = (item) => {
    setItemId(item.id_localidad);
    setOrder(item.orden);
    setName(item.nombre);
    setDescription(item.resumen);
    setDetail(item.detalle);
    setLatitud(item.latitud);
    setLongitud(item.longitud);
    setFormModalVisible(true);
  };

  const handleCloseFormModal = () => {
    clearForm();
    setFormModalVisible(false);
  };

  const clearForm = () => {
    setItemId(null);
    setOrder(null);
    setName("");
    setDescription("");
    setDetail("");
    setLatitud("");
    setLongitud("");
  }

  const onChangePage = (selectedPage) => {
    setCurrentPage(selectedPage);
    getLocationListData(selectedPage);
  }

  const getExportData = async () => {
    let response = await fetchConTokenPromesa(`localidad?page=1&limit=999999999999`);
    let data = response?.localidadList;
    let newData = data.map((item) => ({
      ID: item.id_localidad,
      Nombre: item.nombre,
      Resumen: item.resumen,
      Detalle: item.detalle,
      Longitud: item.longitud,
      Latitud: item.latitud,
    }));
    return newData;
  }

  const getLocationListData = async (page, filter = "") => {
    let response = await fetchConTokenPromesa(`localidad?${filter.length > 0 && '&buscar=' + filter}&page=${page || currentPage}&limit=${AppConfig.TableLimit}`);
    setLoading(false);
    setListData(response?.localidadList);
    setPaginationData(response?.pagination);
  };

  const handleDeleteItem = (item) => {
    setAlert(
      `Se eliminará el registro de "${item.nombre}"`,
      () => deleteItem(item),
      item
    );
  };

  const deleteItem = async (item) => {
    closeAlert();
    setLoading(true);
    await fetchConTokenPromesa(
      `localidad/${item.id_localidad}`,
      {},
      "DELETE",
      ErrorList
    );
    getLocationListData();
    setFilterText("");
    setLoading(false);
  };

  const addNewLocation = async (e) => {
    e.preventDefault();
    setLoading(true);
    await fetchConTokenPromesa(
      "localidad",
      {
        orden: order,
        nombre: name,
        resumen: description,
        detalle: detail,
        longitud: longitud,
        latitud: latitud
      },
      "POST",
      ErrorList
    );

    clearForm();
    getLocationListData();
    setFilterText("");
    setLoading(false);
  }
  const updateLocation = async (e) => {
    e.preventDefault();
    setLoading(true);
    await fetchConTokenPromesa(
      `localidad/${itemId}`,
      {
        orden: order,
        nombre: name,
        resumen: description,
        detalle: detail,
        longitud: longitud,
        latitud: latitud
      },
      "PUT",
      ErrorList
    );
    handleCloseFormModal();
    getLocationListData();
    setFilterText("");
    setLoading(false);
  }

  const handleFilterList = (e) => {
    getLocationListData(1, e.target.value);
    setFilterText(e.target.value)
    setCurrentPage(1)
  }

  const handleOnSubmit = (e) => {
    if (!itemId) {
      addNewLocation(e);
    } else {
      updateLocation(e);
    }
  }

  const handleExportToExcel = async (title) => {
    let exportData = await getExportData();
    if(exportData.length > 0){
      ExportToExcel(exportData, title);
    }else{
      enqueueSnackbar('No hay informacion para exportar',{variant:'error'});
    }
  }


  useEffect(() => {
    getLocationListData();
  }, []);

  return (
    <Container fluid>
      {alert}
      <Row>
        <Col md="12">
          <Card className="strpied-tabled-with-hover">
            <Card.Header>
              <Card.Title className={themeClasses.cardTitleHeader}>
                <h4 className={themeClasses.cardTitle}> Localidades </h4>
                <div className={themeClasses.simplyRowDivider} />
                <div className={themeClasses.secondaryHeaderSearcherContainer}>
                  <InputSearcher value={filterText} onChange={handleFilterList} placeholder="Buscar" />
                  <div className={themeClasses.mediumRowDivider} />
                  <Button
                    size="sm"
                    onClick={() => setFormModalVisible(true)}
                    className={themeClasses.headerButton}
                  >
                    {isMobile ? "➕" : 'Agregar'}
                  </Button>
                  <div className={themeClasses.mediumRowDivider} />
                  {/* <OverlayTrigger overlay={<Tooltip >Exportar a excel</Tooltip>}>
                    <Button
                      className={themeClasses.exportButton}
                      onClick={(e) => { handleExportToExcel('Localidades') }}
                      variant="success"
                    >
                      <i class="fas fa-file-excel"></i>
                    </Button>
                  </OverlayTrigger> */}
                </div>
              </Card.Title>
            </Card.Header>
            <Card.Body className="table-responsive p-0">
              <div className={classes.root}>

                {loading ?
                  <p>Cargando...</p>
                  :

                  <Table className="table-hover table-striped w-full">
                    <thead>
                      <tr>
                        <th>id</th>
                        <th>orden</th>
                        <th>nombre</th>
                        <th>longitud</th>
                        <th>latitud</th>
                      </tr>
                    </thead>

                    <tbody>
                      {listData?.map((item, index) => (
                        <tr key={index}>
                          <td>{item?.id_localidad}</td>
                          <td>{item?.orden}</td>
                          <td>{item?.nombre}</td>
                          <td>{item?.longitud}</td>
                          <td>{item?.latitud}</td>
                          <td>
                            <Image
                              src={item?.url_imagen}
                              style={
                                {
                                  padding: '0.5%',
                                  display: 'flex',
                                  width: '40px',
                                }
                              }
                            />
                          </td>
                          <td className="td-actions text-right">
                            <OverlayTrigger
                              overlay={<Tooltip id="tooltip-48903503">Modificar</Tooltip>}
                            >
                              <Button
                                className="btn-link btn-xl"
                                onClick={(e) => handleSelectItem(item)}
                                variant="success"
                              >
                                <i className="fas fa-edit"></i>
                              </Button>
                            </OverlayTrigger>

                            <OverlayTrigger
                              overlay={<Tooltip id="tooltip-255158527">Borrar</Tooltip>}
                            >
                              <Button
                                className="btn-link btn-xl"
                                onClick={(e) => handleDeleteItem(item)}
                                variant="danger"
                              >
                                <i className="fas fa-times"></i>
                              </Button>
                            </OverlayTrigger>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                }
                <div style={{ alignSelf: 'center' }}>
                  <Pagination className={classes.pagination} pages={paginationData?.total_page} currentPage={currentPage} onSelectPage={onChangePage} />
                </div>

                <Modal show={formModalVisible} onHide={() => handleCloseFormModal()} size="lg">
                  <Modal.Header closeButton></Modal.Header>
                  <Modal.Body >
                    <Container fluid>
                      <Form onSubmit={handleOnSubmit}>
                        <Row>
                          <Col className="pr-1" md="12">
                            <h3 className="text-center mt-0">Localidad</h3>
                          </Col>
                        </Row>
                        <Row>
                          <Col className="pr-1" md="3">
                            <Form.Group>
                              <label>Orden</label>
                              <Form.Control
                                placeholder="Orden"
                                type="number"
                                min={1}
                                value={order}
                                required
                                onChange={(e) => setOrder(e.target.value)}
                              ></Form.Control>
                            </Form.Group>
                          </Col>
                          <Col className="pr-1" md="9">
                            <Form.Group>
                              <label>Nombre</label>
                              <Form.Control
                                placeholder="Nombre"
                                type="text"
                                value={name}
                                required
                                onChange={(e) => setName(e.target.value)}
                              ></Form.Control>
                            </Form.Group>
                          </Col>
                        </Row>
                        <Row>
                          <Col className="pr-1" md="12">
                            <Form.Group>
                              <label>Descripcion</label>
                              <Form.Control
                                as={'textarea'}
                                rows={5}
                                placeholder="Descripcion"
                                type="text"
                                value={detail}
                                onChange={(e) => setDetail(e.target.value)}
                              ></Form.Control>
                            </Form.Group>
                          </Col>
                        </Row>
                        <Row>
                          <Col className="pr-1" md="6">
                            <Form.Group>
                              <label>Longitud</label>
                              <Form.Control
                                value={longitud}
                                onChange={(e) => setLongitud(e.target.value)}
                                placeholder="Longitud"
                                type="text"
                              ></Form.Control>
                            </Form.Group>
                          </Col>

                          <Col className="pr-1" md="6">
                            <Form.Group>
                              <label>Latitud</label>
                              <Form.Control
                                value={latitud}
                                onChange={(e) => setLatitud(e.target.value)}
                                placeholder="Latitud"
                                type="text"
                              ></Form.Control>
                            </Form.Group>
                          </Col>
                        </Row>

                        <div className="d-flex justify-content-between">
                          <Button
                            type="button"
                            className={themeClasses.formCancelButton}
                            variant="secundary"
                            onClick={() => handleCloseFormModal()}
                          >
                            Cancelar
                          </Button>
                          {loading ?
                            <a variant="dark" type="button">
                              <Spinner
                                as="span"
                                animation="grow"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                                className="mr-1"
                              />
                              Guardando...
                            </a>
                            : null}
                          <Button
                            className={themeClasses.formSubmitButton}
                            type="submit"
                            variant="primary">
                            {itemId == null ? "Agregar y continuar" : "Actualizar datos"}
                          </Button>
                        </div>
                        <div className="clearfix"></div>
                      </Form>
                    </Container>
                  </Modal.Body>
                  <Modal.Footer></Modal.Footer>
                </Modal>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}

export default Location;
