import React, { useEffect } from "react";
import { Redirect } from "react-router";
import { Switch, Route } from "react-router-dom";
// react-bootstrap components

// core components
import Sidebar from "components/Sidebar/Sidebar.js";
import AdminNavbar from "components/Navbars/AdminNavbar.js";
import AdminFooter from "components/Footers/AdminFooter.js";
import FixedPlugin from "components/FixedPlugin/FixedPlugin.js";

// dinamically create dashboard routes
import routes from "routes.js";

import image1 from "assets/img/full-screen-image-1.jpg";
import image2 from "assets/img/full-screen-image-2.jpg";
import image3 from "assets/img/full-screen-image-3.jpg";
import image4 from "assets/img/full-screen-image-4.jpg";
import { useDispatch, useSelector } from "react-redux";
import { getRolList } from "actions/rolActions";
import useFetch from "hooks/useFetch";
import { useSnackbar } from "notistack";
import { logout } from "actions/authActions";

function Admin() {
  const { enqueueSnackbar } = useSnackbar();
  const { RefreshToken } = useFetch();


  const dispatch = useDispatch();
  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return getRoutes(prop.views);
      }

      if (prop.layout === "/results") {
        return (
          <Route
            path={prop.layout + prop.path}
            key={key}
            component={prop.component}
          />
        );
      } else {
        return null;
      }
    });
  };

  //RefreshToken every 55 minutes (3300000 miliseconds);
  useEffect(() => {
    let Interval;
    (async () => {
      Interval = setInterval(async () => {
        let isRefreshedToken = await RefreshToken();
        if (await isRefreshedToken) {
          //DO SOMETHING
        }
        else {
          dispatch(logout());
          enqueueSnackbar("Ha ocurrido un error inicie sesion nuevamente.", { variant: "error" })
        }
      }, 3300000);
    })();
    return () => clearInterval(Interval);
  }, []);

  return (
    <>

      <Switch>{getRoutes(routes)}</Switch>

    </>
  );
}

export default Admin;
