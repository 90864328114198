import React, { useEffect, useState } from "react";
// react component used to create charts
import ChartistGraph from "react-chartist";
// react components used to create a SVG / Vector map
import useFetch from "hooks/useFetch";
import { CircularProgress } from "@material-ui/core";
// react-bootstrap components
import {
  Card,
  Container,
  Row,
  Col,
} from "react-bootstrap";
import LineChart from "components/dashboard/LineChart";
import PieChar from "components/dashboard/PieChar";
import { SaleChart } from "components/dashboard/SaleChart";








function Dashboard() {
  const { fetchConTokenPromesa } = useFetch();

  //TO DO RECHARGE EVERY 3 MINUTES
  const [loading, setLoading] = useState(true);
  const [chartData, setChartData] = useState([]);
  const [chartSales, setChartSales] = useState([]);
  const [chartSalesBySeller, setChartSalesBySeller] = useState([]);
  const [salesTotal, setSalesTotal] = useState(0);


  const getActivitiesChartData = async () => {
    let response = await fetchConTokenPromesa(`activity/chartCount`);
    setChartData(response?.result);
  }

  const getSalesChartData = async () => {
    let response = await fetchConTokenPromesa(`activity/salesChart`);
    setChartSales(response?.eventos);
  }

  const getSalesChartDataBySeller = async () => {
    let response = await fetchConTokenPromesa(`activity/salesChartByseller`);
    setChartSalesBySeller(response?.ventas);
  }

  useEffect(() => {
    (async () => {
      setLoading(true);
      await getActivitiesChartData();
      await getSalesChartData();
      await getSalesChartDataBySeller();
      setLoading(false);
    })();

  }, []);

  useEffect(()=>{
    let sales = 0;

    for (const sale of chartSalesBySeller) {
      sales += Number(sale.monto);
    }    

    setSalesTotal(sales);
  },[chartSalesBySeller])

  const formatNumber = Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "usd",
    signDisplay: "never",
  });

  return (

    <Container fluid>
      {loading ?
        <div style={{ display: 'flex', alignSelf: 'center', alignItems: 'center', justifyContent: 'center' }}>
          <CircularProgress />
        </div>
        :
        <>
          <Row>
            <Col md="4">
              <Card>
                <Card.Header>
                  <Card.Title as="h4" className="text-uppercase">Próximos Eventos</Card.Title>
                    <p className="card-category">Con personas registradas</p>
                </Card.Header>
                <Card.Body style={{height:'350px'}} className='mb-5'>
                  <PieChar dataDb={chartData} /> 
                </Card.Body>
              </Card>
            </Col>
            <Col md="8">
              <Card>
                <Card.Header>
                  <Card.Title as="h4"  className="text-uppercase">Ventas por evento RD$</Card.Title>
                  <p className="card-category">Rendimiento de eventos</p>
                </Card.Header>
                <Card.Body style={{height:'350px'}} className='mb-5'>
                  <LineChart dataDb={chartSales}/>
                </Card.Body>

              </Card>
            </Col>
          </Row>

          <Row>
            <Col md="12">
              <Card>
                <Card.Header>
                  <Card.Title as="h4"  className="text-uppercase">Ventas por vendedor</Card.Title>
                  <p className="card-category">Total de ventas: RD{formatNumber.format(salesTotal)}</p>
                </Card.Header>
                <Card.Body style={{height:'350px'}} className='mb-5'>
                  <SaleChart dataDb={chartSalesBySeller}/>
                </Card.Body>

              </Card>
            </Col>
          </Row>

        </>
      }
    </Container>
  );
}

export default Dashboard;
