import { types } from "../types/types";

const initialState = {
  activityList: [],
  showActivityForm: false,
  seletedActivity: null,
};

const activityReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.getActivityList:
      return {
        ...state,
        activityList: action.payload,
      };
    case types.cleanActivityList:
      return {
        ...state,
        activityList: [],
      };
    case types.deleteActivity:
      return {
        ...state,
        activityList: state.activityList.filter((a) => a.id !== action.payload),
      };
    case types.addActivity:
      return {
        ...state,
        activityList: [action.payload, ...state.activityList],
      };

    case types.updateActivity:
      return {
        ...state,
        activityList: state.activityList.map((e) =>
          e.id === action.payload.id ? action.payload : e
        ),
      };

    case types.showActivityForm:
      return {
        ...state,
        showActivityForm: !state.showActivityForm,
      };

    case types.selectActivity:
      return {
        ...state,
        seletedActivity: action.payload,
      };

    case types.unSelectActivity:
      return {
        ...state,
        seletedActivity: null,
      };

    default:
      return state;
  }
};

export default activityReducer;
