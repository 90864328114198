import React, { useEffect } from "react";
import { SnackbarProvider } from 'notistack';

import { Provider } from "react-redux";
import { store } from "./redux/storeRedux";

import "@fortawesome/fontawesome-free/css/all.min.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "assets/scss/light-bootstrap-dashboard-pro-react.scss?v=2.0.0";
import "assets/css/demo.css";

import { AppRouter } from "routers/AppRouter";

const App = () => {

  return (
    <Provider store={store}>
      <SnackbarProvider maxSnack={5}>
        <AppRouter />
      </SnackbarProvider>
    </Provider>
  );
};

export default App;
