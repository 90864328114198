import React, { useState, useEffect } from "react";

// react-bootstrap components
import {
  OverlayTrigger,
  Button,
  Card,
  Tooltip,
  Table,
  Container,
  Row,
  Col,
} from "react-bootstrap";

import moment from "moment";
import ActivitiesAccessStyles from "./styles/ActivitiesAccessStyles";
import useFetch from "hooks/useFetch";
import Pagination from "components/Pagination/Pagination";
import Styles from "themes/Styles";
import { useSnackbar } from "notistack";
import AsyncSelect from "react-select/async";
import { formatDateToLocalDateString } from "utilities/DateFormat";
function ActivitiesAccess() {

  const classes = ActivitiesAccessStyles();
  const themeClasses = Styles();
  const { enqueueSnackbar } = useSnackbar();

  const [loading, setLoading] = useState(false);
  const { fetchConTokenPromesa } = useFetch();

  const [listData, setListData] = useState([]);
  const [selectedActivity, setSelectedActivity] = useState({value: "all",label: "TODOS"});

  const getActivitiesAccessListData = async (activityID) => {
    setLoading(true);
    let response = await fetchConTokenPromesa(`activity/accessList/${activityID}`);
   // console.log(`activity/accessList/${activityID}`)
    setLoading(false);
    setListData(response?.personList);
  };

  const getActivityForSelect = async (inputValue = "") => {
    let response = await fetchConTokenPromesa(`activity?&buscar=${inputValue}&page=1&limit=50`);
    let arrayForActivitySelect = [
      {
        value: "all",
        label: "TODOS",
      },
      {
        value: "today",
        label: "HOY",
      }
    ]
    let responseArray = response?.activityList.map((item) => ({
      value: item.id,
      label: item.resumen,
    }));
    return arrayForActivitySelect.concat(responseArray);
  }

  useEffect(() => {
    getActivitiesAccessListData(selectedActivity.value)
  }, []);

  return (
    <Container fluid>
      <Row>
        <Col md="12">
          <Card className="strpied-tabled-with-hover">
            <Card.Header>
              <Card.Title className={themeClasses.cardTitleHeader}>
                <h4 className={themeClasses.cardTitle}> Acceso a evento </h4>
                <div style={{ width: '77%' }}>
                  <AsyncSelect
                    openMenuOnClick={false}
                    placeholder="Buscar actividad"
                    classNamePrefix="react-select"
                    onChange={(e) => {
                      getActivitiesAccessListData(e.value);
                      setSelectedActivity(e);
                    }}
                    value={selectedActivity}
                    cacheOptions
                    defaultOptions
                    loadOptions={getActivityForSelect} />
                </div>

              </Card.Title>
            </Card.Header>
            <Card.Body className="table-responsive p-0">
              <div className={classes.root}>
                {loading ?
                  <p>Cargando...</p>
                  :

                  <Table className="table-hover table-striped w-full">
                    <thead>
                      <tr>
                        <th>Documento</th>
                        <th>Nombre</th>
                        <th>Apellidos</th>
                        <th>Fecha</th>
                      </tr>
                    </thead>

                    <tbody>
                      {listData?.map((item, index) => (
                        <tr key={index}>
                          <td>{item.cedula_passport}</td>
                          <td>{item.firstname}</td>
                          <td>{item.lastname}</td>
                          <td>{formatDateToLocalDateString(item.fecha_hora)}</td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                }
                {/*   <div style={{ alignSelf: 'center' }}>
                  <Pagination className={classes.pagination} pages={paginationData?.total_page} currentPage={currentPage} onSelectPage={onChangePage} />
              </div>*/}
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}

export default ActivitiesAccess;
