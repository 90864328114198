import React, { useState, useEffect } from "react";

// react-bootstrap components
import {
  OverlayTrigger,
  Button,
  Card,
  Form,
  Tooltip,
  Table,
  Container,
  Row,
  Col,
  Modal,
  Spinner,
} from "react-bootstrap";
import SpeakersStyles from "./styles/SpeakersStyles";
import useFetch from "hooks/useFetch";
import Pagination from "components/Pagination/Pagination";
import useSweetAlertConfirm from "hooks/useSweetAlertConfirm";
import Styles from "themes/Styles";
import { isMobile } from "react-device-detect";
import InputSearcher from "components/InputSearcher/InputSearcher";
import ReactDatetime from "react-datetime";
import moment from "moment";
import { useSnackbar } from "notistack";
import { shirtSizes, documentTypes } from '../../consts/APPCONSTANTS'
import InputMask from 'react-input-mask';
import { CountryDropdown } from 'react-country-region-selector';
import ExportToExcel from "utilities/ExportToExcel";
import AppConfig from "AppConfig";

function Speakers() {

  const [alert, setAlert, closeAlert] = useSweetAlertConfirm();
  const classes = SpeakersStyles();
  const themeClasses = Styles();
  const { enqueueSnackbar } = useSnackbar();

  const [loading, setLoading] = useState(false);
  const { fetchConTokenPromesa } = useFetch();

  const [formModalVisible, setFormModalVisible] = useState(false);

  const [filterText, setFilterText] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [listData, setListData] = useState([]);
  const [paginationData, setPaginationData] = useState({});
  const [anUserIsSelected, setAnUserIsSelected] = useState(false);

  const [country, setCountry] = useState("Dominican Republic");
  const [cedula_passport, setCedula_passport] = useState("");
  const [documentType, setDocumentType] = useState(1);
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [sex, setSex] = useState(1);
  const [birthday, setBirthday] = useState(null);
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [celphone, setCelphone] = useState("");
  const [direccion, setDireccion] = useState("");
  const [comentario, setComentario] = useState("");
  const [idSelected, setIdSelected] = useState('');



  const handleSelectItem = (item) => {
    setIdSelected(item.id)
    setAnUserIsSelected(true);
    setCountry(item.pais);
    setDocumentType(item.tipo_documento);
    setCedula_passport(item.cedula_passport);
    setFirstname(item.firstname);
    setSex(item.sexo);
    setEmail(item.email);
    setPhone(item.phone);
    setCelphone(item.celphone);
    setDireccion(item.direccion);
    setComentario(item.comentario);
    setBirthday(item.birthday);
    setLastname(item.lastname);
    setFormModalVisible(true);
  };

  const clearForm = () => {
    setIdSelected('')
    setAnUserIsSelected(false);
    setCountry("Dominican Republic");
    setDocumentType(1);
    setCedula_passport("");
    setFirstname("");
    setSex(1);
    setEmail("");
    setPhone("");
    setCelphone("");
    setDireccion("");
    setComentario("");
    setBirthday(null);
    setLastname("");
  }

  const handleCloseFormModal = () => {
    clearForm();
    setFormModalVisible(false);
  };

  const onChangePage = (selectedPage) => {
    setCurrentPage(selectedPage);
    getSpeakersListData(selectedPage);
  }

  const getExportData = async () => {
    let response = await fetchConTokenPromesa(`person?id_tipo=3&page=1&limit=999999999999`);
    let data = response?.personList;
    let newData = data.map((item) => ({
      ID: item.id,
      Pais: item.pais,
      Tipo_Documento: documentTypes.find((type) => type.value == item.tipo_documento).label,
      Documento: item.cedula_passport + '',
      Nombres: item.firstname,
      Apellidos: item.lastname,
      Sexo: item.sexo == 1 ? 'Hombre' : 'Mujer',
      Email: item.email,
      Fecha_Nacimiento: item?.birthday ? moment.parseZone(item.birthday).format("DD/MM/YYYY") : null,
      Direccion: item.direccion,
      Telefono: item.phone,
      Celular: item.celphone,
      Talla_Shirt: shirtSizes.find((shirt) => shirt.value == item.talla_shirt)?.label,
      Fecha_Pago: item?.fecha_pago ? moment.parseZone(item.fecha_pago).format("DD/MM/YYYY") : null,
      Empresa: item.empresa,
      Telefono_Empresa: item.tel_empresa,
      Puesto_Empresa: item.puesto_empresa

    }));
    return newData;
  }

  const getSpeakersListData = async (page, filter = "") => {
    //TODO Search data from STAFF/PERSONS FILTERED BY FIELDS
    setLoading(true);
    let response = await fetchConTokenPromesa(`person?id_tipo=3${filter.length > 0 ? '&buscar=' + filter : ""}&page=${page || currentPage}&limit=${AppConfig.TableLimit}`);
    setListData(response?.personList);
    setPaginationData(response?.pagination);
    setLoading(false);
  };

  const addNewSpeakers = async (e) => {
    e.preventDefault();
    if (!validateForm()) {
      return;
    }
    setLoading(true);
    await fetchConTokenPromesa(
      "person",
      {
        id_tipo: 3, //3 BECAUSE 3 IS STAFF
        id_face: "",
        cedula_passport,
        firstname,
        lastname,
        birthday: birthday ? moment.parseZone(birthday).format("YYYY-MM-DD") : null,
        email,
        phone,
        celphone,
        direccion,
        comentario,
        tipo_documento: documentType,
        pais: country,
        sexo: sex,
      },
      "POST"
    );
    clearForm();
    getSpeakersListData();
    setFilterText("");
    setLoading(false);
  }
  const updateSpeakers = async (e) => {
    e.preventDefault();
    if (!validateForm()) {
      return;
    }
    setLoading(true);
    await fetchConTokenPromesa(
      `person/${idSelected}`,
      {
        id_tipo: 3, //3 BECAUSE 3 IS PLAYER
        id_face: "",
        cedula_passport,
        firstname,
        lastname,
        birthday: birthday ? moment.parseZone(birthday).format("YYYY-MM-DD") : null,
        email,
        phone,
        celphone,
        direccion,
        comentario,
        tipo_documento: documentType,
        pais: country,
        sexo: sex,
      },
      "PUT"
    );
    handleCloseFormModal();
    getSpeakersListData();
    setFilterText("");
    setLoading(false);
  }

  const handleDeleteItem = (item) => {
    setAlert(
      `Se eliminará el registro de "${item.firstname}"`,
      () => deleteItem(item),
      item
    );
  };

  const deleteItem = async (item) => {
    closeAlert();
    setLoading(true);
    await fetchConTokenPromesa(
      `person/${item.id}`,
      {},
      "DELETE"
    );
    getSpeakersListData();
    setFilterText("");
    setLoading(false);
  };

  const validateForm = () => {
    let currentDate = moment.parseZone(moment());
    let birthdayDate = moment.parseZone(birthday);

    if (birthdayDate.isAfter(currentDate)) {
      enqueueSnackbar('La fecha de nacimiento no puede ser mayor a la fecha actual', { variant: 'error' })
      return false;
    }

    if (cedula_passport?.length > 9 && cedula_passport?.length < 11 && cedula_passport?.length > 1) {
      enqueueSnackbar('La Cédula o pasaporte debe tener 9 o 11 Dígitos', { variant: 'error' });
      return false;
    }
    return true;
  }

  const handleDocumentTypeChange = (value) => {
   setDocumentType(value);
   setCountry('Dominican Republic');
  }

  const handleFilterList = (e) => {
    getSpeakersListData(1, e.target.value);
    setFilterText(e.target.value)
    setCurrentPage(1)
  }

  const handleSubmit = (e) => {
    if (!anUserIsSelected) {
      addNewSpeakers(e);
    } else {
      updateSpeakers(e);
    }
  }


  const handleExportToExcel = async (title) => {
    let exportData = await getExportData();
    if(exportData.length > 0){
      ExportToExcel(exportData, title);
    }else{
      enqueueSnackbar('No hay informacion para exportar',{variant:'error'});
    }
  }

  useEffect(() => {
    getSpeakersListData();
  }, []);

  return (
    <Container fluid>
      {alert}
      <Row>
        <Col md="12">
          <Card className="strpied-tabled-with-hover">
            <Card.Header>
              <Card.Title className={themeClasses.cardTitleHeader}>
                <h4 className={themeClasses.cardTitle}> Speakers </h4>
                <div className={themeClasses.simplyRowDivider} />
                <div className={themeClasses.secondaryHeaderSearcherContainer}>
                  <InputSearcher value={filterText} onChange={handleFilterList} placeholder="Buscar" />
                  <div className={themeClasses.mediumRowDivider} />
                  <Button
                    size="sm"
                    onClick={() => setFormModalVisible(true)}
                    className={themeClasses.headerButton}
                  >
                    {isMobile ? "➕" : 'Agregar'}
                  </Button>
                  <div className={themeClasses.mediumRowDivider} />
                  {/* <OverlayTrigger overlay={<Tooltip >Exportar a excel</Tooltip>}>
                    <Button
                      className={themeClasses.exportButton}
                      onClick={(e) => { handleExportToExcel('Speakers') }}
                      variant="success"
                    >
                      <i class="fas fa-file-excel"></i>
                    </Button>
                  </OverlayTrigger> */}
                </div>
              </Card.Title>
            </Card.Header>
            <Card.Body className="table-responsive p-0">
              <div className={classes.root}>
                
                {loading ?
                  <p>Cargando...</p>
                  :
                  <Table className="table-hover table-striped w-full">
                    <thead>
                      <tr>
                        <th>Id</th>
                        <th>Nombres</th>
                        <th>Apellidos</th>
                        <th>email</th>
                        <th>Whatsapp</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {listData.map((item, index) => (
                        <tr key={index}>
                          <td>{item.id}</td>
                          <td>{item.firstname}</td>
                          <td>{item.lastname}</td>
                          <td>{item.email}</td>
                          <td>{item.celphone}</td>
                          <td className="td-actions text-right">
                            <OverlayTrigger
                              overlay={<Tooltip id="tooltip-48903503">Modificar</Tooltip>}
                            >
                              <Button
                                className="btn-link btn-xl"
                                onClick={(e) => handleSelectItem(item)}
                                variant="success"
                              >
                                <i className="fas fa-edit"></i>
                              </Button>
                            </OverlayTrigger>

                            <OverlayTrigger
                              overlay={<Tooltip id="tooltip-255158527">Borrar</Tooltip>}
                            >
                              <Button
                                className="btn-link btn-xl"
                                onClick={(e) => handleDeleteItem(item)}
                                variant="danger"
                              >
                                <i className="fas fa-times"></i>
                              </Button>
                            </OverlayTrigger>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                }
                <div style={{ alignSelf: 'center' }}>
                  <Pagination pages={paginationData?.total_page} onSelectPage={onChangePage} currentPage={currentPage} />
                </div>

                <Modal show={formModalVisible} onHide={() => handleCloseFormModal()} size="xl">
                  <Modal.Header closeButton></Modal.Header>
                  <Modal.Body className="p-1">
                    <Container fluid>
                      <Form onSubmit={handleSubmit}>
                        <Row>
                          <Col className="" md="11">
                            <h3 className="text-center">Speaker</h3>
                          </Col>
                        </Row>
                        <Row>
                          <Col className="" md="2">
                            <Form.Group>
                              <label>Tipo</label>
                              <select value={documentType} onChange={(e) => handleDocumentTypeChange(e.target.value)} disabled={anUserIsSelected ? true : false} className="form-select" aria-label="Default select example">
                                {documentTypes.map((type, index) => (
                                  <option key={index} value={type.value}>{type.label}</option>
                                ))}
                              </select>
                            </Form.Group>
                          </Col>
                          <Col className="" md="6">
                            <label>Pais</label>
                            <CountryDropdown className="form-select" value={country} onChange={(country) => setCountry(country)} disabled={documentType == 1 ? true:false}/>
                          </Col>
                          <Col className="" md="4">
                            <Form.Group>
                              <label>Numero de Documento</label>
                              <Form.Control
                                // disabled={anUserIsSelected ? true : false}
                                placeholder="Numero de Documento"
                                type="text"
                                inputMode="numeric"
                                minLength={9}
                                maxLength={11}
                                value={cedula_passport}
                                onChange={(e) => setCedula_passport(e.target.value)}
                              />
                            </Form.Group>
                          </Col>
                        </Row>
                        <Row>
                          <Col className="" md="6">
                            <Form.Group>
                              <label>Nombre</label>
                              <Form.Control
                                placeholder="Nombre"
                                type="text"
                                value={firstname}
                                required
                                onChange={(e) => setFirstname(e.target.value)}
                              ></Form.Control>
                            </Form.Group>
                          </Col>
                          <Col className="" md="6">
                            <Form.Group>
                              <label>Apellido</label>
                              <Form.Control
                                value={lastname}
                                required
                                onChange={(e) => setLastname(e.target.value)}
                                placeholder="Apellido"
                                type="text"
                              ></Form.Control>
                            </Form.Group>
                          </Col>
                        </Row>
                        <Row>
                          <Col className="" md="6">
                            <Form.Group>
                              <label>Sexo</label>
                              <Row className="mb-3">
                                <Col md="6">
                                  <Form.Check
                                    inline
                                    checked={sex == 1 ? true : false}
                                    onChange={(e) => {
                                      setSex(1)
                                    }}
                                    label="Hombre"
                                    type="radio"
                                  />
                                </Col>
                                <Col md="6">
                                  <Form.Check
                                    inline
                                    checked={sex == 2 ? true : false}
                                    onChange={(e) => {
                                      setSex(2)
                                    }}
                                    label="Mujer"
                                    type="radio"
                                  />
                                </Col>
                              </Row>
                            </Form.Group>
                          </Col>
                          <Col className="" md="6">
                            <Form.Group>
                              <label>Fecha de nacimiento</label>
                              <ReactDatetime
                                timeFormat={false}
                                inputProps={{
                                  className: "form-control",
                                  placeholder: "Click para seleccionar",
                                }}
                                value={
                                  birthday
                                    ? moment.parseZone(birthday).format("DD/MM/YYYY")
                                    : null
                                }
                                dateFormat="DD/MM/YYYY"
                                onChange={(e) => setBirthday(e)}
                              />
                            </Form.Group>
                          </Col>
                        </Row>
                        <Row>
                          <Col md="12">
                            <Form.Group>
                              <label>Dirección</label>
                              <Form.Control
                                value={direccion}
                                onChange={(e) => setDireccion(e.target.value)}
                                placeholder="Dirección"
                                type="text"
                              ></Form.Control>
                            </Form.Group>
                          </Col>
                        </Row>
                        <Row>
                          <Col className="" md="6">
                            <label>Whatsapp</label>
                            <Form.Group>
                              <InputMask
                                mask='999-999-9999'
                                class="form-control"
                                placeholder="Whatsapp"
                                value={celphone}
                                onChange={(e) => setCelphone(e.target.value)} />
                            </Form.Group>
                          </Col>
                          <Col className="" md="6">
                            <Form.Group>
                              <label htmlFor="email">Email</label>
                              <Form.Control
                                placeholder="Email"
                                type="email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                              ></Form.Control>
                            </Form.Group>
                          </Col>
                        </Row>
                        <Row>
                          <Col md="12">
                            <Form.Group>
                              <label>Briefing</label>
                              <Form.Control
                                cols="80"
                                placeholder="Briefing"
                                as="textarea"
                                rows={5}
                                value={comentario}
                                onChange={(e) => setComentario(e.target.value)}
                              ></Form.Control>
                            </Form.Group>
                          </Col>
                        </Row>

                        <div className="d-flex justify-content-between">
                          <Button
                            type="button"
                            className={themeClasses.formCancelButton}
                            variant="secundary"
                            onClick={() => handleCloseFormModal()}
                          >
                            Cancelar
                          </Button>
                          {loading ?
                            <a variant="dark" type="button">
                              <Spinner
                                as="span"
                                animation="grow"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                                className="mr-1"
                              />
                              Guardando...
                            </a>
                            : null}
                          <Button
                            className={themeClasses.formSubmitButton}
                            type="submit"
                            variant="primary"
                          >
                            {!anUserIsSelected ? "Agregar y continuar" : "Actualizar datos"}
                          </Button>
                        </div>
                        <div className="clearfix"></div>
                      </Form>
                    </Container>
                  </Modal.Body>
                  <Modal.Footer></Modal.Footer>
                </Modal>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}

export default Speakers;
