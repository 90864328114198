import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        minHeight: '1rem',
        overflow: 'auto',
        backgroundColor: theme.palette.background.paper,
    },
}));

export default function ListComponent({ ListItems, onSelected }) {
    const classes = useStyles();
    const [selectedIndex, setSelectedIndex] = React.useState(1);

    const handleListItemClick = (item,index) => {
        setSelectedIndex(index);
        onSelected(item,index);
    };

    return (
        <div className={classes.root}>
            <List component="nav" aria-label="secondary mailbox folder">
                {ListItems?.map((item, index) => 
                    <ListItem
                        key={index}
                        button
                        selected={selectedIndex === index}
                        onClick={(event) => handleListItemClick(item,index)}
                    >
                        <ListItemText primary={`${item.name}`} />
                    </ListItem>
                )}
            </List>
        </div>
    );
}