import { types } from "../types/types";

const initialState = {
  personList: [],
  showPersonForm: false,
  seletedPerson: null,
  formType: null,
};

const personReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.getPersonList:
      return {
        ...state,
        personList: action.payload,
      };
    case types.cleanPersonList:
      return {
        ...state,
        personList: [],
      };
    case types.deletePerson:
      return {
        ...state,
        personList: state.personList.filter(
          (p) => p.cedula_passport !== action.payload
        ),
      };
    case types.addPerson:
      return {
        ...state,
        personList: [action.payload, ...state.personList],
      };

    case types.updatePerson:
      return {
        ...state,
        personList: state.personList.map((e) =>
          e.cedula_passport === action.payload.cedula_passport
            ? action.payload
            : e
        ),
      };

    case types.showPersonForm:
      return {
        ...state,
        showPersonForm: !state.showPersonForm,
        formType: action.payload ? action.payload : null,
      };

    case types.selectPerson:
      return {
        ...state,
        seletedPerson: action.payload,
      };

    case types.unSelectPerson:
      return {
        ...state,
        seletedPerson: null,
      };

    default:
      return state;
  }
};

export default personReducer;
