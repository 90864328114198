import { unSelectPerson } from "actions/personActions";
import { useState } from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import { useDispatch, useSelector } from "react-redux";

const useSweetAlertConfirm = () => {
  const [alert, setAlert] = useState(null);
  const dispatch = useDispatch();

  const hideAlert = () => {
    setAlert(null);
    dispatch(unSelectPerson());
  };

  const handleAlert = (
    msg,
    successDelete,
    idRegistro = null,
    title = "Confirmar",
    hide = hideAlert
  ) => {
    setAlert(
      <SweetAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title={title}
        onConfirm={() => successDelete(idRegistro)}
        onCancel={() => hide()}
        confirmBtnBsStyle="info"
        cancelBtnBsStyle="danger"
        confirmBtnText="Aceptar"
        cancelBtnText="Cancelar"
        showCancel
      >
        {msg}
      </SweetAlert>
    );
  };
  return [alert, handleAlert, hideAlert];
};

export default useSweetAlertConfirm;
