import { makeStyles } from "@material-ui/styles";
import { isMobile } from 'react-device-detect'
import COLORS from "./Colors";

export default makeStyles({
    headerButton: {
        backgroundColor: COLORS.primary,
        borderColor: COLORS.primary,
        '&:hover': {
            backgroundColor: COLORS.secondary,
            borderColor: COLORS.secondary,
        },
        '&:active': {
            backgroundColor: COLORS.secondary,
            borderColor: COLORS.secondary,
        },
        borderRadius: '6px',
        width: '25%',
        height: '2.5rem',
    },
    headerSecondaryButton: {
        backgroundColor: COLORS.secondary,
        borderColor: COLORS.secondary,
        '&:hover': {
            backgroundColor: COLORS.primary,
            borderColor: COLORS.primary,
        },
        borderRadius: '6px'
    },
    formSubmitButton: {
        backgroundColor: COLORS.primary,
        borderColor: COLORS.primary,
        '&:hover': {
            backgroundColor: COLORS.secondary,
            borderColor: COLORS.secondary,
        },
        borderRadius: '6px'
    },
    formCancelButton: {
        backgroundColor: COLORS.tertiary,
        borderColor: COLORS.tertiary,
        borderRadius: '6px'
    },
    centeredModal: {
        justifyContent: 'center',
        alignSelf: 'center'
    },
    headerSearcherContainer: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%'
    },
    secondaryHeaderSearcherContainer: {
        display: 'flex',
        flexDirection: 'row',
        width: '70%'
    },
    searchButton: {
        backgroundColor: COLORS.secondary,
        borderColor: COLORS.secondary,
        '&:hover': {
            backgroundColor: COLORS.secondary,
            borderColor: COLORS.secondary,
        },
        borderRadius: '6px',
        width: isMobile ? '25%' : '20%',
        height: '2.5rem',
    },
    simplyDivider: {
        height: '15px'
    },
    simplyRowDivider: {
        width: '2%'
    },
    mediumRowDivider: {
        width: '10%'
    },
    countrySelector: {
        width: '100%',
        fontSize: '100%'
    },
    cardTitleHeader: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: "center",
        justifyContent: 'space-between'
    },
    cardTitle: {
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        display: '-webkit-box',
        WebkitLineClamp: 1,
        WebkitBoxOrient: 'vertical'
    },
    exportButton: {
        backgroundColor: COLORS.secondary,
        borderColor: COLORS.secondary,
        '&:hover': {
            backgroundColor: COLORS.secondary,
            borderColor: COLORS.secondary,
        },
        '&:active': {
            backgroundColor: COLORS.secondary,
            borderColor: COLORS.secondary,
        },
        fontSize:'19px',
        textAlign:'center',
        justifyContent:'center',
        borderRadius: '6px',
        width: isMobile ? '50px' : '50px',
        height: '2.5rem',
    }
})