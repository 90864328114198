import Dashboard from "views/Dashboard.js";
import Login from "views/auth/Login";
import Users from "views/users/Users";
import Activities from "views/activities/Activities";
import QuestionsAnswers from "./views/questionsAnswers/QuestionsAnswers";
import Message from "views/message/Message";
import ActivitiesAccess from "views/activitiesAccess/ActivitiesAccess";
import Location from "views/Location/Location";
import RestorePassword from "views/auth/RestorePassword";
import Pagos from "views/pagos/Pagos";
import Guests from "views/guests/Guests";
import Speakers from "views/Speakers/Speakers";
import entradasActivity from "views/entradasActivity/entradasActivity";

var routes = [
  {
    path: "/dashboard",
    layout: "/admin",
    name: "Dashboard",
    icon: "nc-icon nc-chart-pie-35",
    component: Dashboard,
  },

  {
    collapse: true,
    path: "/ingresados",
    name: "Ingresados",
    layout: "/admin",
    state: "openPersons",
    icon: "nc-icon nc-single-02",
    views: [
      {
        path: "/speakers",
        layout: "/admin",
        name: "Speakers",
        mini: "P",
        component: Speakers,
      },
      {
        path: "/invitados",
        layout: "/admin",
        name: "Invitados",
        mini: "P",
        component: Guests,
      },
      {
        path: "/usuarios",
        layout: "/admin",
        name: "Usuarios",
        mini: "U",
        component: Users,
      },
    ],
  },
  {
    collapse: true,
    path: "/forms",
    layout: "/admin",
    name: "Eventos",
    state: "openActivities",
    icon: "nc-icon nc-notes",
    views: [
      {
        path: "/Eventos",
        layout: "/admin",
        name: "Lista de eventos",
        mini: "LA",
        component: Activities,
      },
      {
        path: "/entradas",
        layout: "/admin",
        name: "Lista de Entradas",
        mini: "E",
        component: entradasActivity,
      },
      {
        path: "/acceso-eventos",
        layout: "/admin",
        name: "Registro de accesos",
        mini: "RA",
        component: ActivitiesAccess,
      },
    ],
  },
  {
    path: "/location",
    layout: "/admin",
    name: "Localidades",
    icon: "nc-icon nc-pin-3",
    component: Location,
  },
  {
    path: "/pagos",
    layout: "/admin",
    name: "pagos",
    icon: "nc-icon nc-bank",
    component: Pagos,
  },
  {
    path: "/faq",
    layout: "/admin",
    name: "Temas y Preguntas",
    icon: "nc-icon nc-chat-round",
    component: QuestionsAnswers,
  },
  {
    path: "/messages",
    layout: "/admin",
    name: "Mensajes",
    icon: "nc-icon nc-email-83",
    component: Message,
  },
  {
    path: "/password-reset/:id/:token",
    layout: "/auth",
    name: "Cambiar Contraseña",
    mini: "CC",
    component: RestorePassword,
  },
  {
    path: "/login",
    layout: "/auth",
    name: "Login Page",
    mini: "LP",
    component: Login,
  }
];
export default routes;
