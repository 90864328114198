import React, { useState, useEffect } from "react";

// react-bootstrap components
import {
  OverlayTrigger,
  Button,
  Card,
  Form,
  Tooltip,
  Table,
  Container,
  Row,
  Col,
  Modal,
  Spinner,
  FormCheck,
} from "react-bootstrap";
import GuestsStyles from "./styles/UsersStyles";
import useFetch from "hooks/useFetch";
import Pagination from "components/Pagination/Pagination";
import useSweetAlertConfirm from "hooks/useSweetAlertConfirm";
import Styles from "themes/Styles";
import InputSearcher from "components/InputSearcher/InputSearcher";
import { isMobile } from "react-device-detect";
import { useSnackbar } from "notistack";
import { roles } from "consts/APPCONSTANTS";
import ExportToExcel from "utilities/ExportToExcel";
import AppConfig from "AppConfig";
import moment from "moment";

function Users() {
  const [alert, setAlert, closeAlert] = useSweetAlertConfirm();
  const classes = GuestsStyles();
  const themeClasses = Styles();
  const { enqueueSnackbar } = useSnackbar();

  const [loading, setLoading] = useState(true);
  const { fetchConTokenPromesa } = useFetch();

  const [formModalVisible, setFormModalVisible] = useState(false);

  const [filterText, setFilterText] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [listData, setListData] = useState([]);
  const [paginationData, setPaginationData] = useState({});

  const [itemId, setItemId] = useState();
  const [password, setPassword] = useState("");
  const [repeatPassword, setRepeatPassword] = useState("");
  const [documentId, setDocumentId] = useState();
  const [status, setStatus] = useState(true);
  const [role, setRole] = useState(1);
  const [name, setName] = useState("");
  const [acceso_portal, setAcceso_portal] = useState(false);

  const handleSelectItem = (item) => {
    setItemId(item.id_usuario);
    setDocumentId(item.cedula_passport);
    setStatus(item.status == 1 ? true : false);
    setFormModalVisible(true);
    setRole(item.rol);
    setName(item.name);
    setAcceso_portal(item.acceso_portal == 1 ? true : false);
  };

  const clearForm = () => {
    setItemId(null);
    setPassword("");
    setRepeatPassword("");
    setDocumentId("");
    setStatus(true);
    setRole(1);
    setName("");
    setAcceso_portal(false);
  };

  const handleCloseFormModal = () => {
    clearForm();
    setFormModalVisible(false);
  };

  const onChangePage = (selectedPage) => {
    setCurrentPage(selectedPage);
    getUsersListData(selectedPage);
  };

  const getExportData = async () => {
    let response = await fetchConTokenPromesa(`auth?page=1&limit=999999999999`);
    let data = response?.userList;
    let newData = data.map((item) => ({
      ID: item.id_usuario,
      Documento: item.cedula_passport + "",
      Rol: roles.find((role) => role.value == item.tipo_persona)?.label,
      Estado: item.status == 1 ? "Activo" : "Inactivo",
      Ultimo_Acceso: item.ultimo_acceso,
    }));
    return newData;
  };

  const getUsersListData = async (page, filter = "") => {
    let response = await fetchConTokenPromesa(
      `auth?${filter.length > 0 && "&buscar=" + filter}&page=${
        page || currentPage
      }&limit=${AppConfig.TableLimit}`
    );
    setLoading(false);
    setListData(response?.userList);
    setPaginationData(response?.pagination);
  };

  const addNewUser = async (e) => {
    e.preventDefault();
    if (!validateForm()) {
      return;
    }
    setLoading(true);
    await fetchConTokenPromesa(
      "auth/new",
      {
        documentId,
        password: password == "" ? null : password,
        rol: role,
        name,
        acceso_portal: acceso_portal ? 1:0,
      },
      "POST"
    );
    clearForm();
    getUsersListData();
    setFilterText("");
    setLoading(false);
  };
  const updateUser = async (e) => {
    e.preventDefault();
    if (!validateForm()) {
      return;
    }
    setLoading(true);
    await fetchConTokenPromesa(
      `auth/${documentId}`,
      {
        documentId,
        password: password == "" ? null : password,
        status: status ? 1 : 0,
        rol: role,
        name,
        acceso_portal: acceso_portal ? 1:0 
      },
      "PUT"
    );
    handleCloseFormModal();
    getUsersListData();
    setFilterText("");
    setLoading(false);
  };

  const handleDeleteItem = (item) => {
    setAlert(
      `Se eliminará el registro con id "${item.id_usuario}"`,
      () => deleteItem(item),
      item
    );
  };

  const deleteItem = async (item) => {
    closeAlert();
    setLoading(true);
    await fetchConTokenPromesa(`auth/${item.cedula_passport}`, {}, "DELETE");
    getUsersListData();
    setFilterText("");
    setLoading(false);
  };

  const handleFilterList = (e) => {
    getUsersListData(1, e.target.value);
    setFilterText(e.target.value);
    setCurrentPage(1);
  };

  const handleSubmit = (e) => {
    if (!itemId) {
      addNewUser(e);
    } else {
      updateUser(e);
    }
  };

  const validateForm = () => {
    if (password) {
      if (password != repeatPassword) {
        enqueueSnackbar("Las contraseñas no coinciden", { variant: "error" });
        return false;
      }
    }
    if (!Number(documentId)) {
      enqueueSnackbar("Introduzca una Cédula o pasaporte valido", {
        variant: "error",
      });
      return false;
    }
    if (documentId.length < 9) {
      enqueueSnackbar("La Cédula o pasaporte debe tener 9 o 11 Dígitos", {
        variant: "error",
      });
      return false;
    }
    if (documentId.length > 9 && documentId.length < 11) {
      enqueueSnackbar("La Cédula o pasaporte debe tener 9 o 11 Dígitos", {
        variant: "error",
      });
      return false;
    }
    return true;
  };

  const handleExportToExcel = async (title) => {
    let exportData = await getExportData();
    ExportToExcel(exportData, title);
  };

  useEffect(() => {
    getUsersListData();
  }, []);

  console.log(acceso_portal)

  return (
    <Container fluid>
      {alert}
      <Row>
        <Col md="12">
          <Card className="strpied-tabled-with-hover">
            <Card.Header>
              <Card.Title className={themeClasses.cardTitleHeader}>
                <h4 className={themeClasses.cardTitle}> Usuarios </h4>
                <div className={themeClasses.simplyRowDivider} />
                <div className={themeClasses.secondaryHeaderSearcherContainer}>
                  <InputSearcher
                    value={filterText}
                    onChange={handleFilterList}
                    placeholder="Buscar"
                  />
                  <div className={themeClasses.mediumRowDivider} />
                  <Button
                    size="sm"
                    onClick={() => setFormModalVisible(true)}
                    className={themeClasses.headerButton}
                  >
                    {isMobile ? "➕" : "Agregar"}
                  </Button>
                  <div className={themeClasses.mediumRowDivider} />
                  {/* <OverlayTrigger overlay={<Tooltip>Exportar a excel</Tooltip>}>
                    <Button
                      className={themeClasses.exportButton}
                      onClick={(e) => {
                        handleExportToExcel("Usuarios");
                      }}
                      variant="success"
                    >
                      <i class="fas fa-file-excel"></i>
                    </Button>
                  </OverlayTrigger> */}
                </div>
              </Card.Title>
            </Card.Header>
            <Card.Body className="table-responsive p-0">
              <div className={classes.root}>
                {loading ? (
                  <p>Cargando...</p>
                ) : (
                  <Table className="table-hover table-striped w-full">
                    <thead>
                      <tr>
                        <th>ID</th>
                        <th>Nombre</th>
                        <th>Rol</th>
                        <th>Estado</th>
                        <th>Ultimo acceso</th>
                      </tr>
                    </thead>
                    <tbody>
                      {listData.map((item, index) => (
                        <tr key={index}>
                          <td>{item.id_usuario}</td>
                          <td>{item.name}</td>
                          <td>
                            {
                              roles.find((role) => role.value == item.rol)
                                ?.label
                            }
                          </td>
                          <td>{item.status == 0 ? "Inactivo" : "Activo"}</td>
                          <td>
                            {item.ultimo_acceso
                              ? moment(item.ultimo_acceso).format(
                                  "DD-MM-YYYY hh:mm:ss a"
                                )
                              : "Sin acceder"}
                          </td>
                          <td className="td-actions text-right">
                            <OverlayTrigger
                              overlay={
                                <Tooltip id="tooltip-48903503">
                                  Modificar
                                </Tooltip>
                              }
                            >
                              <Button
                                className="btn-link btn-xl"
                                onClick={(e) => handleSelectItem(item)}
                                variant="success"
                              >
                                <i className="fas fa-edit"></i>
                              </Button>
                            </OverlayTrigger>

                            <OverlayTrigger
                              overlay={
                                <Tooltip id="tooltip-255158527">Borrar</Tooltip>
                              }
                            >
                              <Button
                                className="btn-link btn-xl"
                                onClick={(e) => handleDeleteItem(item)}
                                variant="danger"
                              >
                                <i className="fas fa-times"></i>
                              </Button>
                            </OverlayTrigger>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                )}
                <div style={{ alignSelf: "center" }}>
                  <Pagination
                    className={classes.pagination}
                    pages={paginationData?.total_page}
                    currentPage={currentPage}
                    onSelectPage={onChangePage}
                  />
                </div>

                <Modal
                  show={formModalVisible}
                  onHide={() => handleCloseFormModal()}
                >
                  <Modal.Header closeButton></Modal.Header>
                  <Modal.Body className="p-1">
                    <Container fluid>
                      <Form onSubmit={handleSubmit}>
                        <Row>
                          <Col className="pr-1" md="12">
                            <h3 className="text-center">Usuario</h3>
                          </Col>
                        </Row>
                        <Row>
                          <Col md="12">
                            <label>Nombre</label>
                            <Form.Control
                              placeholder="Nombre"
                              type="text"
                              value={name}
                              required
                              onChange={(e) => setName(e.target.value)}
                            />
                          </Col>
                          <Col md="12">
                            <label>Cédula o pasaporte</label>
                            <Form.Control
                              disabled={itemId ? true : false}
                              placeholder="Cédula o pasaporte"
                              type="text"
                              inputMode="numeric"
                              minLength={9}
                              maxLength={11}
                              value={documentId}
                              required
                              onChange={(e) => setDocumentId(e.target.value)}
                            />
                          </Col>
                          <Col md="12">
                            <label>Nueva contraseña</label>
                            <Form.Control
                              placeholder="contraseña"
                              type="password"
                              onChange={(e) => {
                                setPassword(e.target.value);
                              }}
                              required={!documentId ? true : false}
                              value={password}
                              autoComplete="off"
                              minLength="4"
                            ></Form.Control>
                          </Col>
                          <Col md="12">
                            <label>Repita la contraseña</label>
                            <Form.Control
                              placeholder="Repita la contraseña"
                              type="password"
                              name="repeatPassword"
                              minLength="4"
                              required={!documentId ? true : false}
                              onChange={(e) => {
                                setRepeatPassword(e.target.value);
                              }}
                              value={repeatPassword}
                              autoComplete="off"
                            ></Form.Control>
                          </Col>
                        </Row>
                        <Row>
                          <Col md="12">
                            <Form.Group>
                              <label>Rol</label>
                              <select
                                value={role}
                                onChange={(e) => setRole(e.target.value)}
                                className="form-select"
                                aria-label="Default select example"
                              >
                                {roles.map((type, index) => (
                                  <option key={index} value={type.value}>
                                    {type.label}
                                  </option>
                                ))}
                              </select>
                            </Form.Group>
                          </Col>
                        </Row>
                        <br></br>
                        <Row>
                          <Col md="6">
                            <Row>
                              <Col md="4 d-flex">
                                <p className="category mr-3">Activo</p>
                                <FormCheck
                                  type="switch"
                                  name="status"
                                  checked={status}
                                  onChange={(e) => setStatus(e.target.checked)}
                                  id="custom-switch"
                                  className="mb-1"
                                />
                              </Col>
                            </Row>
                          </Col>
                          <Col md="6">
                            <Row>
                              <Col md="12 d-flex">
                                <p className="category mr-3">
                                  Acceso al portal
                                </p>
                                <FormCheck
                                  type="switch"
                                  name="status"
                                  checked={acceso_portal}
                                  onChange={(e) => setAcceso_portal(e.target.checked)}
                                  id="acceso-switch"
                                  className="mb-1"
                                />
                              </Col>
                            </Row>
                          </Col>
                        </Row>

                        <div className="d-flex justify-content-between">
                          <Button
                            type="button"
                            className={themeClasses.formCancelButton}
                            variant="secundary"
                            onClick={() => handleCloseFormModal()}
                          >
                            Cancelar
                          </Button>
                          {loading ? (
                            <a variant="dark" type="button">
                              <Spinner
                                as="span"
                                animation="grow"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                                className="mr-1"
                              />
                              Guardando...
                            </a>
                          ) : null}
                          <Button
                            className={themeClasses.formSubmitButton}
                            type="submit"
                            variant="primary"
                          >
                            {itemId == null
                              ? "Agregar y continuar"
                              : "Actualizar datos"}
                          </Button>
                        </div>
                        <div className="clearfix"></div>
                      </Form>
                    </Container>
                  </Modal.Body>
                  <Modal.Footer></Modal.Footer>
                </Modal>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}

export default Users;
