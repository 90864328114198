import React, { useState, useEffect } from "react";

// react-bootstrap components
import {
  OverlayTrigger,
  Button,
  Card,
  Form,
  Tooltip,
  Table,
  Container,
  Row,
  Col,
  Modal,
  Spinner,
} from "react-bootstrap";
import { useDispatch } from "react-redux";
import QuestionsAnswersStyles from "./styles/QuestionsAnswersStyles";
import useFetch from "hooks/useFetch";
import { mockupQuestions, mockupPages } from "./QuestionsAnswersConstants";
import Pagination from "components/Pagination/Pagination";
import useSweetAlertConfirm from "hooks/useSweetAlertConfirm";
import Styles from "themes/Styles";
import InputSearcher from "components/InputSearcher/InputSearcher";
import { isMobile } from "react-device-detect";
import ExportToExcel from "utilities/ExportToExcel";
import { useSnackbar } from "notistack";
import AppConfig from "AppConfig";
import Questions from "./Questions";

function QuestionsAnswers() {
  const [alert, setAlert, closeAlert] = useSweetAlertConfirm();
  const classes = QuestionsAnswersStyles();
  const themeClasses = Styles();
  const { enqueueSnackbar } = useSnackbar();

  const dispatch = useDispatch();

  const [loading, setLoading] = useState(true);
  const { fetchConTokenPromesa } = useFetch();

  const [filterText, setFilterText] = useState("");
  const [formModalVisible, setFormModalVisible] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);
  const [listData, setListData] = useState([]);
  const [paginationData, setPaginationData] = useState({});

  const [itemId, setItemId] = useState();
  const [itemTitle, setItemTitle] = useState("");
  const [itemContent, setItemContent] = useState("");

  const [exportData, setExportData] = useState([]);

  const handleSelectItem = (item) => {
    setItemId(item.Id);
    setItemTitle(item.pregunta);
    setItemContent(item.respuesta);
    setFormModalVisible(true);
  };

  const handleCloseFormModal = () => {
    clearForm();
    setFormModalVisible(false);
  };

  const clearForm = () => {
    setItemId(null);
    setItemTitle("");
    setItemContent("");
  };

  const onChangePage = (selectedPage) => {
    setCurrentPage(selectedPage);
    getQuestionsAnswersListData(selectedPage);
  };

  const getExportData = async () => {
    let response = await fetchConTokenPromesa(`fqa?&page=1&limit=999999999999`);
    let data = response?.fqaList;
    let newData = data.map((item) => ({
      ID: item.Id,
      Pregunta: item.pregunta,
      Respuesta: item.respuesta,
    }));
    return newData;
  };

  const getQuestionsAnswersListData = async (page, filter = "") => {
    let response = await fetchConTokenPromesa(
      `fqa?${filter.length > 0 && "&buscar=" + filter}&page=${
        page || currentPage
      }&limit=${AppConfig.TableLimit}`
    );
    setLoading(false);
    setListData(response?.fqaList);
    setPaginationData(response?.pagination);
  };

  const handleDeleteItem = (item) => {
    setAlert(
      `Se eliminará el registro de "${item.pregunta}"`,
      () => deleteItem(item),
      item
    );
  };

  const deleteItem = async (item) => {
    closeAlert();
    setLoading(true);
    await fetchConTokenPromesa(`fqa/${item.Id}`, {}, "DELETE");
    getQuestionsAnswersListData();
    setFilterText("");
    setLoading(false);
  };

  const addNewQuestionAnswer = async (e) => {
    e.preventDefault();
    setLoading(true);
    await fetchConTokenPromesa(
      "fqa",
      {
        pregunta: itemTitle,
        respuesta: itemContent,
      },
      "POST"
    );
    clearForm();
    getQuestionsAnswersListData();
    setFilterText("");
    setLoading(false);
  };
  const updateQuestionAnswer = async (e) => {
    e.preventDefault();
    setLoading(true);
    await fetchConTokenPromesa(
      `fqa/${itemId}`,
      {
        pregunta: itemTitle,
        respuesta: itemContent,
      },
      "PUT"
    );
    handleCloseFormModal();
    getQuestionsAnswersListData();
    setFilterText("");
    setLoading(false);
  };

  const handleFilterList = (e) => {
    getQuestionsAnswersListData(1, e.target.value);
    setFilterText(e.target.value);
    setCurrentPage(1);
  };

  const handleSubmit = (e) => {
    if (!itemId) {
      addNewQuestionAnswer(e);
    } else {
      updateQuestionAnswer(e);
    }
  };

  const handleExportToExcel = async (title) => {
    let exportData = await getExportData();
    if (exportData.length > 0) {
      ExportToExcel(exportData, title);
    } else {
      enqueueSnackbar("No hay informacion para exportar", { variant: "error" });
    }
  };

  useEffect(() => {
    getQuestionsAnswersListData();
  }, []);

  return (
    <Container fluid>
      {alert}
      <Row>
        <Col md="12">
          <Card className="strpied-tabled-with-hover">
            <Card.Header>
              <Card.Title className={themeClasses.cardTitleHeader}>
                <h4 className={themeClasses.cardTitle}>Temas y Preguntas </h4>
                <div className={themeClasses.simplyRowDivider} />
                  <Button
                    size="sm"
                    onClick={() => setFormModalVisible(true)}
                    className={themeClasses.headerButton}
                    style={{maxWidth: '200px'}}
                  >
                    {isMobile ? "➕" : "Agregar"}
                  </Button>
                  
              </Card.Title>
            </Card.Header>
            <Card.Body className="table-responsive p-0">
              <div className={classes.root}>
                {loading ? (
                  <p>Cargando...</p>
                ) : (
                  <Table className="table-hover table-striped w-full">
                    <thead>
                      <tr>
                        <th>Tema</th>
                      </tr>
                    </thead>

                    <tbody>
                      {listData.map((item, index) => (
                        <tr key={index}>
                          <td>{item.pregunta}</td>

                          <td className="td-actions text-right">
                            <OverlayTrigger
                              overlay={
                                <Tooltip id="tooltip-48903503">
                                  Modificar
                                </Tooltip>
                              }
                            >
                              <Button
                                className="btn-link btn-xl"
                                onClick={(e) => handleSelectItem(item)}
                                variant="success"
                              >
                                <i className="fas fa-edit"></i>
                              </Button>
                            </OverlayTrigger>

                            <OverlayTrigger
                              overlay={
                                <Tooltip id="tooltip-255158527">Borrar</Tooltip>
                              }
                            >
                              <Button
                                className="btn-link btn-xl"
                                onClick={(e) => handleDeleteItem(item)}
                                variant="danger"
                              >
                                <i className="fas fa-times"></i>
                              </Button>
                            </OverlayTrigger>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                )}

                <Modal
                  show={formModalVisible}
                  onHide={() => handleCloseFormModal()}
                  size="lg"
                >
                  <Modal.Header closeButton></Modal.Header>
                  <Modal.Body className="p-1">
                    <Container fluid>
                      <Form onSubmit={handleSubmit}>
                        <Row>
                          <Col className="pr-1" md="12">
                            <h3 className="text-center">Tema</h3>
                          </Col>
                        </Row>
                        <Row>
                          <Col md="12">
                            <Form.Group>
                              <label>Pregunta</label>
                              <Form.Control
                                required
                                value={itemTitle}
                                placeholder="Pregunta"
                                onChange={(e) => setItemTitle(e.target.value)}
                              ></Form.Control>
                            </Form.Group>
                          </Col>
                        </Row>
                        <div className="d-flex justify-content-between">
                          <Button
                            type="button"
                            className={themeClasses.formCancelButton}
                            variant="secundary"
                            onClick={() => handleCloseFormModal()}
                          >
                            Cancelar
                          </Button>
                          {loading ? (
                            <a variant="dark" type="button">
                              <Spinner
                                as="span"
                                animation="grow"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                                className="mr-1"
                              />
                              Guardando...
                            </a>
                          ) : null}
                          <Button
                            className={themeClasses.formSubmitButton}
                            type="submit"
                            variant="primary"
                          >
                            {itemId == null
                              ? "Agregar y continuar"
                              : "Actualizar datos"}
                          </Button>
                        </div>
                        <div className="clearfix"></div>
                      </Form>
                    </Container>
                  </Modal.Body>
                  <Modal.Footer></Modal.Footer>
                </Modal>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row>
        <Questions temas={listData} />
      </Row>
    </Container>
  );
}

export default QuestionsAnswers;
