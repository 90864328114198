import React, { useState } from 'react';
import ReactPaginate from 'react-paginate';
import { makeStyles } from '@material-ui/core';
import COLORS from 'themes/Colors';

const Styles = makeStyles({
    activePage:{
        display:'flex',
        backgroundColor:COLORS.primary,
        borderRadius:'20px',
        height:'1.7rem',
        width:'1.7rem',
        fontSize:'1.1rem',
        color:COLORS.white,
        textAlign:'center',  
        justifyContent:'center'
    },
    page:{
        borderRadius:'20px',
        height:'1.6rem',
        width:'2rem',
        fontSize:'1.1rem',
        color:COLORS.black,
        textAlign:'center',  
    },
    pagination:{
        display:'flex',
        flexDirection:'row',
        listStyle:'none',
        outline:'none',
        justifyContent:'center',
        cursor:'pointer',
        alignSelf:'center'
    }
});

function TablePagination({ pages,onSelectPage,currentPage}) {
    const classes = Styles();
    return (
        <ReactPaginate
            previousLabel={'‹'}
            nextLabel={'›'}
            breakLabel={'...'}
            breakClassName={'break-me'}
            pageCount={pages}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            forcePage={(currentPage-1)}
            onPageChange={({selected}) => onSelectPage(selected+1)}
            containerClassName={classes.pagination}
            pageClassName={classes.page}
            activeLinkClassName={classes.activePage}
        />
    )
}

export default TablePagination;