import React, { useState, useEffect } from "react";

// react-bootstrap components
import {
  Card,
  Table,
  Container,
  Row,
  Col,
} from "react-bootstrap";

import moment from "moment";
import useFetch from "hooks/useFetch";
import Styles from "themes/Styles";
import { useSnackbar } from "notistack";
import AsyncSelect from "react-select/async";
import { formatDateToLocalDateString } from "utilities/DateFormat";
import ActivitiesAccessStyles from "views/activitiesAccess/styles/ActivitiesAccessStyles";
function Questions({temas=[]}) {

  const classes = ActivitiesAccessStyles();
  const themeClasses = Styles();

  const [loading, setLoading] = useState(false);
  const { fetchConTokenPromesa } = useFetch();

  const [listData, setListData] = useState([]);
  const [arrayForActivitySelect, setArrayForActivitySelect] = useState(temas.map((tema)=>({value:tema.id, label:tema.pregunta})));

  const getQuestionList = async (tema) => {
    setLoading(true);
    let response = await fetchConTokenPromesa(`fqa/ws?tema=${tema}`);
    setLoading(false);
    setListData(response.result);
  };


useEffect(() => {
  setArrayForActivitySelect(temas.map((tema)=>({value:tema.Id, label:tema.pregunta})))
}, [temas])


  return (
    <Container fluid>
      <Row>
        <Col md="12">
          <Card className="strpied-tabled-with-hover">
            <Card.Header>
              <Card.Title className={themeClasses.cardTitleHeader}>
                <h4 className={themeClasses.cardTitle}> Preguntas por tema </h4>
                <div style={{ width: '77%' }}>
                  <AsyncSelect
                    placeholder="Seleccione el tema"
                    classNamePrefix="react-select"
                    onChange={(e) => {
                      getQuestionList(e.value);
                    }}
                    defaultOptions={arrayForActivitySelect} />
                </div>

              </Card.Title>
            </Card.Header>
            <Card.Body className="table-responsive p-0">
              <div className={classes.root}>
                {loading ?
                  <p>Cargando...</p>
                  :

                  <Table className="table-hover table-striped w-full">
                    <thead>
                      <tr>
                        <th>Contacto</th>
                        <th>Nombre</th>
                        <th>Pregunta</th>
                        <th>Fecha</th>
                      </tr>
                    </thead>

                    <tbody>
                      {listData?.map((item, index) => (
                        <tr key={index}>
                          <td>{item.contacto}</td>
                          <td>{item.nombre}</td>
                          <td>{item.pregunta}</td>
                          <td>{moment(item.created_at).format('DD-MM-YYYY hh:mm:ss a')}</td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                }
                {/*   <div style={{ alignSelf: 'center' }}>
                  <Pagination className={classes.pagination} pages={paginationData?.total_page} currentPage={currentPage} onSelectPage={onChangePage} />
              </div>*/}
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}

export default Questions;
