export const mockupQuestions = [
  {
    id: 1,
    title: "¿Cuando comienza el torneo BMCARGOGOLF?",
    content: "19 de octubre del 2021",
  },
  {
    id: 2,
    title: "¿Como participo en BMCARGOGOLF?",
    content: "Registrandose antes del dia 18 de octubre del 2021",
  },
  {
    id: 3,
    title: "¿Como crear una nueva actividad?",
    content:
      'En el menu de la izquierda haga click en Actividades, luego acceda a Lista de actividades a continuacion haga click en el boton superior derecho con el titulo "Agregar". Para finalizar llene el formulario y haga click en crear nueva actividad. ',
  },
];

export const mockupPages = [
  {
    value: 1,
    isSelected: false,
  },
  {
    value: 2,
    isSelected: true,
  },
  {
    value: 3,
    isSelected: false,
  },
  {
    value: 4,
    isSelected: false,
  },
  {
    value: 5,
    isSelected: false,
  },
];

export const rangoEdades = [
  { value: 1, label: "14 - 19" },
  { value: 2, label: "20 - 29" },
  { value: 3, label: "30 - 39" },
  { value: 4, label: "40 - 49" },
  { value: 5, label: "50 - 59" },
  { value: 6, label: "60 - 69" },
  { value: 7, label: "70+" },
];
