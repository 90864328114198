import { types } from "../types/types";

const initialState = {
  checking: true,
  login: false,
  logged_user: "",
  token: null
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.authLogin:
      return {
        ...state,
        checking: false,
        login:action.payload.login,
        logged_user:action.payload.logged_user,
        token:action.payload.token
      };
    case types.authCkeckingFinish:
      return {
        ...state,
        checking: false,
      };
    case types.authLogout:
      return {
        checking: false,
      };

    case types.authLoginError:
      return {
        checking: false,
        error: action.payload.error,
      };

    default:
      return state;
  }
};

export default authReducer;
