import { makeStyles } from "@material-ui/core";

export default makeStyles({
    headerTitle:{
        textAlign: 'center',
        alignSelf: 'center',
        fontWeight: 'bold',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        display: '-webkit-box',
        WebkitLineClamp: 2,
        WebkitBoxOrient: 'vertical',
        marginTop:'10px'
    },
    headerTitleStyle:{
        fontWeight:'bold',
    }
})