import React, { useState, useEffect } from "react";
import InputMask from "react-input-mask";

// react-bootstrap components
import {
  OverlayTrigger,
  Button,
  Card,
  Form,
  Tooltip,
  Table,
  Container,
  Row,
  Col,
  Modal,
  Spinner,
  Image,
  FormCheck,
} from "react-bootstrap";
import Select from "react-select";
import pagosStyles from "./styles/PagosStyles";
import useFetch from "hooks/useFetch";
import Pagination from "components/Pagination/Pagination";
import useSweetAlertConfirm from "hooks/useSweetAlertConfirm";
import Styles from "themes/Styles";
import { isMobile } from "react-device-detect";
import AsyncSelect from "react-select/async";
import InputSearcher from "components/InputSearcher/InputSearcher";
import ExportToExcel from "utilities/ExportToExcel";
import { useSnackbar } from "notistack";
import { ErrorList } from "./PagosConstants";
import AppConfig from "AppConfig";
import ListSelect from "components/selects/ListSelect";
import { tipo_pagos } from "consts/APPCONSTANTS";
import moment from "moment";

function Pagos() {
  const [alert, setAlert, closeAlert] = useSweetAlertConfirm();
  const classes = pagosStyles();
  const themeClasses = Styles();
  const { enqueueSnackbar } = useSnackbar();

  const [loading, setLoading] = useState(true);
  const { fetchConTokenPromesa, fetchConTokenWithFile } = useFetch();

  const [formModalVisible, setFormModalVisible] = useState(false);

  const [filterText, setFilterText] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [listData, setListData] = useState([]);
  const [listPerson, setListPerson] = useState([]);
  const [paginationData, setPaginationData] = useState({});

  const [itemId, setItemId] = useState();
  const [wsPagante, setWsPagante] = useState("");
  const [nombre, setNombre] = useState("");
  const [activity, setActivity] = useState("");
  const [tipo, setTipo] = useState(1);
  const [file, setFile] = useState();
  const [costo, setCosto] = useState(0);
  const [confirmado, setConfirmado] = useState(false);
  const [cantidadEntradas, setCantidadEntradas] = useState(0);
  const [sendQR, setSendQR] = useState(0);

  const handleSelectItem = (item) => {
    setNombre(item.nombre_pagante);
    setCosto(item.total / item.cantidad_entradas);
    setTipo(item.tipo_pago);
    setConfirmado(item.confirmado == 1 ? true : false);
    setListPerson([]);
    setWsPagante(item.ws_pagante.substring(1,20));
    setFormModalVisible(true);

    setActivity({ value: item.id_actividad, label: item.evento, costo });
    setListPerson(
      item.listEntradas.map((i) => ({
        id: i.id_persona,
        name: i.id_persona + " - " + i.persona,
      }))
    );

    setItemId(item.id);
    setCantidadEntradas(item.cantidad_entradas);
  };

  const handleCloseFormModal = () => {
    clearForm();
    setFormModalVisible(false);
  };

  const clearForm = () => {
    setNombre("");
    setCosto(0);
    setTipo(1);
    setConfirmado(false);
    setListPerson([]);
    setWsPagante("");
    setFormModalVisible(true);
    setItemId(null);
    setSendQR(0);
    setActivity(null);
    setListPerson([]);
    setFile(null);
  };

  const onChangePage = (selectedPage) => {
    setCurrentPage(selectedPage);
    getPagosListData(selectedPage);
  };

  const getExportData = async () => {
    let response = await fetchConTokenPromesa(`pago?page=1&limit=999999999999`);
    let data = response?.pagoList;
    let newData = data.map((item) => ({
      ID: item.id,
      Nombre: item.nombre,
      Resumen: item.resumen,
      Detalle: item.detalle,
      Longitud: item.longitud,
      Latitud: item.latitud,
    }));
    return newData;
  };

  const getPagosListData = async (page, filter = "") => {
    let response = await fetchConTokenPromesa(
      `pago?${filter.length > 0 && "&buscar=" + filter}&page=${
        page || currentPage
      }&limit=${AppConfig.TableLimit}`
    );
    setLoading(false);
    setListData(response?.pagoList);
    setPaginationData(response?.pagination);
  };

  const handleDeleteItem = (item) => {
    setAlert(
      `Se eliminará el pago con id "${item.id}"`,
      () => deleteItem(item),
      item
    );
  };

  const deleteItem = async (item) => {
    closeAlert();
    setLoading(true);
    await fetchConTokenPromesa(`pago/${item.id}`, {}, "DELETE", ErrorList);
    getPagosListData();
    setFilterText("");
    setLoading(false);
  };

  const addNewPagos = async (e) => {
    e.preventDefault();
    setLoading(true);

    let celular = wsPagante.replace('+','');
    if (wsPagante.substring(1,1) != 1 && wsPagante.length == 10) {
      celular = 1+wsPagante.replace('+','');
    }

    await fetchConTokenWithFile(
      "pago",
      {
        id_actividad: activity.value,
        total: costo * cantidadEntradas,
        ws_pagante: celular,
        nombre_pagante: nombre,
        image: file?.files[0],
        tipo_pago: tipo,
        confirmado: confirmado,
        entradas: listPerson,
        cantidad_entradas: cantidadEntradas,
        sendQR
      },
      ErrorList
    );

    clearForm();
    getPagosListData();
    setFilterText("");
    setLoading(false);
  };

  const updatePagos = async (e) => {
    e.preventDefault();
    setLoading(true);

    let celular = wsPagante.replace('+','');
    if (wsPagante.substring(1,1) != 1 && wsPagante.length == 10) {
      celular = 1+wsPagante.replace('+','');
    }

    await fetchConTokenWithFile(
      `pago/${itemId}`,
      {
        id_actividad: activity.value,
        total: costo * cantidadEntradas,
        ws_pagante: celular,
        nombre_pagante: nombre,
        image: file?.files[0],
        tipo_pago: tipo,
        confirmado: confirmado,
        entradas: listPerson,
        cantidad_entradas: cantidadEntradas,
        sendQR
      },
      "PUT",
      ErrorList
    );
    handleCloseFormModal();
    getPagosListData();
    setFilterText("");
    setLoading(false);
  };

  const handleFilterList = (e) => {
    getPagosListData(1, e.target.value);
    setFilterText(e.target.value);
    setCurrentPage(1);
  };

  const handleOnSubmit = (e) => {
    if (!itemId) {
      addNewPagos(e);
    } else {
      updatePagos(e);
    }
  };

  const handleExportToExcel = async (title) => {
    let exportData = await getExportData();
    if (exportData.length > 0) {
      ExportToExcel(exportData, title);
    } else {
      enqueueSnackbar("No hay informacion para exportar", { variant: "error" });
    }
  };

  useEffect(() => {
    getPagosListData();
  }, []);

  const getActivitiesDataForSelect = async (inputValue) => {
    const response = await fetchConTokenPromesa(
      "activity?page=1&limit=100&buscar=" + inputValue
    );
    if (response?.activityList) {
      return response.activityList.map((r) => ({
        value: r.id,
        label: r.resumen,
        costo: r.costo,
      }));
    } else {
      return [];
    }
  };

  const handleSelectActivity = (e) => {
    setCosto(e.costo);
    setActivity(e);
  };

  const formatNumber = Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "usd",
    signDisplay: "never",
  });

  useEffect(() => {
    !itemId && setCantidadEntradas(listPerson.length);
  }, [listPerson]);

  return (
    <Container fluid>
      {alert}
      <Row>
        <Col md="12">
          <Card className="strpied-tabled-with-hover">
            <Card.Header>
              <Card.Title className={themeClasses.cardTitleHeader}>
                <h4 className={themeClasses.cardTitle}> Pagos </h4>
                <div className={themeClasses.simplyRowDivider} />
                <div className={themeClasses.secondaryHeaderSearcherContainer}>
                  <InputSearcher
                    value={filterText}
                    onChange={handleFilterList}
                    placeholder="Buscar por evento o pagante"
                  />
                  <div className={themeClasses.mediumRowDivider} />
                  <Button
                    size="sm"
                    onClick={() => setFormModalVisible(true)}
                    className={themeClasses.headerButton}
                  >
                    {isMobile ? "➕" : "Agregar"}
                  </Button>
                  <div className={themeClasses.mediumRowDivider} />
                  {/* <OverlayTrigger overlay={<Tooltip>Exportar a excel</Tooltip>}>
                    <Button
                      className={themeClasses.exportButton}
                      onClick={(e) => {
                        handleExportToExcel("Pagos");
                      }}
                      variant="success"
                    >
                      <i class="fas fa-file-excel"></i>
                    </Button>
                  </OverlayTrigger> */}
                </div>
              </Card.Title>
            </Card.Header>
            <Card.Body className="table-responsive p-0">
              <div className={classes.root}>
                {loading ? (
                  <p>Cargando...</p>
                ) : (
                  <Table className="table-hover table-striped w-full">
                    <thead>
                      <tr>
                        <th>id</th>
                        <th>Evento</th>
                        <th>Entradas</th>
                        <th>Monto</th>
                        <th>Fecha</th>
                        <th>Pagante</th>
                        <th>Confirmado</th>
                        <th>Comprobante</th>
                        <th>Vendedor</th>
                      </tr>
                    </thead>

                    <tbody>
                      {listData?.map((item, index) => (
                        <tr key={index}>
                          <td>{item?.id}</td>
                          <td>
                            {item?.evento} {`(${item.id_actividad})`}
                          </td>
                          <td>{item?.cantidad_entradas}</td>
                          <td>RD{formatNumber.format(item?.total)}</td>
                          <td>
                            {moment(item?.created_at).format(
                              "DD-MM-YYYY h:m:s A"
                            )}
                          </td>
                          <td>{item?.nombre_pagante}</td>
                          <td>{item?.confirmado ? "Si" : "No"}</td>
                          <td>
                            {item.ws_pagante}
                            {/* {item.comprobante && (
                              <>
                                <a href={item.comprobante} target={"_blank"}>
                                  <Image
                                    src={item?.comprobante}
                                    style={{
                                      padding: "0.5%",
                                      display: "flex",
                                      width: "40px",
                                    }}
                                  />
                                </a>
                              </>
                            )} */}
                          </td>
                          <td>{item?.vendedor_name}</td>
                          <td className="td-actions text-right">
                            <OverlayTrigger
                              overlay={
                                <Tooltip id="tooltip-48903503">
                                  Modificar
                                </Tooltip>
                              }
                            >
                              <Button
                                className="btn-link btn-xl"
                                onClick={(e) => handleSelectItem(item)}
                                variant="success"
                              >
                                <i className="fas fa-edit"></i>
                              </Button>
                            </OverlayTrigger>

                            <OverlayTrigger
                              overlay={
                                <Tooltip id="tooltip-255158527">Borrar</Tooltip>
                              }
                            >
                              <Button
                                className="btn-link btn-xl"
                                onClick={(e) => handleDeleteItem(item)}
                                variant="danger"
                              >
                                <i className="fas fa-times"></i>
                              </Button>
                            </OverlayTrigger>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                )}
                <div style={{ alignSelf: "center" }}>
                  <Pagination
                    className={classes.pagination}
                    pages={paginationData?.total_page}
                    currentPage={currentPage}
                    onSelectPage={onChangePage}
                  />
                </div>

                <Modal
                  show={formModalVisible}
                  onHide={() => handleCloseFormModal()}
                  size="lg"
                >
                  <Modal.Header closeButton></Modal.Header>
                  <Modal.Body>
                    <Container fluid>
                      <Form onSubmit={handleOnSubmit}>
                        <Row>
                          <Col className="pr-1" md="12">
                            <h3 className="text-center mt-0">Pago</h3>
                          </Col>
                        </Row>
                        <Row>
                          <Col className="pl-1" md="8">
                            <Form.Group>
                              <label htmlFor="email">Evento</label>
                              <AsyncSelect
                                className="react-select info"
                                placeholder="Escribe para buscar"
                                classNamePrefix="react-select"
                                onChange={handleSelectActivity}
                                cacheOptions
                                defaultOptions
                                value={activity}
                                loadOptions={getActivitiesDataForSelect}
                              />
                            </Form.Group>
                          </Col>
                          <Col className="pr-1" md="4">
                            <Form.Group>
                              <label>Costo</label>
                              <Form.Control
                                placeholder="Costo"
                                type="text"
                                value={`RD${formatNumber.format(costo)}`}
                                readOnly
                                required
                                onChange={(e) => setCosto(e.target.value)}
                              ></Form.Control>
                            </Form.Group>
                          </Col>
                        </Row>
                        <Row>
                          <ListSelect
                            title="Entradas"
                            list={listPerson}
                            setList={setListPerson}
                            endpoint={"person?id_tipo=2"}
                            limit={itemId ? cantidadEntradas : 100}
                          />
                        </Row>
                        <Row>
                          <Col className="pr-1" md="4">
                            <Form.Group>
                              <label>Whatsapp pagante</label>
                              <InputMask
                                // mask="999-999-9999"
                                className="form-control"
                                placeholder="Whatsapp"
                                value={wsPagante}
                                onChange={(e) => setWsPagante(e.target.value)}
                              />
                            </Form.Group>
                          </Col>
                          <Col className="pr-1" md="8">
                            <Form.Group>
                              <label>Nombre</label>
                              <Form.Control
                                placeholder="Nombre"
                                type="text"
                                value={nombre}
                                required
                                onChange={(e) => setNombre(e.target.value)}
                              ></Form.Control>
                            </Form.Group>
                          </Col>
                        </Row>
                        <Row>
                          <Col className="pr-1" md="6">
                            <Form.Group>
                              <label>Tipo pago</label>
                              <Select
                                className="react-select info"
                                classNamePrefix="react-select"
                                placeholder="Tipo de persona"
                                onChange={(e) => setTipo(e.value)}
                                value={[...tipo_pagos].filter(
                                  (t) => t.value == tipo
                                )}
                                required
                                options={tipo_pagos}
                              />
                            </Form.Group>
                          </Col>

                          <Col md="6">
                            <Form.Group>
                              <label>Comprobante</label>
                              <input
                                type="file"
                                className="form-control"
                                onChange={(e) => setFile(e.target)}
                              />
                            </Form.Group>
                          </Col>
                          <Row>
                            <Col md="12">
                              <Row>
                                <Col md="3 d-flex flex-column">
                                  <p className="category mr-3 d-block">
                                    Confirmado
                                  </p>
                                  <FormCheck
                                    type="switch"
                                    name="confirmado"
                                    checked={confirmado}
                                    onChange={(e) =>
                                      setConfirmado(e.target.checked)
                                    }
                                    id="custom-switch"
                                    className="mb-1"
                                  />
                                </Col>
                                <Col md="3 d-flex flex-column">
                                  <p className="category mr-3 d-block">
                                    Enviar QR
                                  </p>
                                  <FormCheck
                                    type="switch"
                                    name="confirmado"
                                    checked={sendQR}
                                    onChange={(e) =>
                                      setSendQR(e.target.checked)
                                    }
                                    id="sendQR-switch"
                                    className="mb-1"
                                  />
                                </Col>
                                <Col className="pr-1" md="3">
                                  <Form.Group>
                                    <label>Entradas</label>
                                    <Form.Control
                                      placeholder="Nombre"
                                      type="text"
                                      value={cantidadEntradas}
                                      disabled={itemId ? true : false}
                                      required
                                      onChange={(e) =>
                                        setCantidadEntradas(e.target.value)
                                      }
                                    ></Form.Control>
                                  </Form.Group>
                                </Col>
                                <Col
                                  className="d-flex justify-content-end"
                                  md="3"
                                >
                                  <Form.Group>
                                    <label>Monto total:</label>
                                    <p className="font-weight-bold">
                                      {" "}
                                      RD
                                      {formatNumber.format(
                                        costo * cantidadEntradas
                                      )}
                                    </p>
                                  </Form.Group>
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                        </Row>

                        <div className="d-flex justify-content-between">
                          <Button
                            type="button"
                            className={themeClasses.formCancelButton}
                            variant="secundary"
                            onClick={() => handleCloseFormModal()}
                          >
                            Cancelar
                          </Button>
                          {loading ? (
                            <a variant="dark" type="button">
                              <Spinner
                                as="span"
                                animation="grow"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                                className="mr-1"
                              />
                              Guardando...
                            </a>
                          ) : null}
                          <Button
                            className={themeClasses.formSubmitButton}
                            type="submit"
                            variant="primary"
                          >
                            {itemId == null
                              ? "Agregar y continuar"
                              : "Actualizar datos"}
                          </Button>
                        </div>
                        <div className="clearfix"></div>
                      </Form>
                    </Container>
                  </Modal.Body>
                  <Modal.Footer></Modal.Footer>
                </Modal>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}

export default Pagos;
