import { types } from "../types/types";

export const getPersonList = (personList) => ({
  type: types.getPersonList,
  payload: personList,
});

export const cleanPersonList = () => ({
  type: types.cleanPersonList,
});

export const deletePersonAction = (personId) => ({
  type: types.deletePerson,
  payload: personId,
});

export const updatePersonAction = (person) => ({
  type: types.updatePerson,
  payload: person,
});

export const addPerson = (person) => ({
  type: types.addPerson,
  payload: person,
});

export const selectPerson = (personId) => ({
  type: types.selectPerson,
  payload: personId,
});

export const unSelectPerson = () => ({
  type: types.unSelectPerson,
});

export const showPersonForm = (tipo_persona = false) => ({
  type: types.showPersonForm,
  payload: tipo_persona,
});
