import React, { useEffect } from "react";
import { Redirect } from "react-router";
import { Switch, Route } from "react-router-dom";
// react-bootstrap components

// core components
import Sidebar from "components/Sidebar/Sidebar.js";
import AdminNavbar from "components/Navbars/AdminNavbar.js";
import AdminFooter from "components/Footers/AdminFooter.js";
import FixedPlugin from "components/FixedPlugin/FixedPlugin.js";

// dinamically create dashboard routes
import routes from "routes.js";

import image1 from "assets/img/full-screen-image-1.jpg";
import image2 from "assets/img/full-screen-image-2.jpg";
import image3 from "assets/img/full-screen-image-3.jpg";
import image4 from "assets/img/full-screen-image-4.jpg";
import { useDispatch, useSelector } from "react-redux";
import { getRolList } from "actions/rolActions";
import useFetch from "hooks/useFetch";
import { useSnackbar } from "notistack";
import {  logout } from "actions/authActions";

function Admin() {
  const [sidebarImage, setSidebarImage] = React.useState(image2);
  const [sidebarBackground, setSidebarBackground] = React.useState("orange");
  const { enqueueSnackbar } = useSnackbar();
  const {  RefreshToken } = useFetch();

  const roles = [
    { id_tipo: 1, descripcion: "Jugador" },
    { id_tipo: 2, descripcion: "Invitado" },
    { id_tipo: 3, descripcion: "Staff" },
    { id_tipo: 4, descripcion: "Admin torneo" },
    { id_tipo: 5, descripcion: "Admin técnico" },
  ];

  const dispatch = useDispatch();
  dispatch(getRolList(roles));
  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return getRoutes(prop.views);
      }

      if (prop.layout === "/admin") {
        return (
          <Route
            path={prop.layout + prop.path}
            key={key}
            component={prop.component}
          />
        );
      } else {
        return null;
      }
    });
  };
  
  //RefreshToken every 55 minutes (3300000 miliseconds);
  useEffect(() => {
    let Interval;
    (async () => {
      Interval = setInterval(async () => {
        let isRefreshedToken = await RefreshToken();
        if (await isRefreshedToken) {
            //DO SOMETHING
        }
        else {
          dispatch(logout());
          enqueueSnackbar("Ha ocurrido un error inicie sesion nuevamente.", { variant: "error" })
        }
      },3300000);
    })();
    return () => clearInterval(Interval);
  }, []);

  return (
    <>
      <div className="wrapper">
        <Sidebar
          routes={routes.filter(route => route.layout == '/admin')}
          image={sidebarImage}
          background={sidebarBackground}
        />
        <div className="main-panel">
          <AdminNavbar />
          <div className="content">
            <Switch>{getRoutes(routes)}</Switch>
          </div>
          <AdminFooter />
          <div
            className="close-layer"
            onClick={() =>
              document.documentElement.classList.toggle("nav-open")
            }
          />
        </div>
      </div>
      <FixedPlugin
        setSidebarImageParent={(value) => setSidebarImage(value)}
        sidebarDefaultImage={sidebarImage}
        sidebarImages={[image1, image2, image3, image4]}
        backgroundColors={[
          "black",
          "azure",
          "green",
          "orange",
          "red",
          "purple",
        ]}
        backgroundColor={sidebarBackground}
        setSidebarBackgroundParent={(value) => setSidebarBackground(value)}
      />
    </>
  );
}

export default Admin;
