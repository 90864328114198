export const colors = [
    '#23CCEF',
    '#FB404B',
    '#FFA534',
    'rgba(54, 162, 235, 1)',
    'rgba(255, 206, 86, 1)',
    'rgba(75, 192, 192, 1)',
    'rgba(153, 102, 255, 1)',
    'rgba(255, 159, 64, 1)',
    "#F74022",
    '#FF00CC',
    '#0099FF',
    '#33CC66',
    '#FF0066',
    '#FFFF00'
  ]