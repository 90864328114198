import React, { useState, useEffect } from "react";

// react-bootstrap components
import { Button, Form, Row, Col } from "react-bootstrap";
import useFetch from "hooks/useFetch";
import { useSnackbar } from "notistack";
import List from "./List";
import AsyncSelect from "react-select/async";
import { Divider } from "@material-ui/core";
import ListSelectStyle from "./styles/ListSelectStyle";

function ListSelect({
  list = [],
  setList,
  endpoint = "",
  title = "Selector de lista",
  limit=100
}) {
  const classes = ListSelectStyle();
  const { enqueueSnackbar } = useSnackbar();

  const { fetchConTokenPromesa } = useFetch();
  const [currentSelected, setCurrentSelected] = useState();

  const [currentIndexOfSelected, setCurrentIndexOfSelected] = useState();

  const handleSelectItemFromList = (item, index) => {
    setCurrentIndexOfSelected(index);
    setCurrentSelected({
      value: item.id,
      label: item.name,
    });
  };

  const addToList = () => {
    if (currentSelected == null) {
      enqueueSnackbar("Debes seleccionar primero", { variant: "error" });
      return;
    }
    if (list.filter((item) => item.id == currentSelected.value).length > 0) {
      enqueueSnackbar("Ya se encuentra en la lista", {
        variant: "error",
      });
      return;
    }
    
    if (list.length >= limit) {
      enqueueSnackbar(`Este pago solo aplica para ${limit} entradas`, {
        variant: "error",
      });
      return;
    }
    setList((prev) => [
      ...prev,
      {
        id: currentSelected.value,
        name: currentSelected.label,
      },
    ]);
    clearSelectedItemFromList();
  };

  const clearSelectedItemFromList = () => {
    setCurrentIndexOfSelected(null);
    setCurrentSelected(null);
  };

  const removeItemFromList = () => {
    if (currentIndexOfSelected == null) {
      enqueueSnackbar("No hay ningun artículo seleccionado", {
        variant: "error",
      });
      return;
    }
    let newList = [];
    for (let i = 0; i < list.length; i++) {
      if (list[i] == list[currentIndexOfSelected]) {
      } else {
        newList.push(list[i]);
      }
    }
    setList(newList);
    clearSelectedItemFromList();
  };

  const getDataForSelect = async (inputValue) => {
    const response = await fetchConTokenPromesa(
      `${endpoint}&page=1&limit=100&buscar=` + inputValue
    );

    if (response?.userList) {
      const items = response.userList.map((r) => ({
        value: r.id,
        label: r.id + " - " + r.firstname + " " + r.lastname,
      }));
      return items;
    } else if (response?.personList) {
      const items = response.personList.map((r) => ({
        value: r.id,
        label: r.id + " - " + r.firstname + " " + r.lastname,
      }));
      return items;
    } else {
      return [];
    }
  };

  return (
    <>
      <Divider className="my-2" />
      <Row>
        <Col>
          <label className="font-weight-bold">{title}:</label>
          <List
            ListItems={list}
            onSelected={handleSelectItemFromList}
          />
        </Col>
      </Row>
      <Col className="pl-1" md="12">
        <Form.Group>
          <AsyncSelect
            className="react-select info"
            classNamePrefix="react-select"
            placeholder="Seleccionar"
            onChange={(e) => setCurrentSelected(e)}
            value={currentSelected}
            cacheOptions
            defaultOptions
            loadOptions={getDataForSelect}
          />
        </Form.Group>
      </Col>
      <Row>
        <Col className="pr-1" sm="6">
          <Button
            className={classes.addButton}
            onClick={() => addToList()}
            variant="primary"
          >
            Agregar
          </Button>
        </Col>
        <Col className="pr-0" style={{  textAlign: "right" }} sm="6">
          <Button
            className={classes.deleteButton}
            onClick={() => removeItemFromList()}
            variant="primary"
          >
            Eliminar
          </Button>
        </Col>
      </Row>
      <Divider className="my-2" />
    </>
  );
}

export default ListSelect;
