import React, { useState, useEffect } from "react";

// react-bootstrap components
import {
  Card,
  Table,
  Container,
  Row,
  Col,
} from "react-bootstrap";

import entradasActivityStyles from "./styles/entradasActivityStyles";
import useFetch from "hooks/useFetch";
import Styles from "themes/Styles";
import AsyncSelect from "react-select/async";
function entradasActivity() {

  const classes = entradasActivityStyles();
  const themeClasses = Styles();

  const [loading, setLoading] = useState(false);
  const { fetchConTokenPromesa } = useFetch();

  const [listData, setListData] = useState([]);
  const [personName, setPersonName] = useState('');
  const [selectedActivity, setSelectedActivity] = useState({value: "all",label: "Seleccione un evento"});


  const getentradasActivityListData = async (activityID, query='') => {
    setLoading(true);
    let response = await fetchConTokenPromesa(`activity/entradasList/${activityID}?query=${query}`);
    setLoading(false);
    setListData(response?.personList);
  };

  const getActivityForSelect = async (inputValue = "") => {
    let response = await fetchConTokenPromesa(`activity?&buscar=${inputValue}&page=1&limit=50`);
    let arrayForActivitySelect = [
      {
        value: "all",
        label: "Seleccione un evento",
      }
    ]
    let responseArray = response?.activityList.map((item) => ({
      value: item.id,
      label: item.resumen,
    }));
    return arrayForActivitySelect.concat(responseArray);
  }

  const searchPerson = (e)=>{
    setPersonName(e.target.value);
    getentradasActivityListData(selectedActivity.value,e.target.value);
  }

  return (
    <Container fluid>
      <Row>
        <Col md="12">
          <Card className="strpied-tabled-with-hover">
            <Card.Header>
              <Card.Title className={themeClasses.cardTitleHeader}>
                <h4 className={themeClasses.cardTitle}> Lista de entradas</h4>
                <div style={{ width: '77%' }}>
                  <Row>
                    <Col md='6'>
                    <AsyncSelect
                    placeholder="Buscar evento"
                    classNamePrefix="react-select"
                    onChange={(e) => {
                      getentradasActivityListData(e.value);
                      setSelectedActivity(e);
                    }}
                    value={selectedActivity}
                    cacheOptions
                    defaultOptions
                    loadOptions={getActivityForSelect} />
                    </Col>
                    <Col md='6'>
                      <input value={personName} onChange={(e)=>searchPerson(e)} placeholder="Buscar invitado" className="form-control" type="text"/>
                    </Col>
                  </Row>
                 
                   
                </div>

              </Card.Title>
            </Card.Header>
            <Card.Body className="table-responsive p-0">
              <div className={classes.root}>
                {loading ?
                  <p>Cargando...</p>
                  :

                  <Table className="table-hover table-striped w-full">
                    <thead>
                      <tr>
                        <th>Whatsapp</th>
                        <th>Invitado</th>
                        <th>ID Pago</th>
                        <th>Confirmado</th>
                      </tr>
                    </thead>

                    <tbody>
                      {listData?.map((item, index) => (
                        <tr key={index}>
                          <td>{item.celphone}</td>
                          <td>{item.persona}</td>
                          <td>{item.id_pago}</td>
                          <td>{item?.confirmado ? "Si" : "No"}</td>
                          {/* <td>{formatDateToLocalDateString(item.fecha_hora)}</td> */}
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                }
                {/*   <div style={{ alignSelf: 'center' }}>
                  <Pagination className={classes.pagination} pages={paginationData?.total_page} currentPage={currentPage} onSelectPage={onChangePage} />
              </div>*/}
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}

export default entradasActivity;
