import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import { colors } from "./Colors";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export default function LineChart({ dataDb = [] }) {
  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "bottom",
      },
    },
    scales: {
      yAxis: {
        min: 0,
      },
    },
    tension: 0.5,
    
  };

  const meses = [
    { month: "Enero", id: 1 },
    { month: "Febrero", id: 2 },
    { month: "Marzo", id: 3 },
    { month: "Abrir", id: 4 },
    { month: "Mayo", id: 5 },
    { month: "Junio", id: 6 },
    { month: "Julio", id: 7 },
    { month: "Agosto", id: 8 },
    { month: "Septiembre", id: 9 },
    { month: "Octubre", id: 10 },
    { month: "Noviembre", id: 11 },
    { month: "Diciembre", id: 12 },
  ];

  meses.length = new Date().getMonth()+1;

  const data = {
    labels: meses.map((d) => d.month),
    datasets: dataDb?.map((datos, index) => ({
      label: datos.evento,
      data: meses.map(date =>{
        const reducer = (accumulator, curr) => {
          console.log(accumulator)
          return {venta:Number(accumulator.venta) + Number(curr.venta)};
        };

        if (datos.sales.filter(d=>d.month == date.id).length > 1) {
          return datos.sales.filter(d=>d.month == date.id)[0]?.venta ? datos.sales.filter(d=>d.month == date.id).reduce(reducer).venta : 0;
        }else{
          return datos.sales.filter(d=>d.month == date.id)[0]?.venta ? datos.sales.filter(d=>d.month == date.id)[0]?.venta : 0;
        }
        
      }),
      borderColor: colors[index],
      backgroundColor: colors[index],
    })),
  };

  return <Line width={"1000%"} height={"300%"} options={options} data={data} />;
}
