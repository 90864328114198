import { types } from "../types/types";

const initialState = {
  foursomeList: [],
  showFoursomeForm: false,
  seletedFoursome: null,
};

const foursomeReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.getFoursomeList:
      return {
        ...state,
        foursomeList: action.payload,
      };
    case types.cleanFoursomeList:
      return {
        ...state,
        foursomeList: [],
      };
    case types.deleteFoursome:
      return {
        ...state,
        foursomeList: state.foursomeList.filter((a) => a.id !== action.payload),
      };
    case types.addFoursome:
      return {
        ...state,
        foursomeList: [action.payload, ...state.foursomeList],
      };

    case types.updateFoursome:
      return {
        ...state,
        foursomeList: state.foursomeList.map((e) =>
          e.id === action.payload.id ? action.payload : e
        ),
      };

    case types.showFoursomeForm:
      return {
        ...state,
        showFoursomeForm: !state.showFoursomeForm,
      };

    case types.selectFoursome:
      return {
        ...state,
        seletedFoursome: action.payload,
      };

    case types.unSelectFoursome:
      return {
        ...state,
        seletedFoursome: null,
      };

    default:
      return state;
  }
};

export default foursomeReducer;
