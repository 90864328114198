import { fetchSinToken, fetchConToken } from "../helpers/fetch";
import { types } from "../types/types";

export const startLogin = (payload) => {
  return (dispatch) => {
      dispatch(login({
        login: true,
        logged_user: payload?.logged_user,
        token: payload.token
      }));
  };
};

export const startRegister = (email, password, name) => {
  return async (dispatch) => {
    const resp = await fetchSinToken(
      "auth/new",
      { name, email, password },
      "POST"
    );
    const body = await resp.json();
    if (body.ok) {
      localStorage.setItem("token", body.token);
      localStorage.setItem("token-init-date", new Date().getTime());
      dispatch(login({ uid: body.uid, name: body.name }));
    } else {
      
    }
  };
};

export const updateToken = (data) => {
  return (dispatch) => {
    dispatch(login({
      login: true,
      logged_user: data.logged_user,
      token: data.token
    }));

  };
};

const login = (user) => ({
  type: types.authLogin,
  payload: user,
});

export const logout = () => ({
  type: types.authLogout
});
