import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { colors } from './Colors';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);


export function SaleChart({dataDb}) {

    const options = {
        fill: true,
        responsive: true,
        plugins: {
          legend: {
            display: false,
            position:'bottom'
          },
          Tooltip
        },
      };
    
    const labels = dataDb?.map(d=>d.name);
    
    const data = {
      labels,
      datasets: [
        {
          label: [`Venta`],
          data: dataDb?.map(d=>d.monto),
          backgroundColor: colors,
        }
      ],
    };

    
  return <Bar  width={300} height={300} style={{width:'100%', maxHeight:'373px ', margin:'auto'}} options={options} data={data} />;
}
