import React, { useState, useEffect } from "react";

// react-bootstrap components
import {
  OverlayTrigger,
  Button,
  Card,
  Form,
  Tooltip,
  Table,
  Container,
  Row,
  Col,
  Modal,
  Spinner,
  FormCheck,
} from "react-bootstrap";
import GuestsStyles from "./styles/GuestsStyles";
import useFetch from "hooks/useFetch";
import Pagination from "components/Pagination/Pagination";
import useSweetAlertConfirm from "hooks/useSweetAlertConfirm";
import Styles from "themes/Styles";
import { isMobile } from "react-device-detect";
import InputSearcher from "components/InputSearcher/InputSearcher";
import ReactDatetime from "react-datetime";
import moment from "moment";
import { useSnackbar } from "notistack";
import AsyncSelect from "react-select/async";
import InputMask from "react-input-mask";
import { CountryDropdown } from "react-country-region-selector";
import ExportToExcel from "utilities/ExportToExcel";
import { documentTypes, invitedByTypes } from "../../consts/APPCONSTANTS";
import AppConfig from "AppConfig";
import { ErrorList } from "views/Location/LocationConstants";
import { rangoEdades } from "./GuestsConstants";

function Guests() {
  const [alert, setAlert, closeAlert] = useSweetAlertConfirm();
  const classes = GuestsStyles();
  const themeClasses = Styles();
  const { enqueueSnackbar } = useSnackbar();

  const [loading, setLoading] = useState(false);
  const { fetchConTokenPromesa, fetchConTokenWithFile } = useFetch();

  const [formModalVisible, setFormModalVisible] = useState(false);

  const [filterText, setFilterText] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [listData, setListData] = useState([]);
  const [paginationData, setPaginationData] = useState({});
  const [anUserIsSelected, setAnUserIsSelected] = useState(false);

  const [country, setCountry] = useState("Dominican Republic");
  const [cedula_passport, setCedula_passport] = useState("");
  const [documentType, setDocumentType] = useState(1);
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [sex, setSex] = useState(2);
  const [birthday, setBirthday] = useState(null);
  const [email, setEmail] = useState("");
  const [celphone, setCelphone] = useState("");
  const [direccion, setDireccion] = useState("");
  const [comentario, setComentario] = useState("");

  const [idSelected, setIdSelected] = useState("");
  const [file, setFile] = useState();
  const [costo, setCosto] = useState(500);
  const [activity, setActivity] = useState({costo:500,label:"Conferencia internacional de mujeres",value:66});
  const [confirmado, setConfirmado] = useState(true);
  const [rangoEdad, setRangoEdad] = useState(0);

  const handleSelectItem = async (item) => {
    setIdSelected(item.id);
    setAnUserIsSelected(true);
    setCountry(item.pais);
    setDocumentType(item.tipo_documento);
    setCedula_passport(item.cedula_passport);
    setFirstname(item.firstname);
    setSex(item.sexo);
    setEmail(item.email);
    setCelphone(item.celphone.substring(1,20));
    setDireccion(item.direccion);
    setComentario(item.comentario);
    setBirthday(item.birthday);
    setLastname(item.lastname);
    setFormModalVisible(true);
    setRangoEdad(item.rango_edad);
  };

  const clearForm = () => {
    setIdSelected("");
    setAnUserIsSelected(false);
    setCountry("Dominican Republic");
    setDocumentType(1);
    setCedula_passport("");
    setFirstname("");
    setSex(1);
    setEmail("");
    setCelphone("");
    setDireccion("");
    setComentario("");
    setBirthday(null);
    setLastname("");
    setConfirmado(true);
    setRangoEdad(0);
  };

  const handleCloseFormModal = () => {
    clearForm();
    setFormModalVisible(false);
  };

  const onChangePage = (selectedPage) => {
    setCurrentPage(selectedPage);
    getGuestsListData(selectedPage);
  };

  const getExportData = async () => {
    let response = await fetchConTokenPromesa(
      `person?id_tipo=2&page=1&limit=999999999999`
    );
    let data = response?.personList;
    let newData = data.map((item) => ({
      ID: item.id + " ",
      Categoría_Invitado: invitedByTypes.find(
        (invitedType) => invitedType.value == item.invitado_por_type
      ).label,
      Pais: item.pais,
      Tipo_Documento: documentTypes.find(
        (type) => type.value == item.tipo_documento
      ).label,
      Documento: item.cedula_passport + "",
      Nombres: item.firstname,
      Apellidos: item.lastname,
      Sexo: item.sexo == 1 ? "Hombre" : "Mujer",
      Email: item.email,
      Fecha_Nacimiento: item?.birthday
        ? moment.parseZone(item.birthday).format("DD/MM/YYYY")
        : null,
      Direccion: item.direccion,
      Celular: item.celphone,
    }));
    return newData;
  };

  const getGuestsListData = async (page, filter = "") => {
    setLoading(true);
    let response = await fetchConTokenPromesa(
      `person?id_tipo=2${filter.length > 0 ? "&buscar=" + filter : ""}&page=${
        page || currentPage
      }&limit=${AppConfig.TableLimit}`
    );
    setListData(response?.personList);
    setPaginationData(response?.pagination);
    setLoading(false);
  };

  const addNewGuest = async (e) => {
    e.preventDefault();
    if (!validateForm()) {
      return;
    }
    setLoading(true);
    await fetchConTokenWithFile(
      "person",
      {
        id_tipo: 2, //2 BECAUSE 2 IS GUEST
        id_face: "",
        cedula_passport,
        firstname,
        lastname,
        birthday: birthday
          ? moment.parseZone(birthday).format("YYYY-MM-DD")
          : null,
        email,
        celphone: country == 'Dominican Republic' ? 1+celphone.replace('+',''):celphone.replace('+',''),
        direccion,
        comentario,
        tipo_documento: documentType,
        pais: country,
        sexo: sex,
        image: file?.files[0],
        costo,
        activity: activity?.value,
        confirmado,
        rango_edad: rangoEdad,
      },
      "POST"
    );

    clearForm();
    getGuestsListData();
    setFilterText("");
    setLoading(false);
  };
  const updateGuest = async (e) => {
    e.preventDefault();
    if (!validateForm()) {
      return;
    }
    setLoading(true);
    await fetchConTokenPromesa(
      `person/${idSelected}`,
      {
        id_tipo: 2, //2 BECAUSE 2 IS GUEST
        cedula_passport,
        firstname,
        lastname,
        birthday: birthday
          ? moment.parseZone(birthday).format("YYYY-MM-DD")
          : null,
        email,
        celphone: country == 'Dominican Republic' ? 1+celphone.replace('+',''):celphone.replace('+',''),
        direccion,
        comentario,
        tipo_documento: documentType,
        pais: country,
        sexo: sex,
        image: file?.files[0],
        costo,
        activity: activity?.value,
        confirmado,
        rango_edad: rangoEdad,
      },
      "PUT",
      ErrorList
    );
    handleCloseFormModal();
    getGuestsListData();
    setFilterText("");
    setLoading(false);
  };

  const handleDeleteItem = (item) => {
    setAlert(
      `Se eliminará el registro de "${item.firstname}"`,
      () => deleteItem(item),
      item
    );
  };

  const handleSelectActivity = (e) => {
    setCosto(e.costo);
    setActivity(e);
  };

  const getActivitiesDataForSelect = async (inputValue) => {
    const response = await fetchConTokenPromesa(
      "activity?page=1&limit=100&buscar=" + inputValue
    );
    if (response?.activityList) {
      return response.activityList.map((r) => ({
        value: r.id,
        label: r.resumen,
        costo: r.costo,
      }));
    } else {
      return [];
    }
  };

  const deleteItem = async (item) => {
    closeAlert();
    setLoading(true);
    await fetchConTokenPromesa(`person/${item.id}`, {}, "DELETE");
    getGuestsListData();
    setFilterText("");
    setLoading(false);
  };

  const validateForm = () => {
    if (cedula_passport?.length == 11 && !Number(cedula_passport)) {
      enqueueSnackbar("Introduzca una Cédula valida", { variant: "error" });
      return false;
    }

    if (celphone == "___-___-____") {
      enqueueSnackbar("Introduzca un Whatsapp valido", { variant: "error" });
      return false;
    }

    if (celphone?.length < 10 || celphone.search("_") > 0) {
      enqueueSnackbar("Introduzca un Whatsapp valido", { variant: "error" });
      return false;
    }
    if (
      cedula_passport?.length > 9 &&
      cedula_passport?.length < 11 &&
      cedula_passport?.length > 0
    ) {
      enqueueSnackbar("La Cédula o pasaporte debe tener 9 o 11 Dígitos", {
        variant: "error",
      });
      return false;
    }
    return true;
  };

  const handleDocumentTypeChange = (value) => {
    setDocumentType(value);
    setCountry("Dominican Republic");
  };

  const handleRangoChange = (value) => {
    setRangoEdad(value);
  };

  const handleFilterList = (e) => {
    getGuestsListData(1, e.target.value);
    setFilterText(e.target.value);
    setCurrentPage(1);
  };

  const handleSubmit = (e) => {
    if (!anUserIsSelected) {
      addNewGuest(e);
    } else {
      updateGuest(e);
    }
  };

  const handleExportToExcel = async (title) => {
    let exportData = await getExportData();
    if (exportData.length > 0) {
      ExportToExcel(exportData, title);
    } else {
      enqueueSnackbar("No hay informacion para exportar", { variant: "error" });
    }
  };

  const formatNumber = Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "usd",
    signDisplay: "never",
  });

  const selectRangoByDate = (e) => {
    setBirthday(e);
    const anios = moment().diff(e, "years");

    if (anios <= 19) {
      setRangoEdad(1);
    } else if (anios <= 29) {
      setRangoEdad(2);
    } else if (anios <= 39) {
      setRangoEdad(3);
    } else if (anios <= 49) {
      setRangoEdad(4);
    } else if (anios <= 59) {
      setRangoEdad(5);
    } else if (anios <= 69) {
      setRangoEdad(6);
    } else {
      setRangoEdad(7);
    }
  };

  const handleCelPhone = (e) => {
    if (e.target.value == "___-___-____") {
      enqueueSnackbar("Introduzca un Whatsapp valido", { variant: "error" });
      return false;
    }

    setCelphone(e.target.value);
  };

  useEffect(() => {
    getGuestsListData();
  }, []);

  return (
    <Container fluid>
      {alert}
      <Row>
        <Col md="12">
          <Card className="strpied-tabled-with-hover">
            <Card.Header>
              <Card.Title className={themeClasses.cardTitleHeader}>
                <h4 className={themeClasses.cardTitle}> Invitados </h4>
                <div className={themeClasses.simplyRowDivider} />
                <div className={themeClasses.secondaryHeaderSearcherContainer}>
                  <InputSearcher
                    value={filterText}
                    onChange={handleFilterList}
                    placeholder="Buscar"
                  />
                  <div className={themeClasses.mediumRowDivider} />
                  <Button
                    size="sm"
                    onClick={() => setFormModalVisible(true)}
                    className={themeClasses.headerButton}
                  >
                    {isMobile ? "➕" : "Agregar"}
                  </Button>
                  <div className={themeClasses.mediumRowDivider} />
                  {/* <OverlayTrigger overlay={<Tooltip>Exportar a excel</Tooltip>}>
                    <Button
                      className={themeClasses.exportButton}
                      onClick={(e) => {
                        handleExportToExcel("Invitados");
                      }}
                      variant="success"
                    >
                      <i className="fas fa-file-excel"></i>
                    </Button>
                  </OverlayTrigger> */}
                </div>
              </Card.Title>
            </Card.Header>
            <Card.Body className="table-responsive p-0">
              <div className={classes.root}>
                {loading ? (
                  <p>Cargando...</p>
                ) : (
                  <Table className="table-hover table-striped w-full">
                    <thead>
                      <tr>
                        <th>Id</th>
                        <th>Nombres</th>
                        <th>Apellidos</th>
                        <th>email</th>
                        <th>Whatsapp</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {listData?.map((item, index) => (
                        <tr key={index}>
                          <td>{item.id}</td>
                          <td>{item.firstname}</td>
                          <td>{item.lastname}</td>
                          <td>{item.email}</td>
                          <td>{item.celphone.substring(1,20)}</td>
                          <td className="td-actions text-right">
                            <OverlayTrigger
                              overlay={
                                <Tooltip id="tooltip-48903503">
                                  Modificar
                                </Tooltip>
                              }
                            >
                              <Button
                                className="btn-link btn-xl"
                                onClick={(e) => handleSelectItem(item)}
                                variant="success"
                              >
                                <i className="fas fa-edit"></i>
                              </Button>
                            </OverlayTrigger>

                            <OverlayTrigger
                              overlay={
                                <Tooltip id="tooltip-255158527">Borrar</Tooltip>
                              }
                            >
                              <Button
                                className="btn-link btn-xl"
                                onClick={(e) => handleDeleteItem(item)}
                                variant="danger"
                              >
                                <i className="fas fa-times"></i>
                              </Button>
                            </OverlayTrigger>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                )}
                <div style={{ display:'flex', justifyContent:'space-between' }}>
                  <Pagination
                    className={classes.pagination}
                    pages={paginationData?.total_page}
                    currentPage={currentPage}
                    onSelectPage={onChangePage}
                  />
                  <p style={{marginRight:'20px'}}>Registros totales: {paginationData.total_person}</p>
                </div>

                <Modal
                  show={formModalVisible}
                  onHide={() => handleCloseFormModal()}
                  size="xl"
                >
                  <Modal.Header closeButton></Modal.Header>
                  <Modal.Body className="p-1">
                    <Container fluid>
                      <Form onSubmit={handleSubmit}>
                        <Row>
                          <Col className="" md="11">
                            <h3 className="text-center">Invitado</h3>
                          </Col>
                        </Row>
                        <Row>
                          <Col className="" md="2">
                            <Form.Group>
                              <label>Tipo</label>
                              <select
                                value={documentType}
                                onChange={(e) =>
                                  handleDocumentTypeChange(e.target.value)
                                }
                                disabled={anUserIsSelected ? true : false}
                                className="form-select"
                                aria-label="Default select example"
                              >
                                {documentTypes.map((type, index) => (
                                  <option key={index} value={type.value}>
                                    {type.label}
                                  </option>
                                ))}
                              </select>
                            </Form.Group>
                          </Col>
                          <Col className="" md="6">
                            <label>Pais</label>
                            <CountryDropdown
                              className="form-select"
                              value={country}
                              onChange={(country) => setCountry(country)}
                              disabled={documentType == 1 ? true : false}
                            />
                          </Col>
                          <Col className="" md="4">
                            <Form.Group>
                              <label>Numero de Documento</label>
                              <Form.Control
                                id="documentoId"
                                placeholder="Numero de Documento"
                                type="text"
                                inputMode="numeric"
                                minLength={9}
                                maxLength={11}
                                value={cedula_passport}
                                onChange={(e) =>
                                  setCedula_passport(e.target.value)
                                }
                              />
                            </Form.Group>
                          </Col>
                        </Row>
                        <Row>
                          <Col className="" md="6">
                            <Form.Group>
                              <label>Nombre</label>
                              <Form.Control
                                placeholder="Nombre"
                                type="text"
                                value={firstname}
                                required
                                onChange={(e) => setFirstname(e.target.value)}
                              ></Form.Control>
                            </Form.Group>
                          </Col>
                          <Col className="" md="6">
                            <Form.Group>
                              <label>Apellido</label>
                              <Form.Control
                                value={lastname}
                                required
                                onChange={(e) => setLastname(e.target.value)}
                                placeholder="Apellido"
                                type="text"
                              ></Form.Control>
                            </Form.Group>
                          </Col>
                        </Row>
                        <Row>
                          <Col className="" md="3">
                            <Form.Group>
                              <label>Sexo</label>
                              <Row className="mb-3">
                                <Col md="6">
                                  <Form.Check
                                    inline
                                    checked={sex == 1 ? true : false}
                                    onChange={(e) => {
                                      setSex(1);
                                    }}
                                    label="M"
                                    type="radio"
                                  />
                                </Col>
                                <Col md="6">
                                  <Form.Check
                                    inline
                                    checked={sex == 2 ? true : false}
                                    onChange={(e) => {
                                      setSex(2);
                                    }}
                                    label="F"
                                    type="radio"
                                  />
                                </Col>
                              </Row>
                            </Form.Group>
                          </Col>
                          <Col className="" md="5">
                            <Form.Group>
                              <label>Fecha de nacimiento</label>
                              <ReactDatetime
                                timeFormat={false}
                                inputProps={{
                                  className: "form-control",
                                  placeholder: "Click para seleccionar",
                                }}
                                value={
                                  birthday
                                    ? moment
                                        .parseZone(birthday)
                                        .format("DD/MM/YYYY")
                                    : null
                                }
                                dateFormat="DD/MM/YYYY"
                                onChange={(e) => selectRangoByDate(e)}
                              />
                            </Form.Group>
                          </Col>
                          <Col className="" md="4">
                            <Form.Group>
                              <label>Rango de edad</label>
                              <select
                                value={rangoEdad}
                                onChange={(e) =>
                                  handleRangoChange(e.target.value)
                                }
                                className="form-select"
                                aria-label="Default select example"
                              >
                                <option selected disabled value={0}>
                                  Seleccionar
                                </option>
                                {rangoEdades.map((type, index) => (
                                  <option key={index} value={type.value}>
                                    {type.label}
                                  </option>
                                ))}
                              </select>
                            </Form.Group>
                          </Col>
                        </Row>
                        <Row>
                          <Col md="12">
                            <Form.Group>
                              <label>Dirección</label>
                              <Form.Control
                                value={direccion}
                                onChange={(e) => setDireccion(e.target.value)}
                                placeholder="Dirección"
                                type="text"
                              ></Form.Control>
                            </Form.Group>
                          </Col>
                        </Row>
                        <Row>
                          <Col className="" md="6">
                            <label>Whatsapp</label>
                            <Form.Group>
                              <InputMask
                                required
                                mask={country == 'Dominican Republic' ? "999-999-9999":''}
                                className="form-control"
                                placeholder={country == 'Dominican Republic' ? "829-000-0000":''}
                                value={celphone}
                                onChange={handleCelPhone}
                              />
                            </Form.Group>
                          </Col>
                          <Col className="" md="6">
                            <Form.Group>
                              <label htmlFor="email">Email</label>
                              <Form.Control
                                placeholder="Email"
                                type="email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                              ></Form.Control>
                            </Form.Group>
                          </Col>
                        </Row>
                        <Row className={anUserIsSelected ? "d-none" : ""}>
                          <Col md={12}>
                            <h3 className="text-center">Datos del pago</h3>
                          </Col>
                          <Col className="pl-1" md="8">
                            <Form.Group>
                              <label htmlFor="email">Evento</label>
                              <AsyncSelect
                                className="react-select info"
                                placeholder="Seleccione el evento"
                                classNamePrefix="react-select"
                                onChange={handleSelectActivity}
                                cacheOptions
                                defaultOptions
                                value={activity}
                                loadOptions={getActivitiesDataForSelect}
                              />
                            </Form.Group>
                          </Col>
                          <Col className="pr-1" md="4">
                            <Form.Group>
                              <label>Costo</label>
                              <Form.Control
                                placeholder="Costo"
                                type="text"
                                value={`RD${formatNumber.format(costo)}`}
                                readOnly
                                required
                                onChange={(e) => setCosto(e.target.value)}
                              ></Form.Control>
                            </Form.Group>
                          </Col>
                        </Row>

                        <Row className={anUserIsSelected ? "d-none" : ""}>
                          <Col md="6 d-flex ">
                            <p className="category mr-3">Pago confirmado</p>
                            <FormCheck
                              type="switch"
                              name="confirmado"
                              checked={confirmado}
                              onChange={(e) => setConfirmado(e.target.checked)}
                              id="custom-switch"
                              className="mb-1"
                            />
                          </Col>
                          <Col md="6">
                            <Form.Group>
                              <label className="mr-2">Comprobante</label>
                              <input
                                type="file"
                                onChange={(e) => setFile(e.target)}
                              />
                            </Form.Group>
                          </Col>
                        </Row>

                        <div className="d-flex justify-content-between">
                          <Button
                            type="button"
                            className={themeClasses.formCancelButton}
                            variant="secundary"
                            onClick={() => handleCloseFormModal()}
                          >
                            Cancelar
                          </Button>
                          {loading ? (
                            <a variant="dark" type="button">
                              <Spinner
                                as="span"
                                animation="grow"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                                className="mr-1"
                              />
                              Guardando...
                            </a>
                          ) : null}
                          <Button
                            className={themeClasses.formSubmitButton}
                            type="submit"
                            variant="primary"
                          >
                            {!anUserIsSelected
                              ? "Agregar y continuar"
                              : "Actualizar datos"}
                          </Button>
                        </div>
                        <div className="clearfix"></div>
                      </Form>
                    </Container>
                  </Modal.Body>
                  <Modal.Footer></Modal.Footer>
                </Modal>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}

export default Guests;
