import { useState } from "react";

const useForm = (initialState = {}) => {
  const [values, setValues] = useState(initialState);

  const reset = (state = initialState) => {
    setValues(state);
  };
  const handleInputChange = ({ target }) => {
    switch (target.type) {
      case "checkbox":
        setValues({
          ...values,
          [target.name]: target.checked,
        });
        break;

      case "react-select":
        setValues({
          ...values,
          [target.name]: target.value,
        });
        break;

      default:
        setValues({
          ...values,
          [target.name]: target.value,
        });

        break;
    }
  };
  return [values, handleInputChange, reset];
};

export default useForm;
