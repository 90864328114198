import React, { useEffect, useState } from "react";
import useForm from "hooks/useForm";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { BrowserView, MobileView, } from "react-device-detect";
import { Button, Card, Form, Container, Col, Image } from "react-bootstrap";
import { LoginStyles } from "./styles/LoginStyles";
import { useParams } from 'react-router';
import { useSnackbar } from "notistack";
import useFetch from "hooks/useFetch";
import AppConfig from "AppConfig";

const APP_VERSION = AppConfig.AppVersion;

function RestorePassword() {
  const { enqueueSnackbar } = useSnackbar();
  const { fetchConTokenPromesa } = useFetch();

  const [form, setForm] = useForm({
    password: "",
    repeatedPassword: "",
  });
  const { password, repeatedPassword } = form;
  const params = useParams();


  const ChangePassword = async () => {
    const ErrorList = [
      {
        key: 'expirado',
        error: 'Ha expirado el tiempo para poder cambiar la contraseña, favor solicitar el cambio nuevamente.'
      },
      {
        key: 'Documento',
        error: 'Ha expirado el tiempo para poder cambiar la contraseña, favor solicitar el cambio nuevamente.'
      },
    ]
    await fetchConTokenPromesa(`passwordReset/${params.id}/${params.token}`,
      {
        password: password,
      }
      , "POST",
      ErrorList);    
  };

  const handleOnSubmit = (e) => {
    e.preventDefault();
    if (!validateForm()) {
      return;
    }
    ChangePassword();
  };

  const validateForm = () => {
    if (password != repeatedPassword) {
      enqueueSnackbar('Las contraseñas no coinciden', { variant: 'error' })
      return false;
    }
    return true;
  }


  const classes = LoginStyles();
  return (
    <React.Fragment>
      <BrowserView className={classes.root}>
        <div className={classes.loginContainer}>
          <div
            className={classes.sidebarHeader}
          >
            <Image
              src={require("assets/img/bmcargoGolf.png").default}
              width={"40%"}
              style={{ marginRight: "20px" }}
            />
            <div style={{ height: '0.5rem' }} />
            <h5 className={classes.Title}>Recuperar contraseña</h5>
            <div className={classes.headerImageDivider} />
          </div>
          <div style={{ height: '2rem' }} />
          <Form onSubmit={handleOnSubmit}>
            <Form.Group>
              <label>Contraseña</label>
              <Form.Control
                placeholder="Contraseña"
                type="password"
                name="password"
                minLength="4"
                maxLength="4"
                autoComplete="off"
                onChange={(e) => {
                  if (Number(e.target.value || e.target.value == "")) {
                    setForm(e);
                  }
                  return;
                }}
                value={password}
                required
              ></Form.Control>
            </Form.Group>
            <Form.Group>
              <label>Repetir Contraseña</label>
              <Form.Control
                placeholder="Repetir Contraseña"
                type="password"
                name="repeatedPassword"
                minLength="4"
                maxLength="4"
                onChange={(e) => {
                  if (Number(e.target.value || e.target.value == "")) {
                    setForm(e);
                  }
                  return;
                }}
                value={repeatedPassword}
                required
              ></Form.Control>
            </Form.Group>
            <div style={{ height: '0.5rem' }} />

            <Button
              className={classes.button}
              type="submit"
            >
              Cambiar Contraseña
            </Button>

          </Form>
          <p className={classes.versionText}>Version:{APP_VERSION}</p>
        </div>
        <img width={'70%'} src={require("assets/img/login-background.jpg").default} />
      </BrowserView>
      <MobileView className={classes.loginMobileContainer}>
        <div
          style={{ display: "flex", flexDirection: 'column', alignItems: 'center', justifyContent: "center" }}
        >
          <Image
            src={require("assets/img/bmcargoGolf.png").default}
            width={"40%"}
          />
          <div style={{ height: '0.5rem' }} />
          <h5 className={classes.Title}>Recuperar contraseña</h5>
          <div className={classes.headerImageDivider} />
        </div>

        <Form onSubmit={handleOnSubmit}>
          <Form.Group>
            <label>Contraseña</label>
            <Form.Control
              placeholder="Contraseña"
              type="password"
              name="password"
              minLength="4"
              maxLength="4"
              autoComplete="off"
              onChange={(e) => {
                if (Number(e.target.value || e.target.value == "")) {
                  setForm(e);
                }
                return;
              }}
              value={password}
              required
            ></Form.Control>
          </Form.Group>
          <Form.Group>
            <label>Repetir Contraseña</label>
            <Form.Control
              placeholder="Repetir Contraseña"
              type="password"
              name="repeatedPassword"
              minLength="4"
              maxLength="4"
              onChange={(e) => {
                if (Number(e.target.value || e.target.value == "")) {
                  setForm(e);
                }
                return;
              }}
              value={repeatedPassword}
              required
            ></Form.Control>
          </Form.Group>
          <Button
            className={classes.button}
            type="submit"
          >
            Cambiar Contraseña
          </Button>
        </Form>
        <p className={classes.versionText}>Version:{APP_VERSION}</p>
      </MobileView>
    </React.Fragment>

  );
}

export default RestorePassword;
