import { makeStyles } from "@material-ui/styles";
import COLORS from "themes/Colors";

export default makeStyles({
    root: {
        display: 'flex',
        flexDirection: 'column'
    },
    headerButton: {
     backgroundColor: COLORS.primary,
     borderColor:COLORS.primary,
     '&:hover':{
        backgroundColor: COLORS.secondary,
        borderColor:COLORS.secondary,
     }
    },
    container:{
        backgroundImage: 'url("./fondo.jpeg")',
        backgroundColor:'#e78eaa',
        backgroundSize: 'cover',
        minHeight: '100vh',
        display: 'flex',
        justifyContent: 'center',
    },
    form:{
        maxWidth: '600px',
        background: '#ffffffde',
        borderRadius: '29px',
        paddingBottom:20,
        marginTop:20,
        marginBottom:50
    },
    btn:{
        background:'#6c6265',
        borderColor:'#e78eaa',
        width:'50%',
        alignSelf:'center',
        '&:hover':{
            backgroundColor:'#198754',
            borderColor:'#198754',
        }
    },
    check:{
        '&:checked': {
            backgroundColor: '#e77f85',
            borderColor: '  #efa0a5'
        }
    }
})