import React from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie } from 'react-chartjs-2';
import { colors } from './Colors';

ChartJS.register(ArcElement, Tooltip, Legend);

export default function PieChar({dataDb=[]}) {



  const data = {
    labels: dataDb?.map(d=>d.evento),
    datasets: [
      {
        label: '# of Votes',
        data: dataDb?.map(d=>d.entradas),
        backgroundColor: colors,
        borderColor: 'white',
        borderWidth: 2,
      },
    ],
  };
  
  const options = {
      fill: true,
      responsive: true,
      plugins: {
        legend: {
          display: true,
          position:'bottom'
        },
        Tooltip
      },
    };

  return <div style={{maxHeight:'200px', textAlign:'center'}}>
    <Pie  width={300} height={300} style={{width:'90%', maxHeight:'373px ', margin:'auto'}} data={data} options={options}  />
  </div>;
}
