import React, { useState, useEffect } from "react";

// react-bootstrap components
import {
  Button,
  Card,
  Form,
  Container,
  Row,
  Col,
} from "react-bootstrap";
import MessagesStyles from "./styles/MessagesStyles";
import useFetch from "hooks/useFetch";
import { mockupQuestions, mockupPages } from './MessageConstants';
import Styles from "themes/Styles";
import { roles } from "consts/APPCONSTANTS";
import { fetchConToken } from "helpers/fetch";


function Message() {

  const { fetchConTokenPromesa } = useFetch();

  const themeClasses = Styles();

  const [loading, setLoading] = useState(true);
  const { POSTNotificationRequest } = useFetch();

  const [seletedActivity, setSeletedActivity] = useState(null);
  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');
  const [metodo, setMetodo] = useState(1);
  const [activityList, setActivityList] = useState([]);
  const [segmentacion, setSegmentacion] = useState(1);


  const clearForm = () => {
    setTitle("");
    setContent("");
    
  }

  const addSendMessage = async (e) => {
    e.preventDefault();
    setLoading(true);
    
    

    const res = await fetchConTokenPromesa('broadcast',{
      title,
      content,
      metodo,
      segmentacion,
      activity:seletedActivity
    },'post');


    clearForm();
    setLoading(false);
  }

  const getActivities = async () => {
    const response = await fetchConTokenPromesa(
      "activity?page=1&limit=100&buscar="
    );
    if (response?.activityList) {

      if (response.activityList[0]) {
        setSeletedActivity(response.activityList[0].id);
      }
      
      setActivityList(response.activityList)
    }
  };


  const handleSubmit = (e) => {
    addSendMessage(e);
  }

  useEffect(()=>{
    getActivities();
  },[]);

  return (
    <Container fluid>
      <Row>
        <Col md="12">
          <Card className="strpied-tabled-with-hover">
            <Card.Header>
              <Card.Title className={themeClasses.cardTitleHeader}>
                <h4 className={themeClasses.cardTitle}> Mensajes </h4>
              </Card.Title>
            </Card.Header>
            <Card.Body className="table-responsive p-0">
              <Container fluid>
                <Form onSubmit={handleSubmit}>
                  <Row>
                    <Col md="4">
                      <Form.Group>
                        <label style={{'whiteSpace': 'nowrap','textOverflow': 'ellipsis','width': '100%','overflow': 'hidden'}}>ENVIAR A participantes del evento</label>
                        <select value={seletedActivity} onChange={(e) => setSeletedActivity(e.target.value)} className="form-select" aria-label="Default select example">
                          {activityList .map((activity, index) => (
                            <option key={index} value={activity.id}>{activity.resumen}</option>
                          ))}
                        </select>
                      </Form.Group>
                    </Col>
                    <Col md="4">
                      <Form.Group>
                        <label>Via</label>
                        <select value={metodo} onChange={(e) => setMetodo(e.target.value)} className="form-select" aria-label="Default select example">
                          <option value={1}>Whatsapp</option>
                          <option value={2}>Correo</option>
                        </select>
                      </Form.Group>
                    </Col>
                    <Col md="4">
                      <Form.Group>
                        <label>Segmentacion</label>
                        <select value={segmentacion} onChange={(e) => setSegmentacion(e.target.value)} className="form-select" aria-label="Default select example">
                          <option value={1}>Invitados no confirmados</option>
                          <option value={2}>Invitados confirmados</option>
                          <option value={3}>Invitados con checkin</option>
                        </select>
                      </Form.Group>
                    </Col>
                  <Row>
                  <Col md="12">
                      <Form.Group>
                        <label>Titulo</label>
                        <Form.Control
                          value={title}
                          required
                          placeholder="Titulo"
                          onChange={(e) => setTitle(e.target.value)}
                        ></Form.Control>
                      </Form.Group>
                    </Col>
                  </Row>
                  </Row>
                  <Row>
                    <Col md="12">
                      <Form.Group>
                        <label>Contenido</label>
                        <Form.Control
                          value={content}
                          required
                          onChange={(e) => setContent(e.target.value)}
                          as="textarea"
                          rows={5}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <div className="d-flex justify-content-between">
                    <div />
                    <Button
                      className={themeClasses.formSubmitButton}
                      type="submit"
                      variant="primary"
                    >
                      Enviar Mensaje
                    </Button>
                  </div>
                </Form>
              </Container>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}

export default Message;
