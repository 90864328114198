import React, { useState, useEffect } from "react";

// react-bootstrap components
import {
  OverlayTrigger,
  Button,
  Card,
  Form,
  Tooltip,
  Table,
  Container,
  Row,
  Col,
  Modal,
  Spinner,
  Image,
} from "react-bootstrap";
import Select from "react-select";
import ReactDatetime from "react-datetime";
import moment from "moment";
import ActivitiesStyles from "./styles/ActivitiesStyles";
import useFetch from "hooks/useFetch";
import { ErrorList } from "./ActivitiesConstants";
import Pagination from "components/Pagination/Pagination";
import useSweetAlertConfirm from "hooks/useSweetAlertConfirm";
import Styles from "themes/Styles";
import InputSearcher from "components/InputSearcher/InputSearcher";
import { isMobile } from "react-device-detect";
import { useSnackbar } from "notistack";
import List from "./components/List";
import { estado_evento } from "consts/APPCONSTANTS";
import ExportToExcel from "utilities/ExportToExcel";
import AsyncSelect, { Async } from "react-select/async";
import AppConfig from "AppConfig";
import { Divider } from "@material-ui/core";

function Activities() {
  const [alert, setAlert, closeAlert] = useSweetAlertConfirm();
  const classes = ActivitiesStyles();
  const themeClasses = Styles();
  const { enqueueSnackbar } = useSnackbar();

  const [loading, setLoading] = useState(true);
  const { fetchConTokenWithFile, fetchConTokenPromesa } = useFetch();

  const [formModalVisible, setFormModalVisible] = useState(false);

  const [filterText, setFilterText] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [listData, setListData] = useState([]);
  const [paginationData, setPaginationData] = useState({});

  const [itemId, setItemId] = useState();
  const [orden, setOrden] = useState("");
  const [capacidad, setCapacidad] = useState(1);
  const [detalle, setDetalle] = useState("");
  const [tipo, setTipo] = useState(1);
  const [localidad, setLocalidad] = useState(null);
  const [fecha, setFecha] = useState();
  const [fecha_fin, setFecha_fin] = useState();
  const [hora_inicio, setHora_inicio] = useState();
  const [hora_fin, setHora_fin] = useState();
  const [flyer, setFlyer] = useState(null);
  const [logo, setLogo] = useState(null);
  const [resumen, setResumen] = useState("");
  const [listSpeakers, setListlistSpeakers] = useState([]);
  const [listEncargados, setListEncargados] = useState([]);
  const [costo, setCosto] = useState(0);
  const [minimo, setMinimo] = useState(1);

  const [currentSelectedSpeaker, setCurrentSelectedSpeaker] = useState();
  const [currentSelectedEncargado, setCurrentSelectedEncargado] = useState();
  const [
    currentIndexOfSelectedArticleOnListSpeaker,
    setCurrentIndexOfSelectedArticleOnListSpeaker,
  ] = useState();
  const [
    currentIndexOfSelectedArticleOnListEncargado,
    setCurrentIndexOfSelectedArticleOnListEncargado,
  ] = useState();

  const handleSelectItem = async (item) => {
    setItemId(item.id);
    setOrden(item.orden);
    setDetalle(item.detalle);
    setTipo(item.tipo);
    setLocalidad({
      value: item?.localidad,
      label: item?.localidad_name,
    });
    setFecha(moment(item.fecha));
    setHora_inicio(moment("0001-01-01 " + item.hora_inicio));
    setHora_fin(moment("0001-01-01 " + item.hora_fin));
    setResumen(item.resumen);
    setCapacidad(item.capacidad);
    setCosto(item.costo);
    setListlistSpeakers(
      item.speakers.map((speaker) => ({
        id: speaker.id_persona,
        name: speaker.name,
      }))
    );
    setListEncargados(
      item.encargados.map((encargado) => ({
        id: encargado.id_persona,
        name: encargado.name,
      }))
    );
    setFormModalVisible(true);

    setMinimo(item.minimo)
    setFecha_fin(item.fecha_fin);
  };

  const handleCloseFormModal = () => {
    clearForm();
    setFormModalVisible(false);
  };

  const clearForm = () => {
    setItemId(null);
    setOrden("");
    setDetalle("");
    setTipo(1);
    setLocalidad(1);
    setFlyer(null);
    setResumen("");
    setListEncargados([]);
    setListlistSpeakers([]);
    setCosto(0);
    setCapacidad(1);
    setMinimo(1);
    setFecha('');
    setFecha_fin('');
    setLogo(null);
  };

  const onChangePage = (selectedPage) => {
    setCurrentPage(selectedPage);
    getActivitiesListData(selectedPage);
  };

  const getExportData = async () => {
    let response = await fetchConTokenPromesa(
      `activity?page=1&limit=999999999999`
    );
    let data = response?.activityList;
    let newData = data.map((item) => ({
      ID: item.id,
      Orden: item.orden,
      Tipo: estado_evento.find(
        (activityType) => activityType.value == item.tipo
      )?.label,
      Resumen: item.resumen,
      Detalle: item.detalle,
      Localidad: item?.localidad_name,
      Fecha: moment.parseZone(item.fecha).format("DD/MM/YYYY"),
      Hora_Inicio: moment("0001-01-01 " + item.hora_inicio).format("h:mm A"),
      Hora_Fin: moment("0001-01-01 " + item.hora_fin).format("h:mm A"),
      fecha_fin
    }));
    return newData;
  };
  const getActivitiesListData = async (page, filter = "") => {
    let response = await fetchConTokenPromesa(
      `activity?${filter.length > 0 && "&buscar=" + filter}&page=${
        page || currentPage
      }&limit=${AppConfig.TableLimit}`
    );
    setLoading(false);
    setListData(response?.activityList);
    setPaginationData(response?.pagination);
  };

  const handleFilterList = (e) => {
    getActivitiesListData(1, e.target.value);
    setFilterText(e.target.value);
    setCurrentPage(1);
  };

  const handleDeleteItem = (item) => {
    setAlert(
      `Se eliminará el registro de "${item.resumen}"`,
      () => deleteItem(item),
      item
    );
  };

  const deleteItem = async (item) => {
    closeAlert();
    setLoading(true);
    await fetchConTokenPromesa(`activity/${item.id}`, {}, "DELETE", ErrorList);
    getActivitiesListData();
    setFilterText("");
    setLoading(false);
  };

  const addNewActivity = async (e) => {
    e.preventDefault();
    if (!validateForm()) {
      return;
    }
    setLoading(true);
    await fetchConTokenWithFile(
      "activity",
      {
        orden,
        capacidad,
        detalle,
        tipo,
        localidad: localidad.value,
        fecha: moment.parseZone(fecha).format("YYYY-MM-DD"),
        fecha_fin:moment.parseZone(fecha_fin).format("YYYY-MM-DD"),
        hora_inicio: moment(hora_inicio).format("HH:mm:ss"),
        hora_fin: moment(hora_fin).format("HH:mm:ss"),
        resumen,
        speakers: listSpeakers,
        encargados: listEncargados,
        costo,
        minimo,
        imagen_id: '',
        images: [flyer?.files[0],logo?.files[0]],
      }
    );
    clearForm();
    getActivitiesListData();
    setFilterText("");
    setLoading(false);
  };
  const updateActivity = async (e) => {
    e.preventDefault();
    if (!validateForm()) {
      return;
    }
    setLoading(true);
    let saveImage = false;

    if (flyer) {
      saveImage = 'flyer';
    }
    if (logo) {
      saveImage = 'logo';
    }

    if (logo && flyer) {
      saveImage = 'dos';
    }
    

    await fetchConTokenWithFile(
      `activity/${itemId}?saveImage=${saveImage}`,
      {
        orden,
        capacidad,
        detalle,
        tipo,
        localidad: localidad.value,
        fecha: moment.parseZone(fecha).format("YYYY-MM-DD"),
        fecha_fin:moment.parseZone(fecha_fin).format("YYYY-MM-DD"),
        hora_inicio: moment(hora_inicio).format("HH:mm:ss"),
        hora_fin: moment(hora_fin).format("HH:mm:ss"),
        imagen_id: '',
        images: [flyer?.files[0],logo?.files[0]],
        resumen,
        speakers: listSpeakers,
        encargados: listEncargados,
        costo,
        minimo,
      }
    );
    handleCloseFormModal();
    getActivitiesListData();
    setFilterText("");
    setLoading(false);
  };

  const validateForm = () => {
    if (costo <= 0) {
      enqueueSnackbar("El costo debe de ser mayor a cero", {
        variant: "error",
      });
      return false;
    }
    if (tipo == null) {
      enqueueSnackbar("Seleccione un tipo", { variant: "error" });
      return false;
    }
    console.log(localidad)
    if (!localidad) {
      enqueueSnackbar("Seleccione una localidad", { variant: "error" });
      return false;
    }
    if (fecha == null) {
      enqueueSnackbar("Seleccione una Fecha", { variant: "error" });
      return false;
    }
    if (Number(capacidad) <= 0) {
      console.log(Number(capacidad));
      enqueueSnackbar("Debe de poner la capacidad del evento", {
        variant: "error",
      });
      return false;
    }
    return true;
  };

  const handleSelectItemFromListSpeaker = (item, index) => {
    setCurrentIndexOfSelectedArticleOnListSpeaker(index);
    setCurrentSelectedSpeaker({
      value: item.id,
      label: item.name,
    });
  };

  const handleSelectItemFromListEncargado = (item, index) => {
    setCurrentIndexOfSelectedArticleOnListEncargado(index);
    setCurrentSelectedEncargado({
      value: item.id,
      label: item.name,
    });
  };

  const addSpeakerToList = () => {
    if (currentSelectedSpeaker == null) {
      enqueueSnackbar("Debes seleccionar primero", { variant: "error" });
      return;
    }
    if (
      listSpeakers.filter((item) => item.id == currentSelectedSpeaker.value)
        .length > 0
    ) {
      enqueueSnackbar("Ya se encuentra en la lista", {
        variant: "error",
      });
      return;
    }
    setListlistSpeakers((prev) => [
      ...prev,
      {
        id: currentSelectedSpeaker.value,
        name: currentSelectedSpeaker.label,
      },
    ]);
    clearSelectedItemFromListSpeaker();
  };

  const addEncargadoToList = () => {
    if (currentSelectedEncargado == null) {
      enqueueSnackbar("Debes seleccionar primero", { variant: "error" });
      return;
    }
    if (
      listEncargados.filter((item) => item.id == currentSelectedEncargado.value)
        .length > 0
    ) {
      enqueueSnackbar("Ya se encuentra en la lista", {
        variant: "error",
      });
      return;
    }
    setListEncargados((prev) => [
      ...prev,
      {
        id: currentSelectedEncargado.value,
        name: currentSelectedEncargado.label,
      },
    ]);
    clearSelectedItemFromListEncargado();
  };
  const clearSelectedItemFromListSpeaker = () => {
    setCurrentIndexOfSelectedArticleOnListSpeaker(null);
    setCurrentSelectedSpeaker(null);
  };
  const clearSelectedItemFromListEncargado = () => {
    setCurrentIndexOfSelectedArticleOnListEncargado(null);
    setCurrentSelectedEncargado(null);
  };

  const removeItemFromListSpeaker = () => {
    if (currentIndexOfSelectedArticleOnListSpeaker == null) {
      enqueueSnackbar("No hay ningun artículo seleccionado", {
        variant: "error",
      });
      return;
    }
    let newList = [];
    for (let i = 0; i < listSpeakers.length; i++) {
      if (
        listSpeakers[i] ==
        listSpeakers[currentIndexOfSelectedArticleOnListSpeaker]
      ) {
      } else {
        newList.push(listSpeakers[i]);
      }
    }
    setListlistSpeakers(newList);
    clearSelectedItemFromListSpeaker();
  };

  const removeItemFromListEncargado = () => {
    if (currentIndexOfSelectedArticleOnListEncargado == null) {
      enqueueSnackbar("No hay ningun artículo seleccionado", {
        variant: "error",
      });
      return;
    }
    let newList = [];
    for (let i = 0; i < listEncargados.length; i++) {
      if (
        listEncargados[i] ==
        listEncargados[currentIndexOfSelectedArticleOnListEncargado]
      ) {
      } else {
        newList.push(listEncargados[i]);
      }
    }
    setListEncargados(newList);
    clearSelectedItemFromListEncargado();
  };

  const getLocationsDataForSelect = async (inputValue) => {
    const response = await fetchConTokenPromesa(
      "localidad?page=1&limit=100&buscar=" + inputValue
    );
    if (response?.localidadList) {
      return response.localidadList.map((r) => ({
        value: r.id_localidad,
        label: r.nombre,
      }));
    } else {
      return [];
    }
  };

  const getSpeakersDataForSelect = async (inputValue) => {
    const response = await fetchConTokenPromesa(
      "person?page=1&limit=100&&id_tipo=3buscar=" + inputValue
    );
    if (response?.personList) {
      const items = response.personList.map((r) => ({
        value: r.id,
        label: r.firstname + " " + r.lastname,
      }));

      return items;
    } else {
      return [];
    }
  };

  const getEncargadosDataForSelect = async (inputValue) => {
    const response = await fetchConTokenPromesa(
      "auth?page=1&limit=100&id_tipo=3buscar=" + inputValue
    );
    if (response?.userList) {
      const items = response.userList.map((r) => ({
        value: r.id_usuario,
        label: r.name ,
      }));

      return items;
    } else {
      return [];
    }
  };

  const handleSubmit = (e) => {
    if (!itemId) {
      addNewActivity(e);
    } else {
      updateActivity(e);
    }
  };

  const handleExportToExcel = async (title) => {
    let exportData = await getExportData();
    if (exportData.length > 0) {
      ExportToExcel(exportData, title);
    } else {
      enqueueSnackbar("No hay informacion para exportar", { variant: "error" });
    }
  };

  useEffect(() => {
    getActivitiesListData();
  }, []);

  return (
    <Container fluid>
      {alert}
      <Row>
        <Col md="12">
          <Card className="strpied-tabled-with-hover">
            <Card.Header>
              <Card.Title className={themeClasses.cardTitleHeader}>
                <h4 className={themeClasses.cardTitle}> Eventos </h4>
                <div className={themeClasses.simplyRowDivider} />
                <div className={themeClasses.secondaryHeaderSearcherContainer}>
                  <InputSearcher
                    value={filterText}
                    onChange={handleFilterList}
                    placeholder="Buscar"
                  />
                  <div className={themeClasses.mediumRowDivider} />
                  <Button
                    size="sm"
                    onClick={() => setFormModalVisible(true)}
                    className={themeClasses.headerButton}
                  >
                    {isMobile ? "➕" : "Agregar"}
                  </Button>
                  <div className={themeClasses.mediumRowDivider} />
                  {/* <OverlayTrigger overlay={<Tooltip>Exportar a excel</Tooltip>}>
                    <Button
                      className={themeClasses.exportButton}
                      onClick={(e) => {
                        handleExportToExcel("Eventos");
                      }}
                      variant="success"
                    >
                      <i class="fas fa-file-excel"></i>
                    </Button>
                  </OverlayTrigger> */}
                </div>
              </Card.Title>
            </Card.Header>
            <Card.Body className="table-responsive p-0">
              <div className={classes.root}>
                {loading ? (
                  <p>Cargando...</p>
                ) : (
                  <Table className="table-hover table-striped w-full">
                    <thead>
                      <tr>
                        <th>Posición</th>
                        <th>Evento</th>
                        <th>fecha</th>
                        <th>Logo</th>
                        <th>Flyer</th>
                        <th></th>
                      </tr>
                    </thead>

                    <tbody>
                      {listData.map((item, index) => (
                        <tr key={index}>
                          <td>{item.orden}</td>
                          <td>{item.resumen}</td>
                          <td>
                            {moment.parseZone(item.fecha).format("DD/MM/YYYY")}
                          </td>
                          <td>
                            {item.logo && 
                              <>
                                <a href={item.logo} target={'_blank'}>
                                <Image
                                  src={item?.logo}
                                  style={{
                                    padding: "0.5%",
                                    display: "flex",
                                    width: "40px",
                                  }}
                                />
                                </a>
                              </>
                            }
                          </td>
                          <td>
                            {item.imagen_id && 
                              <>
                                <a href={item.imagen_id} target={'_blank'}>
                                <Image
                                  src={item?.imagen_id}
                                  style={{
                                    padding: "0.5%",
                                    display: "flex",
                                    width: "40px",
                                  }}
                                />
                                </a>
                              </>
                            }
                          </td>
                          <td className="td-actions text-right" style={{display:'table-cell'}}>
                            <OverlayTrigger
                              overlay={
                                <Tooltip id="tooltip-48903503">
                                  Modificar
                                </Tooltip>
                              }
                            >
                              <Button
                                className="btn-link btn-xl"
                                onClick={(e) => handleSelectItem(item)}
                                variant="success"
                              >
                                <i className="fas fa-edit"></i>
                              </Button>
                            </OverlayTrigger>

                            <OverlayTrigger
                              overlay={
                                <Tooltip id="tooltip-255158527">Borrar</Tooltip>
                              }
                            >
                              <Button
                                className="btn-link btn-xl"
                                onClick={(e) => handleDeleteItem(item)}
                                variant="danger"
                              >
                                <i className="fas fa-times"></i>
                              </Button>
                            </OverlayTrigger>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                )}
                <div style={{ alignSelf: "center" }}>
                  <Pagination
                    className={classes.pagination}
                    pages={paginationData?.total_page}
                    currentPage={currentPage}
                    onSelectPage={onChangePage}
                  />
                </div>

                <Modal
                  show={formModalVisible}
                  onHide={() => handleCloseFormModal()}
                  size="xl"
                >
                  <Modal.Header closeButton></Modal.Header>
                  <Modal.Body className="p-1">
                    <Container fluid>
                      <Form onSubmit={handleSubmit}>
                        <Row>
                          <Col className="pr-1" md="12">
                            <h3 className="text-center mt-0">Evento</h3>
                          </Col>
                        </Row>
                        <Row>
                          <Col className="pr-1" md="4">
                            <Form.Group>
                              <label>Posición del evento</label>
                              <Form.Control
                                placeholder="Posición"
                                value={orden}
                                onChange={(e) => setOrden(e.target.value)}
                              ></Form.Control>
                            </Form.Group>
                          </Col>
                          <Col className="pl-1" md="4">
                            <Form.Group>
                              <label htmlFor="Detalle">Nombre de evento</label>
                              <Form.Control
                                placeholder="Evento"
                                onChange={(e) => setResumen(e.target.value)}
                                required
                                value={resumen}
                              ></Form.Control>
                            </Form.Group>
                          </Col>
                          <Col className="pl-1" md="4">
                            <Form.Group>
                              <label htmlFor="Detalle">
                                Costo entrada (RD$)
                              </label>
                              <Form.Control
                                placeholder="Actividad"
                                onChange={(e) => setCosto(e.target.value)}
                                required
                                value={costo}
                              ></Form.Control>
                            </Form.Group>
                          </Col>
                        </Row>
                        <Row>
                          <Col className="pr-1" md="4">
                            <label>Estado</label>
                            <Select
                              className="react-select info"
                              classNamePrefix="react-select"
                              placeholder="Tipo de persona"
                              onChange={(e) => setTipo(e.value)}
                              value={[...estado_evento].filter(
                                (t) => t.value == tipo
                              )}
                              required
                              options={estado_evento}
                            />
                          </Col>
                          <Col className="pr-1" md="4">
                            <label>Asistencia máxima</label>
                            <Form.Control
                              placeholder="Personas"
                              value={capacidad}
                              required
                              onChange={(e) => setCapacidad(e.target.value)}
                            ></Form.Control>
                          </Col>
                          <Col className="pl-1" md="4">
                            <Form.Group>
                              <label>Asistencia mínima</label>
                              <Form.Control
                                value={minimo}
                                required
                                onChange={(e) => setMinimo(e.target.value)}
                                placeholder="Minimo"
                              ></Form.Control>
                            </Form.Group>
                          </Col>
                        </Row>
                        <Row>
                          <Col className="pr-1" md="6">
                            <Form.Group>
                              <label>Fecha inicio</label>
                              <ReactDatetime
                                timeFormat={false}
                                onChange={(e) => setFecha(e)}
                                value={
                                  fecha
                                    ? moment
                                        .parseZone(fecha)
                                        .format("DD/MM/YYYY")
                                    : null
                                }
                                dateFormat="DD/MM/YYYY"
                                inputProps={{
                                  className: "form-control",
                                  placeholder: "Click para seleccionar",
                                }}
                              ></ReactDatetime>
                            </Form.Group>
                          </Col>
                          <Col md="6">
                            <Form.Group>
                              <label>Fecha fin</label>
                              <ReactDatetime
                                timeFormat={false}
                                onChange={(e) => setFecha_fin(e)}
                                value={
                                  fecha_fin
                                    ? moment
                                        .parseZone(fecha_fin)
                                        .format("DD/MM/YYYY")
                                    : null
                                }
                                dateFormat="DD/MM/YYYY"
                                inputProps={{
                                  className: "form-control",
                                  placeholder: "Click para seleccionar",
                                }}
                              ></ReactDatetime>
                            </Form.Group>
                          </Col>

                        </Row>
                        <Row>
                          <Col className="" md="4">
                            <Form.Group>
                            <label>Flyer</label>
                            <input
                                type="file"
                                className="form-control"
                                onChange={(e) => setFlyer(e.target)}
                              />
                            </Form.Group>
                          </Col>
                          <Col className="" md="4">
                            <Form.Group>
                            <label>Logo</label>
                            <input
                                type="file"
                                className="form-control"
                                onChange={(e) => setLogo(e.target)}
                              />
                            </Form.Group>
                          </Col>
                          <Col className="pl-1" md="4">
                            <Form.Group>
                              <label htmlFor="email">Localidad</label>
                              <AsyncSelect
                                className="react-select info"
                                placeholder="Escribe para buscar"
                                classNamePrefix="react-select"
                                onChange={(e) => setLocalidad(e)}
                                cacheOptions
                                defaultOptions
                                value={localidad}
                                loadOptions={getLocationsDataForSelect}
                              />
                            </Form.Group>
                          </Col>
                        </Row>
                        <Row>
                          <Col md="12">
                            <Form.Group>
                              <label>Detalle</label>
                              <Form.Control
                                value={detalle}
                                required
                                onChange={(e) => setDetalle(e.target.value)}
                                as="textarea"
                                rows={5}
                              />
                            </Form.Group>
                          </Col>
                        </Row>

                        <Row>
                          <Col>
                            <label htmlFor="email" className="font-weight-bold">
                              Speakers:
                            </label>
                            <List
                              ListItems={listSpeakers}
                              onSelected={handleSelectItemFromListSpeaker}
                            />
                          </Col>
                        </Row>
                        <Col className="pl-1" md="12">
                          <Form.Group>
                            <AsyncSelect
                              className="react-select info"
                              classNamePrefix="react-select"
                              placeholder="Seleccionar"
                              onChange={(e) => setCurrentSelectedSpeaker(e)}
                              value={currentSelectedSpeaker}
                              cacheOptions
                              defaultOptions
                              loadOptions={getSpeakersDataForSelect}
                            />
                          </Form.Group>
                        </Col>
                        <Row>
                          <Col className="pr-1" md="6">
                            <Button
                              className={classes.addButton}
                              onClick={() => addSpeakerToList()}
                              variant="primary"
                            >
                              Agregar
                            </Button>
                          </Col>
                          <Col
                            style={{ marginRight: "15px", textAlign: "right" }}
                          >
                            <Button
                              className={classes.deleteButton}
                              onClick={() => removeItemFromListSpeaker()}
                              variant="primary"
                            >
                              Eliminar
                            </Button>
                          </Col>
                        </Row>
                        <Divider className="my-2" />
                        <Row>
                          <Col>
                            <label htmlFor="email" className="font-weight-bold">
                              Encargados:
                            </label>
                            <List
                              ListItems={listEncargados}
                              onSelected={handleSelectItemFromListEncargado}
                            />
                          </Col>
                        </Row>
                        <Col className="pl-1" md="12">
                          <Form.Group>
                            <AsyncSelect
                              className="react-select info"
                              classNamePrefix="react-select"
                              placeholder="Seleccionar"
                              onChange={(e) => setCurrentSelectedEncargado(e)}
                              value={currentSelectedEncargado}
                              cacheOptions
                              defaultOptions
                              loadOptions={getEncargadosDataForSelect}
                            />
                          </Form.Group>
                        </Col>
                        <Row>
                          <Col className="pr-1" md="6">
                            <Button
                              className={classes.addButton}
                              onClick={() => addEncargadoToList()}
                              variant="primary"
                            >
                              Agregar
                            </Button>
                          </Col>
                          <Col
                            style={{ paddingRight: "30px", textAlign: "right" }}
                            md="6"
                          >
                            <Button
                              className={classes.deleteButton}
                              onClick={() => removeItemFromListEncargado()}
                              variant="primary"
                            >
                              Eliminar
                            </Button>
                          </Col>
                        </Row>

                        <div className="d-flex justify-content-between mt-5">
                          <Button
                            type="button"
                            className={themeClasses.formCancelButton}
                            variant="secundary"
                            onClick={() => handleCloseFormModal()}
                          >
                            Cancelar
                          </Button>
                          {loading ? (
                            <a variant="dark" type="button">
                              <Spinner
                                as="span"
                                animation="grow"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                                className="mr-1"
                              />
                              Guardando...
                            </a>
                          ) : null}
                          <Button
                            className={themeClasses.formSubmitButton}
                            type="submit"
                            variant="primary"
                          >
                            {itemId == null
                              ? "Agregar y continuar"
                              : "Actualizar datos"}
                          </Button>
                        </div>
                        <div className="clearfix"></div>
                      </Form>
                    </Container>
                  </Modal.Body>
                  <Modal.Footer></Modal.Footer>
                </Modal>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}

export default Activities;
