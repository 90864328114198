import { makeStyles } from "@material-ui/styles";
import COLORS from "themes/Colors";

export default makeStyles({
    root: {
        display: 'flex',
        flexDirection: 'column'
    },
    headerButton: {
     backgroundColor: COLORS.primary,
     borderColor:COLORS.primary,
     '&:hover':{
        backgroundColor: COLORS.secondary,
        borderColor:COLORS.secondary,
     }
    }
})