const COLORS = {
  //BMCargo Colors
  primary: "#c87100",
  primaryOpacity: (color) => { return `rgba(0,130,200,${color || 0.50})` },
  secondary: "#1a1e45",
  secondaryOpacity: (color) => { return `rgba(80,185,72,${color || 0.50})` },
  tertiary: "#58595b",
  tertiaryOpacity: 'rgba(87, 88, 91,0.65)',

  //BMCargo complementary colors
  pink: "#ff3fb4",
  yellow: "#ffdd00",
  lightBlue: "#0098ce",

  //basics colors
  red: "red",
  error: "#F74022",
  success: "#1a1e45",
  white: '#ffffff',
  black: '#231f20',
  gray: "#58595b",
  grayPlaceholder: 'rgba(87, 88, 91,0.65)',
  lightGray: "#DCDCDC",
  fieldGray: "#E6EAEE",

  //misc
  facebookBlue: "#3B5998",
}

export default COLORS
